import { useState, useEffect, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import { correctionFilterVar } from '../../../../apollo/reactiveVariables';
import PageTitle from '../../../../components/layout/PageTitle';
import SkeletonListData from '../../../../components/skeletons/ListData';
import { GET_CORRECTIONS } from '../../../../gql/catalogs';
import GET_FILTERS from '../../../../gql/filters';
import ListServerData from '../../../commons/ListServerData';
import columns from './components/columns';
import CorrectionFilters from './components/CorrectionFilters';

function CorrectionList() {
  const correctionFilter = useReactiveVar(correctionFilterVar);

  const [filterOptions, setFilterOptions] = useState({
    warehouses: [],
    sellers: [],
  });
  const [filters, setFilters] = useState(correctionFilter);
  // TODO: add use get filters from another PR
  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        correctionFilterOptions: { sellers, warehouses },
      },
    }) => {
      const sellersOptions = sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const warehousesOptions = warehouses.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({
        sellers: sellersOptions,
        warehouses: warehousesOptions,
      });
      setFilters((prev) => ({
        ...prev,
        warehouseId:
          correctionFilter.warehouseId || warehousesOptions[0]?.value,
        sellerId: correctionFilter.sellerId || sellersOptions[0]?.value,
      }));
    },
  });
  useEffect(() => {
    correctionFilterVar(filters);
  }, [filters]);

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({
      ...prev,
      globalFilter,
    }));
  };

  const memoizedColumns = useMemo(() => columns(), []);
  if (loading)
    return (
      <>
        <PageTitle>Correcciones de inventario</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <PageTitle>Correciones de inventario</PageTitle>
      <ListServerData
        query={GET_CORRECTIONS}
        filters={{
          components: (
            <CorrectionFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
              warehousesOptions={filterOptions.warehouses}
            />
          ),
          helperTextSearchBox: 'Buscar por referencia',
        }}
        dataKey="corrections"
        columns={memoizedColumns}
        queryVariables={{ filters }}
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={correctionFilter.globalFilter}
      />
    </>
  );
}
export default CorrectionList;
