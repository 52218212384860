import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';

import Field from './Field';
import ItemListTable from './ItemListTable';
import SectionContainer from './SectionContainer';

function GeneralOrderSection({ generalOrderInfo }) {
  const {
    businessType,
    comment,
    createdAt,
    platformName,
    sellerName,
    sourceOrderId,
    warehouseName,
    wmsId,
    itemsDetails,
  } = generalOrderInfo;

  const { items, summary, currency } = itemsDetails;

  const [showDetails, setShowDetails] = useState(false);

  return (
    <SectionContainer title="Informacion general de la orden">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Field label="Fecha ingreso" value={createdAt} />
          <Field label="Vendedor" value={sellerName} />
          <Field label="Tipo de negocio" value={businessType} />
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Field label="Plataforma" value={platformName} />
          <Field label="ID Plataforma" value={sourceOrderId} hasClipboard />
          <Field label="ID WMS" value={wmsId} hasClipboard />
        </Box>

        <Field label="Tienda" value={warehouseName} />
        <Field label="Observaciones" value={comment} />

        <Button
          variant="outlined"
          onClick={() => setShowDetails(!showDetails)}
          sx={{
            marginBottom: 4,
            borderRadius: '4px',
            border: '0.5px solid #EB5D29',
            color: '#EB5D29',
            width: 'max-content',
            alignSelf: 'center',
          }}
        >
          {showDetails ? 'Esconder' : 'Mostrar'} items de la orden
        </Button>
        {showDetails && (
          <Box sx={{ marginTop: 4 }}>
            <Divider />
            <ItemListTable
              items={items}
              summary={summary}
              currency={currency}
            />
          </Box>
        )}
      </Box>
    </SectionContainer>
  );
}

GeneralOrderSection.propTypes = {
  generalOrderInfo: PropTypes.shape({
    businessType: PropTypes.string,
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    platformName: PropTypes.string,
    sellerName: PropTypes.string,
    sourceOrderId: PropTypes.string,
    warehouseName: PropTypes.string,
    wmsId: PropTypes.string,
    itemsDetails: PropTypes.shape({
      currency: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          description: PropTypes.string,
          quantitu: PropTypes.string,
          sku: PropTypes.string,
          totalValue: PropTypes.number,
          unitDiscount: PropTypes.number,
          unitPrice: PropTypes.number,
        }),
      ),
      summary: PropTypes.shape({
        discountsValue: PropTypes.number,
        shippingValue: PropTypes.number,
        totalValue: PropTypes.number,
        valuesWithTaxes: PropTypes.bool,
      }),
    }),
  }),
};

GeneralOrderSection.defaultProps = {
  generalOrderInfo: {
    businessType: null,
    comment: null,
    createdAt: null,
    platformName: null,
    sellerName: null,
    sourceOrderId: null,
    warehouseName: null,
    wmsId: null,
  },
};

export default GeneralOrderSection;
