import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import getDeliveryLogo from 'lib/deliveryLogo';
import DELIVERY_STATUSES from 'lib/deliveryStatuses';
import { SHIPPING_METHODS_LABEL_MAPPING } from 'lib/shippingMethods';

import OrderDeliveryDialog from './OrderDeliveryDialog';

function OrderDelivery({ id, systemName, shippingMethod, status, sellerId }) {
  const isDeliveryError = DELIVERY_STATUSES.error === status;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {systemName && (
          <Box
            component="img"
            sx={{
              height: 'auto',
              width: 70,
            }}
            alt={systemName}
            src={getDeliveryLogo(systemName)}
          />
        )}
        <Typography noWrap variant="caption" sx={{ color: 'inherit' }}>
          {SHIPPING_METHODS_LABEL_MAPPING[shippingMethod]}
        </Typography>
        {isDeliveryError && (
          <Typography noWrap color="error" variant="caption">
            Error al solicitar el envío
          </Typography>
        )}
      </Box>
      <OrderDeliveryDialog
        orderId={id}
        sellerId={sellerId}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
    </>
  );
}
OrderDelivery.propTypes = {
  id: PropTypes.string.isRequired,
  sellerId: PropTypes.string.isRequired,
  systemName: PropTypes.string,
  shippingMethod: PropTypes.string.isRequired,
  status: PropTypes.string,
  orderStatus: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};
OrderDelivery.defaultProps = {
  status: undefined,
  systemName: undefined,
};
export default OrderDelivery;
