import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';

import FormTextField from 'components/text-fields/FormTextField';
import { REQUIRED_FIELD } from 'lib/form';

function BarcodeForm({ control }) {
  const { fields, append, remove } = useFieldArray({
    name: 'barcode',
    control,
    rules: { required: REQUIRED_FIELD },
  });
  const { errors } = useFormState({
    control,
  });
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { currentBarcode: '' },
  });
  const addItem = ({ currentBarcode }) => {
    const duplicatedObject = fields.find(
      ({ value }) => value === currentBarcode,
    );
    if (currentBarcode && !duplicatedObject) {
      append({ value: currentBarcode });
      reset();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormTextField
          fieldName="currentBarcode"
          control={control}
          label="EAN (código de barras)"
          sx={{ width: '100%', mt: 0 }}
          register={() => register('currentBarcode')}
          required={false}
          error={!!errors.barcode}
          helperText={errors.barcode?.root?.message}
        />
        <Button
          size="medium"
          variant="contained"
          sx={{
            backgroundColor: '#01B0BB',
            borderRadius: 1,
            width: 250,
            alignSelf: 'baseline',
          }}
          startIcon={<AddOutlinedIcon />}
          onClick={handleSubmit(addItem)}
        >
          Agregar EAN
        </Button>
      </Box>
      <Typography variant="subtitle3">EAN, códigos de barra:</Typography>

      {fields.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <List sx={{ width: '100%' }}>
            {fields.map(({ value: barcode }, index) => (
              <Box
                key={barcode}
                sx={{ backgroundColor: grey[50], borderRadius: 3, mb: 2 }}
              >
                <ListItem
                  secondaryAction={
                    <Tooltip title="Eliminar código de barra">
                      <IconButton
                        edge="end"
                        value={barcode}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemText primary={barcode} />
                </ListItem>
              </Box>
            ))}
          </List>
        </Box>
      ) : (
        <Alert severity="warning" sx={{ borderRadius: 3 }}>
          No tienes códigos de barra registrados para este producto
        </Alert>
      )}
    </Box>
  );
}
BarcodeForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
};
export default BarcodeForm;
