import Box from '@mui/material/Box';
import {
  Page,
  Text,
  View,
  Document,
  Image,
  PDFViewer,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import logo from 'assets/images/logo-store-central.png';
import SummaryView from 'components/pdf/SummaryView';
import TableView from 'components/pdf/TableView';

import Separator from './Separator';

function DefaultPdf({ title, summaryData, bodyData }) {
  return (
    <Box
      style={{
        margin: 'auto',
        minHeight: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <PDFViewer
        style={{
          width: '100%',
          height: window.innerHeight,
          alignSelf: 'center',
        }}
      >
        <Document>
          <Page size="A4" style={{ padding: 10 }}>
            <View style={{ alignItems: 'left', width: '10%' }}>
              <Image src={logo} />
            </View>
            <View style={{ alignItems: 'center', width: '100%' }}>
              <Text
                key="Title"
                style={{
                  fontSize: 12,
                  fontWeight: 100,
                }}
              >
                {`${title.type} ${title.name}`}
              </Text>
            </View>
            <Separator />
            <SummaryView
              summaryData={summaryData}
              summaryTitle="Información general"
            />
            <Separator />
            <TableView
              columnsNames={bodyData.columnsNames}
              columns={bodyData.columns}
              data={bodyData.data}
              tableName="Items"
              itemKey="sku"
            />
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
}
DefaultPdf.propTypes = {
  title: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  summaryData: PropTypes.shape({}).isRequired,
  bodyData: PropTypes.shape({
    columnsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default DefaultPdf;
