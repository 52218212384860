import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const SX_BOX = { padding: 2 };

function CatalogShowSummary({ catalogInstance }) {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: grey[50],
        borderRadius: 3,
        mb: 2,
      }}
    >
      <Box sx={SX_BOX}>
        <Typography variant="caption">Vendedor</Typography>
        <Typography>{catalogInstance.seller?.name}</Typography>
      </Box>
      {catalogInstance.warehouse && (
        <Box sx={SX_BOX}>
          <Typography variant="caption">Tienda</Typography>
          <Typography>{catalogInstance.warehouse?.name}</Typography>
        </Box>
      )}
      {catalogInstance.originWarehouse && (
        <Box sx={SX_BOX}>
          <Typography variant="caption">Tienda origen</Typography>
          <Typography>{catalogInstance.originWarehouse?.name}</Typography>
        </Box>
      )}
      {catalogInstance.destinationWarehouse && (
        <Box sx={SX_BOX}>
          <Typography variant="caption">Tienda destino</Typography>
          <Typography>{catalogInstance.destinationWarehouse?.name}</Typography>
        </Box>
      )}
      <Box sx={SX_BOX}>
        <Typography variant="caption">Fecha ingreso</Typography>
        <Typography>{catalogInstance.createdAt}</Typography>
      </Box>
      {catalogInstance.createdBy && (
        <Box sx={SX_BOX}>
          <Typography variant="caption">Ingresado por</Typography>
          <Typography>{catalogInstance.createdBy}</Typography>
        </Box>
      )}
      {catalogInstance.comment && (
        <Box sx={SX_BOX}>
          <Typography variant="caption">Comentario</Typography>
          <Typography>{catalogInstance.comment}</Typography>
        </Box>
      )}
    </Box>
  );
}
CatalogShowSummary.propTypes = {
  catalogInstance: PropTypes.shape({
    seller: PropTypes.shape({ name: PropTypes.string }),
    warehouse: PropTypes.shape({ name: PropTypes.string }),
    originWarehouse: PropTypes.shape({ name: PropTypes.string }),
    destinationWarehouse: PropTypes.shape({ name: PropTypes.string }),
    createdAt: PropTypes.string,
    comment: PropTypes.string,
    createdBy: PropTypes.string,
  }).isRequired,
};
export default CatalogShowSummary;
