import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import grey from '@mui/material/colors/grey';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import FormTextField from '../../../../components/text-fields/FormTextField';
import { UPDATE_ORDER_ISSUES } from '../../../../gql/orders';
import { REQUIRED_FIELD } from '../../../../lib/form';
import { ORDER_ISSUES_LABEL_MAPPING } from '../../../../lib/orderIssues';

const DEFAULT_FORM_VALUES = {
  issueType: '',
  comment: '',
};

const issueOptions = Object.keys(ORDER_ISSUES_LABEL_MAPPING).map((issue) => ({
  label: ORDER_ISSUES_LABEL_MAPPING[issue],
  value: issue,
}));

function AddIssueDialog({ openDialog, closeDialog, orderId, sourceOrderId }) {
  const [updateOrderIssues, { loading }] = useMutation(UPDATE_ORDER_ISSUES, {
    onCompleted: () => {
      closeDialog();
    },
  });

  const onSubmit = (formData) => {
    const { issueType, comment } = formData;
    updateOrderIssues({
      variables: { updateOrderIssuesId: orderId, issueType, comment },
    });
  };

  const { register, control, handleSubmit } = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
  });
  return (
    <Dialog open={openDialog} onClose={closeDialog}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        Agregar incidencia a la orden #{sourceOrderId || orderId}
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormTextField
            fieldName="issueType"
            label="Tipo de incidencia"
            sx={{ width: '100%' }}
            control={control}
            register={() => register('issueType', { required: REQUIRED_FIELD })}
            select={{
              select: true,
              options: issueOptions,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              backgroundColor: grey[100],
              padding: 2,
              borderRadius: 1,
            }}
          >
            <InfoIcon sx={{ marginRight: 2, color: grey[600] }} />
            <Typography color={grey[700]}>
              ¡Ayúdanos a comprender las incidencias!. Describe la incidencia lo
              más completa y detallada posible.
            </Typography>
          </Box>
          <FormTextField
            fieldName="comment"
            control={control}
            label="Observaciones"
            register={() => register('comment', { required: REQUIRED_FIELD })}
            multiline
            rows={4}
            sx={{ height: '400px' }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          endIcon={loading && <CircularProgress size={15} />}
          disabled={loading}
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddIssueDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  sourceOrderId: PropTypes.string.isRequired,
};

AddIssueDialog.defaultProps = {
  openDialog: false,
};

export default AddIssueDialog;
