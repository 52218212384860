import Typography from '@mui/material/Typography';

import HeaderListData from '../../../../commons/HeaderListData';
import QuantityDelta from './QuantityDelta';

const columns = () => [
  {
    Header: <HeaderListData>Sku</HeaderListData>,
    accessor: 'sku',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Cantidad corregida</HeaderListData>,
    accessor: 'quantityDelta',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) =>
      (value === 0 || value) && <QuantityDelta quantity={value} />,
  },
];
export default columns;
