import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link, useParams } from 'react-router-dom';

import MainBox from '../../../../components/containers/MainBox';
import Error from '../../../../components/Error';
import PageTitle from '../../../../components/layout/PageTitle';
import SkeletonListData from '../../../../components/skeletons/ListData';
import PAPER_BOX_PROPS from '../../../../components/table/styles';
import Table from '../../../../components/table/Table';
import { GET_REPLENISHMENT } from '../../../../gql/catalogs';
import {
  REPLENISHMENT_STATUS_LABEL_MAPPING,
  REPLENISHMENT_STATUS_COLOR_MAPPING,
} from '../../../../lib/replenishmentStatuses';
import tableColumns from './components/columns';
import ReplenishmentSummary from './components/ReplenishmentSummary';

const MIN_PAGE_SIZE = 1;

function Replenishment() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_REPLENISHMENT, {
    variables: { id },
  });

  const replenishment = useMemo(() => {
    const {
      reference,
      seller,
      warehouse,
      items = [],
      comment,
      createdAt,
      status,
    } = data?.replenishment || {};
    return { reference, seller, warehouse, items, createdAt, comment, status };
  }, [data]);

  const columns = useMemo(tableColumns, []);
  if (error) {
    return (
      <MainBox>
        <Error />
      </MainBox>
    );
  }
  if (loading)
    return (
      <>
        <PageTitle>Abastecimiento</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <PageTitle width="auto">
          Abastecimiento {replenishment.reference}
          <Chip
            label={
              <Typography sx={{ fontWeight: 500 }}>
                {REPLENISHMENT_STATUS_LABEL_MAPPING[replenishment.status]}
              </Typography>
            }
            color={REPLENISHMENT_STATUS_COLOR_MAPPING[replenishment.status]}
            variant="outlined"
            sx={{ marginLeft: 3 }}
          />
          <IconButton component={Link} to="pdf">
            <PictureAsPdfOutlinedIcon />
          </IconButton>
        </PageTitle>
      </Box>
      <ReplenishmentSummary replenishment={replenishment} />
      <Table
        data={replenishment.items}
        columns={columns}
        pagination={{
          hasPagination: false,
          customPageSize: replenishment.items.length || MIN_PAGE_SIZE,
        }}
        hasStickyHeader={false}
        hasSearchBox={false}
        paperBoxProps={PAPER_BOX_PROPS}
        loading={loading}
        error={error}
      />
    </Box>
  );
}
export default Replenishment;
