import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Field from './Field';
import SectionContainer from './SectionContainer';

const { REACT_APP_VELA_URL } = process.env;

function DeliverySection({ delivery, orderId }) {
  const {
    shippingLabelURL,
    shippingMethod,
    systemName,
    trackingURL,
    dropOffAddress: {
      computedAddress: { label },
    },
  } = delivery;
  return (
    <SectionContainer title="Delivery">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Field label="Tipo de envío" value={shippingMethod} />
          <Field label="Courier" value={systemName?.toUpperCase()} />
        </Box>
        <Field label="Dirección de envío" value={label} />

        <Typography variant="h6">Etiquetas</Typography>
        <Field
          label="Etiqueta del courier"
          value="Ver etiqueta"
          type="link"
          href={shippingLabelURL}
        />

        <Typography variant="h6">Seguimientos</Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Field
            label="Seguimiento del courier"
            value="Ir al seguimiento"
            type="link"
            href={trackingURL}
          />
          <Field
            label="Seguimiento de Store Central"
            value="Ir al seguimiento de Store Central"
            type="link"
            href={`${REACT_APP_VELA_URL}/${orderId}`}
          />
        </Box>
      </Box>
    </SectionContainer>
  );
}

DeliverySection.propTypes = {
  delivery: PropTypes.shape({
    dropOffAddress: PropTypes.shape({
      sourceAddress: PropTypes.string,
      computedAddress: PropTypes.shape({
        label: PropTypes.string,
        isVerified: PropTypes.bool,
        isVerifiedBy: PropTypes.string,
        isVerifiedAt: PropTypes.string,
        isPartialMatch: PropTypes.bool,
      }),
    }),
    shippingLabelURL: PropTypes.string,
    shippingMethod: PropTypes.string,
    systemName: PropTypes.string,
    trackingURL: PropTypes.string,
  }).isRequired,
  orderId: PropTypes.string.isRequired,
};

export default DeliverySection;
