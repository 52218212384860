const REQUIRED_FIELD = 'Campo obligatorio';
const POSITIVE_NUMBER = 'Ingresa una cantidad mayor a 0';
const MAX_NUMBER = 'Excedes la cantidad máxima';
const QUANTITY_AVAILABLE_EXCEEDED =
  'Excediste la cantidad disponible en la tienda';

const PRODUCT_FORM_VALUES = ['storageType', 'name', 'measures'];
const SHORT_INVALID_VALUE = 'Inválido';
const SHORT_REQUIRED_FIELD = 'Requerido';
const NATURAL_NUMBER_FIELD = 'Ingresa un número entero, sin decimales';

export {
  REQUIRED_FIELD,
  POSITIVE_NUMBER,
  MAX_NUMBER,
  QUANTITY_AVAILABLE_EXCEEDED,
  PRODUCT_FORM_VALUES,
  SHORT_INVALID_VALUE,
  SHORT_REQUIRED_FIELD,
  NATURAL_NUMBER_FIELD,
};
