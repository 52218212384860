import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import PropTypes from 'prop-types';

function Link({ href }) {
  return (
    <MuiLink href={href} target="_blank" rel="noopener noreferrer">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        Ver
        <LaunchIcon sx={{ width: '0.8em' }} />
      </Box>
    </MuiLink>
  );
}
Link.propTypes = {
  href: PropTypes.string.isRequired,
};
export default Link;
