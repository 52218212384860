import { useMemo } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import PAPER_BOX_PROPS from 'components/table/styles';
import Table from 'components/table/Table';
import Tag from 'components/Tag';
import ArrayFormTextFieldCell from 'components/text-fields/ArrayFormTextFieldCell';

const INPUT_TYPE_NUMBER = 'number';

function PackComponentsTable({ control, fields, remove, register }) {
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Nombre</Typography>,
        accessor: 'name',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Tipo Almacenaje</Typography>,
        id: 'storageType',
        accessor: 'storageType',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          // eslint-disable-next-line react/prop-types
          <Tag
            // eslint-disable-next-line react/prop-types
            label={value.label}
            // eslint-disable-next-line react/prop-types
            type={value.value}
          />
        ),
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'value',
        id: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          const currentItemIndex = fields.findIndex(
            (item) => item.value === value,
          );
          return (
            <ArrayFormTextFieldCell
              control={control}
              currentItemIndex={currentItemIndex}
              defaultValue={1}
              formArrayName="items"
              formItemHeaderName="quantity"
              inputType={INPUT_TYPE_NUMBER}
              required
              valueAsNumber
              register={register}
              validation={{
                min: 1,
              }}
            />
          );
        },
      },
      {
        Header: '',
        id: 'delete',
        accessor: 'value',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => (
          <Box
            key={`delete-item${value}-box`}
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
          >
            <IconButton
              onClick={() => {
                const currentItemIndex = fields.findIndex(
                  (item) => item.value === value,
                );
                remove(currentItemIndex);
              }}
            >
              <Tooltip title="Eliminar producto del pack">
                <ClearIcon />
              </Tooltip>
            </IconButton>
          </Box>
        ),
      },
    ],
    [fields, control],
  );
  return (
    <Table
      data={fields}
      columns={columns}
      pagination={{ hasPagination: false, customPageSize: fields.length }}
      hasSearchBox={false}
      paperBoxProps={PAPER_BOX_PROPS}
    />
  );
}
PackComponentsTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};
export default PackComponentsTable;
