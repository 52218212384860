import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { getToken } from 'auth/utils';
import useQueryParams from 'custom-hooks/useQueryParams';

function useGetToken() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const code = params.get('code');
  const [error, setError] = useState();
  useEffect(() => {
    getToken(code)
      .then(({ data: tokenData }) => {
        Object.keys(tokenData).map((key) =>
          localStorage.setItem(key, tokenData[key]),
        );
        navigate('/');
      })
      .catch((tokenError) => {
        setError(!!tokenError);
      });
  }, []);
  return { error };
}
export default useGetToken;
