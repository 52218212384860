/* eslint-disable camelcase */

import countries from 'i18n-iso-countries';

const LOCATION_TYPE = 'Point';
const COMPUTED_ADDRESS_MAPPER = {
  route: 'street',
  street_number: 'addressNumber',
  administrative_area_level_3: 'municipality',
  administrative_area_level_2: 'subRegion',
  administrative_area_level_1: 'region',
  country: 'country',
  postal_code: 'postalCode',
};

function getGoogleMapsAddressComponents(place) {
  const {
    address_components,
    formatted_address,
    geometry: { location },
  } = place;
  const partialComputedAddress = address_components.reduce((acc, curr) => {
    if (COMPUTED_ADDRESS_MAPPER[curr.types[0]]) {
      return {
        ...acc,
        [COMPUTED_ADDRESS_MAPPER[curr.types[0]]]: curr.short_name,
      };
    }
    return acc;
  }, {});
  const computedAddress = {
    ...partialComputedAddress,
    label: formatted_address,
    country: countries.alpha2ToAlpha3(partialComputedAddress.country),
    location: {
      type: LOCATION_TYPE,
      coordinates: [location.lat(), location.lng()],
    },
  };
  return computedAddress;
}
export default getGoogleMapsAddressComponents;
