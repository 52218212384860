import LinearProgress from '@mui/material/LinearProgress';
import { useRouteError } from 'react-router-dom';

import { ERRORS } from 'apollo/serverErrors';
import Error from 'components/Error';

function Unauthenticated() {
  const error = useRouteError();
  if (error.message === ERRORS.AUTHENTICATION_ERROR_MESSAGE) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Error message="Ha ocurrido un error inesperado. Intenta en un rato" />
  );
}

export default Unauthenticated;
