import PropTypes from 'prop-types';

import SimpleSelect from '../../../../../components/selects/SimpleSelect';

function ProductFilters({ filters, setFilters, sellersOptions }) {
  const hasOneSeller = sellersOptions.length === 1;
  return (
    <div>
      {!hasOneSeller && (
        <SimpleSelect
          id="filter-seller-select"
          variant="outlined"
          helperText="Filtrar por vendedor"
          selectLabel="Vendedor"
          options={sellersOptions}
          eventName="sellerId"
          controlled={{
            controlledValue: filters.sellerId,
            setControlledValue: setFilters,
          }}
          sx={{ width: 180, verticalAlign: 'baseline' }}
        />
      )}
    </div>
  );
}
ProductFilters.propTypes = {
  filters: PropTypes.shape({
    sellerId: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  sellersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

ProductFilters.defaultProps = {
  sellersOptions: [],
};
export default ProductFilters;
