import { createBrowserRouter, Navigate } from 'react-router-dom';

import Unauthenticated from 'components/auth/Unauthenticated';
import Layout from 'components/layout/Layout';
import isAuthenticatedLoader from 'routes/loader/isAuthenticatedLoader';
import CorrectionPdf from 'views/catalogs/corrections/show/pdf';
import NewBatchProductsForm from 'views/catalogs/products/forms/new-batch';
import ReplenishmentPdf from 'views/catalogs/replenishments/show/pdf';
import TransferPdf from 'views/catalogs/transfers/show/pdf';

import Auth from '../components/auth/Auth';
import NotAuthorized from '../components/auth/NotAuthorized';
import MainCatalog from '../views/catalogs';
import CorrectionsList from '../views/catalogs/corrections/list';
import Correction from '../views/catalogs/corrections/show';
import InventoryList from '../views/catalogs/inventories/list';
import ReplenishmentList from '../views/catalogs/replenishments/list';
import NewResplenishment from '../views/catalogs/replenishments/new';
import Replenishment from '../views/catalogs/replenishments/show';
import TransfersList from '../views/catalogs/transfers/list';
import Transfer from '../views/catalogs/transfers/show';
import OrdersIntegrationsList from '../views/integrations/orders/list';
import NewOrderForm from '../views/orders/forms/new';
import NewBatchOrdersForm from '../views/orders/forms/new-batch';
import OrdersList from '../views/orders/list';
import Order from '../views/orders/show';
import OrderPdf from '../views/orders/show/pdf';
import PickingList from '../views/orders/show/picking-list';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    loader: isAuthenticatedLoader,
    errorElement: <Unauthenticated />,
    children: [
      { index: true, element: <Navigate to="/orders" /> },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <OrdersList />,
          },
          { path: 'new', element: <NewOrderForm /> },
          { path: 'new-batch', element: <NewBatchOrdersForm /> },
          {
            path: ':orderId',
            children: [
              { index: true, element: <Order /> },
              { path: 'picking-list-label', element: <PickingList /> },
              { path: 'pdf', element: <OrderPdf /> },
            ],
          },
          { path: '*', element: <Navigate to="/orders" /> },
        ],
      },
      {
        path: 'catalogs',
        children: [
          { index: true, element: <Navigate to="/catalogs/products" /> },
          { path: '*', element: <MainCatalog /> },
          { path: 'products/new-batch', element: <NewBatchProductsForm /> },
          {
            path: 'inventories',
            children: [{ index: true, element: <InventoryList /> }],
          },
          {
            path: 'replenishments',
            children: [
              { index: true, element: <ReplenishmentList /> },
              {
                path: ':id',
                children: [
                  { index: true, element: <Replenishment /> },
                  { path: 'pdf', element: <ReplenishmentPdf /> },
                ],
              },
              { path: 'new', element: <NewResplenishment /> },
            ],
          },
          {
            path: 'corrections',
            children: [
              { index: true, element: <CorrectionsList /> },
              {
                path: ':id',
                children: [
                  { index: true, element: <Correction /> },
                  { path: 'pdf', element: <CorrectionPdf /> },
                ],
              },
            ],
          },
          {
            path: 'transfers',
            children: [
              { index: true, element: <TransfersList /> },
              {
                path: ':id',
                children: [
                  { index: true, element: <Transfer /> },
                  { path: 'pdf', element: <TransferPdf /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'integrations',
        children: [
          {
            path: 'orders',
            children: [{ index: true, element: <OrdersIntegrationsList /> }],
          },
        ],
      },
      { path: 'auth-error', element: <NotAuthorized /> },
    ],
  },
  { path: '*', element: <Navigate to="/orders" /> },
  {
    path: '/auth',
    element: <Auth />,
  },
]);
export default router;
