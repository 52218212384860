import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import GoogleMapsAutocomplete from 'components/google-maps/GoogleMapsAutocomplete';

import AddressComponentContainer from './AddressComponentContainer';

function OrderAddressForm({
  register,
  watch,
  onSubmit,
  loading,
  mutationError,
  control,
  setValue,
  resetForm,
}) {
  const watchShippingAddressLabel = watch('shippingAddressLabel');
  const watchComputedAddress = watch('computedAddress');
  const watchAddressNumber = watch('addressNumber');
  const watchMunicipality = watch('municipality');

  const setComputedAddress = (computedAddress) => {
    setValue('computedAddress', computedAddress);
  };
  const municipalityFormValue =
    watchMunicipality || watchComputedAddress?.municipality;
  const addressNumberFormValue =
    watchAddressNumber || watchComputedAddress?.addressNumber;
  const isMissingRequiredAddressComponents =
    !watchComputedAddress?.street ||
    !addressNumberFormValue ||
    !municipalityFormValue;
  useEffect(() => {
    if (!watchShippingAddressLabel) {
      resetForm();
    }
  }, [watchShippingAddressLabel]);
  return (
    <>
      <GoogleMapsAutocomplete
        setValue={setValue}
        control={control}
        fieldName="shippingAddressLabel"
        showMap
        getComputedAddress={setComputedAddress}
      />
      <TextField
        size="small"
        label="Complemento"
        placeholder="ejemplo: Depto 13A"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register('complement')}
      />
      {watchComputedAddress && watchShippingAddressLabel && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="subtitle1">Campos calculados:</Typography>
          <AddressComponentContainer
            addressComponent={watchComputedAddress.street}
            label="Calle"
          />
          <AddressComponentContainer
            addressComponent={
              watchAddressNumber || watchComputedAddress.addressNumber
            }
            label="Número"
            canEdit
            keyForm="addressNumber"
            setValue={setValue}
          />
          <AddressComponentContainer
            addressComponent={
              watchMunicipality || watchComputedAddress.municipality
            }
            label="Comuna/Distrito"
            canEdit
            keyForm="municipality"
            setValue={setValue}
          />
          <AddressComponentContainer
            addressComponent={watchComputedAddress.subRegion}
            label="Ciudad/Provincia"
          />
          <AddressComponentContainer
            addressComponent={watchComputedAddress.region}
            label="Región/Departamento"
          />
          <AddressComponentContainer
            addressComponent={watchComputedAddress.country}
            label="País"
          />
          <AddressComponentContainer
            addressComponent={watchComputedAddress.postalCode}
            label="Código postal"
          />
        </Box>
      )}

      {mutationError && (
        <Typography color="error" align="center">
          Error para actualizar la dirección
        </Typography>
      )}
      {isMissingRequiredAddressComponents && watchShippingAddressLabel && (
        <Typography color="error" align="center">
          La calle, número y comuna/distrito deben existir
        </Typography>
      )}
      <Button
        sx={{ alignSelf: 'center' }}
        color="secondary"
        variant="contained"
        disabled={
          loading ||
          !watchShippingAddressLabel ||
          isMissingRequiredAddressComponents
        }
        onClick={onSubmit}
        endIcon={loading && <CircularProgress size={15} />}
      >
        Actualizar dirección de entrega
      </Button>
    </>
  );
}
OrderAddressForm.propTypes = {
  errors: PropTypes.shape({}),
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mutationError: PropTypes.shape({}),
  defaultValues: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};

OrderAddressForm.defaultProps = {
  errors: {},
  mutationError: null,
  defaultValues: {},
};
export default OrderAddressForm;
