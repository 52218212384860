import {
  DEFAULT_LINEAL_UNIT_MEASURE,
  DEFAULT_WEIGHT_UNIT_MEASURE,
} from 'lib/productUnitMeasures';

function translateProducts({ tableData, watchSellerId }) {
  const fileProducts = tableData.reduce(
    (acc, current) => ({
      ...acc,
      [current.sku || `Producto ${Object.keys(acc).length}`]: {
        sku: current.sku ? current.sku.toString() : '',
        ...(!current.sku && {
          assignedSku: !current.sku && Object.keys(acc).length,
        }),
        sellerId: watchSellerId,
        name: current.name,
        measures: {
          weight: {
            value: parseFloat(current.kgWeight),
            unit: DEFAULT_WEIGHT_UNIT_MEASURE,
          },
          length: {
            value: parseFloat(current.cmLength),
            unit: DEFAULT_LINEAL_UNIT_MEASURE,
          },
          height: {
            value: parseFloat(current.cmHeight),
            unit: DEFAULT_LINEAL_UNIT_MEASURE,
          },
          width: {
            value: parseFloat(current.cmWidth),
            unit: DEFAULT_LINEAL_UNIT_MEASURE,
          },
        },
        storageType: current.storageType,
        deliveryStorageType: current.deliveryStorageType,
        barcode:
          current.barcode && current.barcode.toString().trim().split('.'),
      },
    }),
    {},
  );
  return Object.values(fileProducts);
}
export default translateProducts;
