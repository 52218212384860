/* eslint-disable no-nested-ternary */
import TableCellMui from '@mui/material/TableCell';
import TableHeadMui from '@mui/material/TableHead';
import TableRowMui from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';

function TableHeader({ headerGroups, sxCell }) {
  return (
    <TableHeadMui id="tableHead">
      {headerGroups.map((headerGroup) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TableRowMui {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <TableCellMui
              align={column.align ?? 'left'}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...column.getHeaderProps(
                column.getSortByToggleProps({
                  title: undefined,
                }),
              )}
              padding={column.padding ? column.padding : 'normal'}
              sx={sxCell}
            >
              {column.canSort ? (
                <TableSortLabel
                  id="tableSort"
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                >
                  {column.render('Header')}
                </TableSortLabel>
              ) : (
                column.render('Header')
              )}
            </TableCellMui>
          ))}
        </TableRowMui>
      ))}
    </TableHeadMui>
  );
}

TableHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  headerGroups: PropTypes.array.isRequired,
  sxCell: PropTypes.shape({}),
};

TableHeader.defaultProps = {
  sxCell: {},
};

export default TableHeader;
