/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';

import DateRangePicker from '../../../../components/selects/DateRangePicker';
import MultipleSelect from '../../../../components/selects/MultiSelect';

function OrderFilters({
  warehousesOptions,
  sellersOptions,
  setFilters,
  filters,
}) {
  const hasOneSeller = sellersOptions.length === 1;
  return (
    <>
      <MultipleSelect
        id="order-filter-multiselect-warehouse"
        helperText="Filtrar por tienda"
        selectLabel="Tienda"
        sx={{ width: 180 }}
        value={filters.warehouseId}
        setValue={setFilters}
        options={warehousesOptions}
        eventName="warehouseId"
      />
      {!hasOneSeller && (
        <MultipleSelect
          id="order-filter-multiselect-seller"
          helperText="Filtrar por vendedor"
          selectLabel="Vendedor"
          sx={{ width: 180 }}
          value={filters.sellerId}
          setValue={setFilters}
          options={sellersOptions}
          eventName="sellerId"
        />
      )}
      <DateRangePicker setDate={setFilters} date={filters} />
    </>
  );
}
OrderFilters.propTypes = {
  filters: PropTypes.shape({
    warehouseId: PropTypes.arrayOf(PropTypes.string),
    sellerId: PropTypes.arrayOf(PropTypes.string),
    sinceDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  warehousesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  sellersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

OrderFilters.defaultProps = {
  warehousesOptions: [],
  sellersOptions: [],
};
export default OrderFilters;
