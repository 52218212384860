import { gql } from '@apollo/client';

const GET_PRODUCTS_AT_WAREHOUSES = gql`
  query getProductsAtWarehouses(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductAtWarehouseFilters!
  ) {
    getProductsAtWarehouses(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        quantityAvailable
        seller {
          id
          name
        }
        warehouse {
          id
          name
        }
        product {
          id
          sku
          name
        }
      }
      total
      pageCount
    }
  }
`;

const GET_PRODUCT_AT_WAREHOUSE_BY_IDS = gql`
  query getProductAtWarehouseByIds(
    $sellerId: String!
    $warehouseId: String!
    $productId: String!
  ) {
    getProductAtWarehouseByIds(
      sellerId: $sellerId
      warehouseId: $warehouseId
      productId: $productId
    ) {
      id
      product {
        id
      }
      quantityAvailable
      lastUpdate @client
    }
  }
`;

const SYNC_PRODUCT_AT_WAREHOUSE = gql`
  mutation syncProductAtWarehouse($input: SyncProductAtWarehouseInput!) {
    syncProductAtWarehouse(input: $input) {
      id
      product {
        id
      }
      quantityAvailable
    }
  }
`;

const GET_PRODUCTS = gql`
  query getProducts(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ProductFilters!
  ) {
    getProducts(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        name
        sku
        barcode
        value @client
        label @client
        componentsQuantity @client
        packType @client
        storageType {
          value
          label
        }
        deliveryStorageType
        seller {
          id
          name
        }
        volume @client
        measures {
          width {
            value
            unit
          }
          height {
            value
            unit
          }
          length {
            value
            unit
          }
          weight {
            value
            unit
          }
        }
        platformsConfig {
          platformName
        }
        components {
          productId
          maxComponentQuantity
          quantity
        }
      }
      total
      pageCount
    }
  }
`;

const GET_PRODUCT = gql`
  query getProduct($sellerId: ID, $id: ID!) {
    product(sellerId: $sellerId, id: $id) {
      id
      sku
      name
      seller {
        id
        name
      }
      storageType {
        value
        label
      }
      deliveryStorageType
      measures {
        width {
          value
          unit
        }
        length {
          value
          unit
        }
        height {
          value
          unit
        }
        weight {
          value
          unit
        }
      }
      barcode
      components {
        productId
        maxComponentQuantity
        sellerId
        quantity
        componentType @client
        product {
          id
          sku
          name
          storageType {
            value
            label
          }
          measures {
            width {
              value
              unit
            }
            length {
              value
              unit
            }
            height {
              value
              unit
            }
            weight {
              value
              unit
            }
          }
          volume @client
          weight @client
        }
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      measures {
        height {
          value
          unit
        }
        length {
          unit
          value
        }
        weight {
          unit
          value
        }
        width {
          unit
          value
        }
      }
      name
      seller {
        name
        id
      }
      sku
      storageType {
        label
        value
      }
    }
  }
`;

const CREATE_BATCH_PRODUCTS = gql`
  mutation Mutation($input: [CreateBatchProductInput!]!) {
    createBatchProducts(input: $input) {
      success
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      sku
      name
      seller {
        id
        name
      }
      measures {
        width {
          value
          unit
        }
        height {
          value
          unit
        }
        length {
          value
          unit
        }
        weight {
          value
          unit
        }
      }
      storageType {
        value
        label
      }
    }
  }
`;

const GET_CORRECTIONS = gql`
  query getCorrections(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: CorrectionFilters
  ) {
    corrections(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        reference
        comment
        createdAt
      }
      total
      pageCount
    }
  }
`;
const GET_CORRECTION = gql`
  query getCorrection($id: ID!) {
    correction(id: $id) {
      id
      reference
      seller {
        id
        name
      }
      warehouse {
        id
        name
      }
      comment
      items {
        sku
        quantityAvailable
        quantityDelta
      }
      createdAt
      createdBy
    }
  }
`;

const GET_REPLENISHMENTS = gql`
  query getReplenishments(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: ReplenishmentFilters
  ) {
    replenishments(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        reference
        status
        comment
        createdAt
      }
      total
      pageCount
    }
  }
`;

const GET_REPLENISHMENT = gql`
  query replenishment($id: ID!) {
    replenishment(id: $id) {
      id
      reference
      seller {
        id
        name
      }
      warehouse {
        id
        name
      }
      status
      comment
      items {
        sku
        productName
        quantity
        quantityDelta
        unitCost
        costCurrency
        lotNumber
        expirationDate
      }
      createdAt
    }
  }
`;

const CREATE_REPLENISHMENT = gql`
  mutation createReplenishment($input: CreateReplenishmentInput!) {
    createReplenishment(input: $input) {
      reference
    }
  }
`;

const GET_TRANSFERS = gql`
  query getTransfers(
    $offset: Int
    $limit: Int
    $sort: String
    $sellerId: String!
  ) {
    transfers(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: { sellerId: $sellerId }
    ) {
      nodes {
        id
        reference
        seller {
          id
          name
        }
        originWarehouse {
          id
          name
        }
        destinationWarehouse {
          id
          name
        }
        status
        comment
        items {
          productId
          sku
          quantityAvailable
          quantityDelta
        }
        createdAt
      }
      total
      pageCount
    }
  }
`;

const GET_TRANSFER = gql`
  query transfer($id: ID!) {
    transfer(id: $id) {
      id
      reference
      seller {
        id
        name
      }
      originWarehouse {
        id
        name
      }
      destinationWarehouse {
        id
        name
      }
      status
      comment
      items {
        sku
        quantityAvailable
        quantityDelta
      }
      createdAt
    }
  }
`;

const CREATE_TRANSFER = gql`
  mutation createTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      reference
    }
  }
`;

export {
  GET_PRODUCTS_AT_WAREHOUSES,
  GET_PRODUCT_AT_WAREHOUSE_BY_IDS,
  GET_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  SYNC_PRODUCT_AT_WAREHOUSE,
  GET_CORRECTIONS,
  GET_CORRECTION,
  GET_REPLENISHMENTS,
  GET_REPLENISHMENT,
  CREATE_REPLENISHMENT,
  GET_TRANSFER,
  CREATE_PRODUCT,
  CREATE_TRANSFER,
  GET_TRANSFERS,
  CREATE_BATCH_PRODUCTS,
};
