import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import storeLoader from 'assets/images/store-loader.gif';
import Error from 'components/Error';
import DefaultPdf from 'components/pdf/DefaultPdf';
import TableBox from 'components/table/TableBox';
import { GET_REPLENISHMENT } from 'gql/catalogs';
import dateParser from 'helpers/dateParser';
import { REPLENISHMENT_STATUS_LABEL_MAPPING } from 'lib/replenishmentStatuses';

function ReplenishmentPdf() {
  const [replenishmentData, setReplenishmentData] = useState();
  const { id } = useParams();
  const [getReplenishment, { loading, error }] =
    useLazyQuery(GET_REPLENISHMENT);

  useEffect(() => {
    getReplenishment({
      variables: { id },
      onCompleted: ({ replenishment }) => {
        setReplenishmentData({
          ...replenishment,
        });
      },
    });
  }, []);

  const tableData = useMemo(() => {
    if (!replenishmentData) return null;
    return {
      summaryData: {
        Estado: REPLENISHMENT_STATUS_LABEL_MAPPING[replenishmentData.status],
        Vendedor: replenishmentData.seller?.name,
        Tienda: replenishmentData.warehouse?.name,
        'Fecha ingreso': dateParser(replenishmentData.createdAt),
        Comentario: replenishmentData.comment,
      },
      title: { type: 'Abastecimiento', name: replenishmentData.reference },
      bodyData: {
        columnsNames: [
          'Sku',
          'Nombre',
          'Cantidad informada',
          'Cantidad recepcionada',
          'Costo unitario',
          'Número de lote',
          'Fecha de vencimiento',
        ],
        columns: [
          'sku',
          'productName',
          'quantity',
          'quantityDelta',
          'unitCost',
          'lotNumber',
          'expirationDate',
        ],
        data: replenishmentData.items,
      },
    };
  }, [replenishmentData]);

  if (error) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <Error message="No pudimos encontrar la información de este abastecimiento." />
        </TableBox>
      </Box>
    );
  }
  if (loading || !replenishmentData) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <img src={storeLoader} alt="store-loader" width="110px" />
          <Typography>Cargando información</Typography>
        </TableBox>
      </Box>
    );
  }

  return (
    <DefaultPdf
      summaryData={tableData.summaryData}
      title={tableData.title}
      bodyData={tableData.bodyData}
    />
  );
}

export default ReplenishmentPdf;
