import LinearProgress from '@mui/material/LinearProgress';

import useGetToken from 'auth/hooks/useGetToken';
import Error from 'components/Error';

function Auth() {
  const { error } = useGetToken();

  if (error)
    return (
      <Error message="Ocurrió un error al iniciar sesión. Intenta en un rato" />
    );
  return <LinearProgress color="secondary" />;
}

export default Auth;
