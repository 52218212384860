import { useCallback } from 'react';

import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { endOfToday, endOfDay, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';

const DATE_FORMAT = 'dd/MM/yyyy';
function DateRangePicker({ date, setDate, sx }) {
  const onChangeSinceDate = useCallback((event) => {
    setDate((prev) => ({
      ...prev,
      sinceDate: startOfDay(event).toString(),
    }));
  });
  const onChangeToDate = useCallback((event) => {
    setDate((prev) => ({
      ...prev,
      toDate: endOfDay(event).toString(),
    }));
  });
  return (
    <>
      <DatePicker
        inputFormat={DATE_FORMAT}
        value={date.sinceDate}
        onChange={onChangeSinceDate}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            size="small"
            sx={sx}
            helperText="Fecha desde"
            color="secondary"
          />
        )}
      />
      <DatePicker
        inputFormat={DATE_FORMAT}
        value={date.toDate}
        onChange={onChangeToDate}
        maxDate={endOfToday()}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            size="small"
            sx={sx}
            helperText="Fecha hasta"
            color="secondary"
          />
        )}
      />
    </>
  );
}

DateRangePicker.propTypes = {
  date: PropTypes.shape({
    sinceDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    toDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }).isRequired,
  setDate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};
DateRangePicker.defaultProps = {
  sx: { width: 180 },
};
export default DateRangePicker;
