import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Link from '../../../../../components/links/Link';
import {
  TRANSFER_STATUS_LABEL_MAPPING,
  TRANSFER_STATUS_COLOR_MAPPING,
} from '../../../../../lib/transferStatuses';
import HeaderListData from '../../../../commons/HeaderListData';

const columns = () => [
  {
    Header: <HeaderListData>ID referencia</HeaderListData>,
    accessor: 'reference',
    disableSortBy: true,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Estado</HeaderListData>,
    accessor: 'status',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => (
      <Tooltip title="La transferencia tiene esta secuencia de estados: creada y finalizada.">
        <Chip
          label={
            <Typography sx={{ fontWeight: 500 }}>
              {TRANSFER_STATUS_LABEL_MAPPING[value]}
            </Typography>
          }
          color={TRANSFER_STATUS_COLOR_MAPPING[value]}
          variant="outlined"
        />
      </Tooltip>
    ),
  },
  {
    Header: <HeaderListData>Fecha creación</HeaderListData>,
    accessor: 'createdAt',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => <Typography>{value}</Typography>,
  },
  {
    id: 'link',
    accessor: 'id',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Link href={`/catalogs/transfers/${value}`} />,
  },
];
export default columns;
