import { useState, useEffect, useMemo, useCallback } from 'react';

import { useReactiveVar, useQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

import { packFilterVar } from 'apollo/reactiveVariables';
import MenuButton from 'components/buttons/MenuButton';
import PageTitle from 'components/layout/PageTitle';
import SimpleMenu from 'components/menus/SimpleMenu';
import EditableCell from 'components/table/EditableCells';
import Tag from 'components/Tag';
import useSnackbarAlert from 'custom-hooks/useSnackbarAlert';
import { GET_PRODUCTS, UPDATE_PRODUCT } from 'gql/catalogs';
import GET_FILTERS from 'gql/filters';
import ProductFilters from 'views/catalogs/inventories/list/components/ProductFilters';
import {
  inlineForms,
  updateVariables,
} from 'views/catalogs/products/forms/edit/inline';
import HeaderListData from 'views/commons/HeaderListData';
import ListServerData from 'views/commons/ListServerData';

import CellText from './components/CellText';
import PackDetails from './components/PackDetails';

const { PRODUCT_FORM_VALUES } = require('lib/form');

const NEW_PACK_ROUTE = '/catalogs/packs/new';

function PackTable() {
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const { SnackbarAlert, displaySnackbarAlert } = useSnackbarAlert();

  const {
    register,
    getValues,
    reset: formReset,
    formState,
    handleSubmit,
  } = useForm({ reValidateMode: 'onBlur' });
  const { errors } = formState;

  // Returns variables used in mutation, receives data from the form and data from the row

  const [updateRow, { loading: updateRowLoading, reset }] = useMutation(
    UPDATE_PRODUCT,
    {
      onError: () => {
        displaySnackbarAlert({
          message: `Producto no ha sido actualizado: Ha ocurrido un error`,
          type: 'error',
        });
        setEditableRowIndex(-1);
        reset();
      },
      onCompleted: () => {
        displaySnackbarAlert({
          message: 'Producto actualizado correctamente',
          type: 'success',
        });
        setEditableRowIndex(-1);
        reset();
      },
    },
  );
  const editFormData = {
    formValues: PRODUCT_FORM_VALUES,
    updateVariables,
    formHooks: { handleSubmit, formReset, getValues, errors },
    mutationFunction: updateRow,
    loading: updateRowLoading,
    editableRowIndex,
    setEditableRowIndex,
  };
  const packFilter = useReactiveVar(packFilterVar);
  const [filters, setFilters] = useState(packFilter);

  const [filterOptions, setFilterOptions] = useState({
    sellers: [],
  });

  useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        catalogFilterOptions: { sellers: _sellers },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers });
      setFilters((prev) => ({
        ...prev,
        sellerId: packFilter.sellerId || _sellers[0]?.id,
      }));
    },
  });

  useEffect(() => {
    packFilterVar(filters);
  }, [filters]);

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({ ...prev, globalFilter }));
  };

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Nombre</HeaderListData>,
        accessor: 'name',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>{value}</Typography>
            </Box>
          );

          const renderCellEditing = () => inlineForms.name(register, errors);
          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>SKU</HeaderListData>,
        accessor: 'sku',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>Tipo pack</HeaderListData>,
        accessor: 'packType',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Tag label={value} />,
      },
      {
        Header: <HeaderListData>Cantidad de componentes</HeaderListData>,
        accessor: 'componentsQuantity',
        Cell: CellText,
      },
      {
        Header: <HeaderListData>Medidas</HeaderListData>,
        accessor: 'volume',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => {
            const { length, width, height } = value || {};
            const missingAllDimensions =
              !value ||
              Object.values({ length, width, height }).filter(
                (dimension) => dimension?.value === '?',
              ).length === 3;
            return (
              <Tooltip
                title={
                  !missingAllDimensions
                    ? `Largo x Ancho x Altura.`
                    : 'No existen dimensiones inscritas'
                }
              >
                <Typography>{value}</Typography>
              </Tooltip>
            );
          };
          const renderCellEditing = () =>
            inlineForms.measures(register, errors, getValues);
          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>Peso</HeaderListData>,
        id: 'weight',
        accessor: 'measures',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => {
            const existsWeight =
              // eslint-disable-next-line react/prop-types
              value?.weight?.value && value?.weight?.value !== '?';
            return (
              <Tooltip title={existsWeight ? '' : 'No existe peso inscrito'}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>
                    {existsWeight ? `${value.weight.value}` : ''}
                  </Typography>
                  <Typography variant="caption">
                    {existsWeight ? `${value.weight.unit}` : '---'}
                  </Typography>
                </Box>
              </Tooltip>
            );
          };
          const renderCellEditing = () =>
            inlineForms.weight(register, errors, getValues);
          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>Acciones</HeaderListData>,
        id: 'actions',
        accessor: 'id',

        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
        }) => {
          const [anchorEl, setAnchorEl] = useState();
          const categories = useMemo(
            () => ({
              actions: { label: 'Acciones', icon: <ModeOutlinedIcon /> },
            }),
            [],
          );
          const options = useMemo(
            () => [
              {
                id: 'editInNewTab',
                label: 'Editar pack',
                href: `/catalogs/packs/${value}/edit`,
                component: 'a',
                icon: <LaunchIcon />,
                category: 'actions',
              },
            ],
            [value],
          );
          const renderButton = useCallback(
            // eslint-disable-next-line react/jsx-props-no-spreading
            (props) => <MenuButton {...props} />,
            [],
          );
          return (
            <SimpleMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              options={options}
              renderButton={renderButton}
              categories={categories}
            />
          );
        },
      },
    ],
    [formState],
  );

  const renderSubComponent = (row) => {
    const {
      original: { id: productId, seller },
    } = row;

    return <PackDetails sellerId={seller.id} productId={productId} />;
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <PageTitle>Packs registrados</PageTitle>
        <Button
          startIcon={<AddIcon />}
          color="secondary"
          href={NEW_PACK_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ whiteSpace: 'nowrap' }}
        >
          CREAR PACK
        </Button>
      </Box>
      <ListServerData
        query={GET_PRODUCTS}
        rowEdition={{ canEdit: false, editFormProperties: editFormData }}
        dataKey="getProducts"
        renderSubComponent={renderSubComponent}
        filters={{
          components: (
            <ProductFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
            />
          ),
          helperTextSearchBox: 'Buscar por producto, sku o tipo de almacenaje',
        }}
        queryVariables={{
          filters: {
            hasComponents: true,
            ...filters,
          },
        }}
        handleGlobalFilter={handleGlobalFilter}
        columns={columns}
      />
      <SnackbarAlert />
    </>
  );
}

export default PackTable;
