const keyMirror = require('keymirror');

const ORDER_ISSUES = keyMirror({
  ORDER_ITEMS_UPDATED: null,
  ORDER_LATE_DELIVERY: null,
  ORDER_HAS_NOT_ARRIVED: null,
  ORDER_MISSING_ITEMS: null,
  DELIVERY_ADDRESS_ERROR: null,
  DELIVERY_UPDATED_DELIVERY_DATE: null,
  DELIVERY_NO_RESPONSE_AT_ADDRESS: null,
});

const ORDER_ISSUES_LABEL_MAPPING = {
  [ORDER_ISSUES.ORDER_ITEMS_UPDATED]: 'Orden con items actualizados',
  [ORDER_ISSUES.ORDER_LATE_DELIVERY]: 'Orden entregada tarde',
  [ORDER_ISSUES.ORDER_HAS_NOT_ARRIVED]: 'Orden no ha sido entregada aún',
  [ORDER_ISSUES.ORDER_MISSING_ITEMS]: 'Orden con items faltantes',
  [ORDER_ISSUES.DELIVERY_ADDRESS_ERROR]: 'Delivery con dirección errónea',
  [ORDER_ISSUES.DELIVERY_UPDATED_DELIVERY_DATE]:
    'Delivery con cambio en fecha de entrega',
  [ORDER_ISSUES.DELIVERY_NO_RESPONSE_AT_ADDRESS]:
    'Delivery sin respuesta en lugar de entrega',
};

export { ORDER_ISSUES, ORDER_ISSUES_LABEL_MAPPING };
