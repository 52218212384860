import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

function TableFilters({
  value,
  onChange,
  filters,
  helperText,
  handleClickOpenExportDataForm,
}) {
  return (
    <Box
      id="filterBox"
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
        padding: '10px',
        alignItems: 'baseline',
        background: grey[50],
        gap: 2,
        borderRadius: 3,
        marginBottom: 2,
      }}
    >
      {filters}
      <TextField
        sx={{
          marginLeft: { md: 'auto' },
          width: 250,
        }}
        id="searchBoxTableTextField"
        size="small"
        placeholder="Buscar"
        helperText={helperText}
        onChange={onChange}
        value={value}
        color="secondary"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {handleClickOpenExportDataForm && (
        <Button
          id="exportButton"
          variant="outlined"
          startIcon={<AttachEmailOutlinedIcon />}
          size="medium"
          sx={{
            borderRadius: 1,
            height: 40,
            alignSelf: 'start',
          }}
          onClick={handleClickOpenExportDataForm}
        >
          Exportar
        </Button>
      )}
    </Box>
  );
}

TableFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired, // add default prop
  filters: PropTypes.node.isRequired,
  helperText: PropTypes.string,
  handleClickOpenExportDataForm: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.bool,
  ]),
};
TableFilters.defaultProps = {
  helperText: 'Buscar',
  handleClickOpenExportDataForm: false,
};
export default TableFilters;
