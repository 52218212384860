import {
  DEFAULT_LINEAL_UNIT_MEASURE,
  DEFAULT_WEIGHT_UNIT_MEASURE,
} from 'lib/productUnitMeasures';

function translateUpdatedProduct(data) {
  const {
    id,
    productName,
    storageType,
    deliveryStorageType,
    measures,
    barcode,
  } = data;
  return {
    id,
    name: productName,
    storageType,
    deliveryStorageType,
    barcode: barcode.map(({ value }) => value),
    measures: {
      width: {
        value: Number(measures.width.value),
        unit: measures.width.unit || DEFAULT_LINEAL_UNIT_MEASURE,
      },
      height: {
        value: Number(measures.height.value),
        unit: measures.height.unit || DEFAULT_LINEAL_UNIT_MEASURE,
      },
      length: {
        value: Number(measures.length.value),
        unit: measures.length.unit || DEFAULT_LINEAL_UNIT_MEASURE,
      },
      weight: {
        value: Number(measures.weight.value),
        unit: measures.weight.unit || DEFAULT_WEIGHT_UNIT_MEASURE,
      },
    },
  };
}
export default translateUpdatedProduct;
