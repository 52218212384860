import { Outlet, Routes, Route, Navigate } from 'react-router-dom';

import EditPackForm from './forms/edit';
import NewPackForm from './forms/new';
import PackTable from './list';

function Packs() {
  return (
    <>
      <Outlet />
      <Routes>
        <Route path="*" element={<Navigate to="/catalogs/packs" />} />
        <Route index element={<PackTable />} />
        <Route path="/new" element={<NewPackForm />} />
        <Route path=":id/edit" element={<EditPackForm />} />
      </Routes>
    </>
  );
}

export default Packs;
