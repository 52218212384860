/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';

import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';

import client from '../../../apollo/client';
import TabPanel from '../../../components/tabs/TabPanel';
import { ORDER_STATUSES } from '../../../lib/orderStatuses';
import { SHIPPING_METHODS } from '../../../lib/shippingMethods';
import OrdersList from './components/OrdersList';

const NIN_ORDER_STATUSES_FILTER = [
  ORDER_STATUSES.outForDelivery,
  ORDER_STATUSES.delivered,
  ORDER_STATUSES.canceled,
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function OrderTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="orders tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Todas" {...a11yProps(0)} />
          <Tab
            label={
              <Tooltip title="Solo las órdenes que necesitan una verificación en la dirección">
                <span>Direcciones pendientes</span>
              </Tooltip>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Tooltip title="Solo las órdenes que no han salido del hub">
                <span>Express</span>
              </Tooltip>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Tooltip title="Solo las órdenes que no han salido del hub">
                <span>Same day</span>
              </Tooltip>
            }
            {...a11yProps(3)}
          />
        </Tabs>
        <Button
          endIcon={<ReplayOutlinedIcon />}
          variant="outlined"
          onClick={async () => client.resetStore()}
        >
          Actualizar información
        </Button>
      </Box>
      <TabPanel value={value} index={0}>
        <OrdersList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrdersList
          title="Órdenes con verificación pendiente"
          customFilters={{
            ninStatus: NIN_ORDER_STATUSES_FILTER,
            shippingMethod: [
              SHIPPING_METHODS.EXPRESS,
              SHIPPING_METHODS.SAME_DAY,
              SHIPPING_METHODS.STANDARD,
            ],
            onlyInvalidAddress: true,
          }}
          hasProcessingTime
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrdersList
          title="Órdenes express"
          customFilters={{
            ninStatus: NIN_ORDER_STATUSES_FILTER,
            shippingMethod: [SHIPPING_METHODS.EXPRESS, SHIPPING_METHODS.FLASH],
          }}
          hasProcessingTime
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OrdersList
          title="Órdenes same day"
          customFilters={{
            ninStatus: NIN_ORDER_STATUSES_FILTER,
            shippingMethod: [SHIPPING_METHODS.SAME_DAY],
          }}
          hasProcessingTime
        />
      </TabPanel>
    </Box>
  );
}
export default OrderTabs;
