import { useMemo } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import grey from '@mui/material/colors/grey';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import dateParser from '../../../../helpers/dateParser';
import { ORDER_STATUSES } from '../../../../lib/orderStatuses';
import {
  ORDER_STATUS_STEPPER,
  STATUS_ORDER_MAPPING,
  STATUS_ORDER_MAPPING_LABEL,
} from '../../../../lib/orderStatusHistory';
import SectionContainer from './SectionContainer';
import CustomStepConnector from './stepper/CustomStepConnector';
import CustomStepIcon from './stepper/CustomStepIcon';

const steps = ORDER_STATUS_STEPPER;

function FollowUpSection({ statusHistory, currentStatus }) {
  const statusHistoryWithoutCanceled = statusHistory.filter(
    (e) => e.event !== ORDER_STATUSES.canceled,
  );
  const mostRecentEvent =
    currentStatus === ORDER_STATUSES.canceled
      ? statusHistoryWithoutCanceled[0]
      : statusHistory.find((e) => e.event === currentStatus) || {};

  const activeStep = useMemo(() => {
    if (!mostRecentEvent) {
      return 0;
    }

    const activeIndex = ORDER_STATUS_STEPPER.findIndex(
      (orderStatusStep) =>
        orderStatusStep === STATUS_ORDER_MAPPING[mostRecentEvent.event],
    );

    return activeIndex === -1 ? 0 : activeIndex;
  }, [mostRecentEvent]);

  const canceledEvent = statusHistory.find(
    (statusOrderEvent) => statusOrderEvent.event === ORDER_STATUSES.canceled,
  );
  const statusHistoryWithCanceledInfo = statusHistory.map((event) => {
    const eventStatus = event.event;
    if (eventStatus === mostRecentEvent.event) {
      return { ...event, canceledAt: canceledEvent?.createdAt };
    }
    return event;
  });
  const finalSteps = steps.map((step) => {
    const event = statusHistoryWithCanceledInfo.find(
      (statusOrderEvent) =>
        STATUS_ORDER_MAPPING[statusOrderEvent.event] === step,
    );
    const author = statusHistoryWithCanceledInfo.find(
      (statusOrderEvent) =>
        STATUS_ORDER_MAPPING[statusOrderEvent.event] === step &&
        statusOrderEvent.author,
    )?.author;
    return {
      value: step,
      ...event,
      label: STATUS_ORDER_MAPPING_LABEL[step],
      author,
    };
  });

  return (
    <SectionContainer title="Seguimiento">
      <Box
        sx={{
          display: 'flex',
          marginY: 4,
          marginX: 'auto',
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<CustomStepConnector />}
          sx={{ width: 980 }}
        >
          {finalSteps.map((step, index) => (
            <Step key={step.value}>
              <StepLabel
                sx={{ marginLeft: '28px' }}
                StepIconComponent={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <CustomStepIcon {...props} />
                )}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'sans-serif',
                      marginTop: 1,
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                  >
                    {(index < activeStep || index === activeStep) && (
                      <CheckIcon sx={{ marginRight: 0.5 }} />
                    )}
                    {step.label}
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      marginTop: 2,
                      fontSize: 14,
                      color: grey[800],
                    }}
                  >
                    {dateParser(step.createdAt)}
                  </Typography>
                  {step.author && (
                    <Typography
                      variant="caption"
                      sx={{
                        marginBottom: 3,
                        fontSize: 14,
                      }}
                    >
                      {`por: ${step.author}`}
                    </Typography>
                  )}
                  {step.canceledAt && (
                    <Typography sx={{ fontSize: 11, color: grey[800] }}>
                      {`**Esta orden ha sido CANCELADA el ${dateParser(
                        step.canceledAt,
                      )}, por lo tanto no se podrá continuar con las siguientes etapas.`}
                    </Typography>
                  )}
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </SectionContainer>
  );
}

FollowUpSection.propTypes = {
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      event: PropTypes.string,
    }),
  ).isRequired,
  currentStatus: PropTypes.oneOf(Object.keys(ORDER_STATUSES)).isRequired,
};

export default FollowUpSection;
