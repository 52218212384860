function translateMeasures(measures) {
  if (!measures) return '-';

  const {
    length: lengthFromMeasures,
    height: heightFromMeasures,
    width: widthFromMeasures,
  } = measures;

  const length = !lengthFromMeasures?.value
    ? '-'
    : `${lengthFromMeasures?.value} ${lengthFromMeasures?.unit} `;
  const height = !heightFromMeasures?.value
    ? '-'
    : `${heightFromMeasures?.value} ${heightFromMeasures?.unit}`;

  const width = !widthFromMeasures?.value
    ? '-'
    : `${widthFromMeasures?.value} ${widthFromMeasures?.unit}`;

  if (length === '-' && height === '-' && width === '-') {
    return '-';
  }

  return `${length} x ${width} x ${height}`;
}
function translateWeight(weight) {
  if (!weight) return '-';
  const { unit: weightUnit, value: weightValue } = weight;
  if (!weightValue) return '-';
  return `${weightValue} ${weightUnit}`;
}

export { translateMeasures, translateWeight };
