import { useState, useEffect } from 'react';

import { useQuery, useLazyQuery, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import cache from '../../../../../apollo/cache';
import MenuProps from '../../../../../components/selects/styles';
import GET_FILTERS from '../../../../../gql/filters';
import GET_RENTS from '../../../../../gql/warehouses';
import { REQUIRED_FIELD } from '../../../../../lib/form';

function NewOrderSellerWarehouse({
  register,
  control,
  setValue,
  errors,
  defaultValues,
}) {
  const [formOptions, setFormOptions] = useState({
    sellers: [],
    warehouses: [],
  });

  useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        orderFilterOptions: { sellers: sellerOptions },
      },
    }) => {
      const sellers = sellerOptions.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFormOptions((prevState) => ({ ...prevState, sellers }));
    },
  });

  const [getRents, { loading: rentsLoading, client }] = useLazyQuery(
    GET_RENTS,
    {
      onCompleted: ({ rents: rentedWarehousesOptions }) => {
        const rentedWarehouses = rentedWarehousesOptions.map(
          ({ id, name, computedAddress }) => ({
            value: id,
            label: name,
            computedAddress,
          }),
        );
        setFormOptions((prevState) => ({
          ...prevState,
          warehouses: rentedWarehouses,
        }));
      },
    },
  );

  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });

  const watchWarehouseId = useWatch({
    name: 'warehouseId',
    control,
  });

  useEffect(() => {
    if (watchSellerId) {
      getRents({ variables: { sellerId: watchSellerId } });
    }
  }, [watchSellerId]);

  useEffect(() => {
    if (watchWarehouseId) {
      const cacheWarehouseIdObject = cache.identify({
        __typename: 'Warehouse',
        id: watchWarehouseId,
      });
      const warehouse = client.readFragment({
        id: cacheWarehouseIdObject,
        fragment: gql`
          fragment WarehouseObject on Warehouse {
            id
            name
            computedAddress {
              label
            }
          }
        `,
      });
      setValue('warehouseAddress', warehouse.computedAddress.label);
    }
  }, [watchWarehouseId]);
  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="subtitle1">2. Vendedor y tienda</Typography>
        {rentsLoading && <CircularProgress size={20} color="secondary" />}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          required
          select
          size="small"
          label="Vendedor"
          error={Boolean(errors.sellerId)}
          sx={{ flexGrow: 1 }}
          SelectProps={{ MenuProps }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('sellerId', { required: REQUIRED_FIELD })}
          helperText={errors.sellerId && errors.sellerId.message}
          defaultValue={defaultValues.sellerId}
        >
          {formOptions.sellers.map(
            ({ value: optionValue, label: optionLabel }) => (
              <MenuItem key={`${optionValue}`} value={optionValue}>
                {optionLabel}
              </MenuItem>
            ),
          )}
        </TextField>
        <TextField
          required
          select
          size="small"
          label="Tienda"
          error={Boolean(errors.warehouseId)}
          sx={{ flexGrow: 1 }}
          SelectProps={{ MenuProps }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('warehouseId', { required: REQUIRED_FIELD })}
          helperText={errors.warehouseId && errors.warehouseId.message}
          defaultValue={defaultValues.warehouseId}
          disabled={!watchSellerId || rentsLoading}
        >
          {formOptions.warehouses.map(
            ({ value: optionValue, label: optionLabel }) => (
              <MenuItem key={`${optionValue}`} value={optionValue}>
                {optionLabel}
              </MenuItem>
            ),
          )}
        </TextField>
      </Box>
    </Box>
  );
}
NewOrderSellerWarehouse.propTypes = {
  errors: PropTypes.shape({
    sellerId: PropTypes.shape({ message: PropTypes.string }),
    warehouseId: PropTypes.shape({ message: PropTypes.string }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  defaultValues: PropTypes.shape({
    sellerId: PropTypes.string,
    warehouseId: PropTypes.string,
  }).isRequired,
};
export default NewOrderSellerWarehouse;
