import { gql } from '@apollo/client';

const GET_SELLERS = gql`
  query getSellers(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: SellerFilters!
  ) {
    getSellers(offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      nodes {
        id
        name
        enabled
      }
      total
      pageCount
    }
  }
`;
export default GET_SELLERS;
