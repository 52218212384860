import { gql } from '@apollo/client';

const GET_DELIVERY_INTEGRATIONS = gql`
  query getDeliveryIntegrations(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: DeliveryIntegrationFilters!
  ) {
    deliveryIntegrations(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        sellerId
        systemName
        description
      }
      total
      pageCount
    }
  }
`;
export default GET_DELIVERY_INTEGRATIONS;
