import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link, useParams } from 'react-router-dom';

import storeLoader from '../../../assets/images/store-loader.gif';
import MainBox from '../../../components/containers/MainBox';
import Error from '../../../components/Error';
import PageTitle from '../../../components/layout/PageTitle';
import { GET_ORDER_BY_ID } from '../../../gql/orders';
import STATUS_COLOR_MAPPING from '../../../helpers/statusColorMapping';
import { ORDER_STATUS_LABEL_MAPPING } from '../../../lib/orderStatuses';
import CustomerSection from './components/CustomerSection';
import DeliverySection from './components/DeliverySection';
import DocumentsSection from './components/DocumentsSection';
import FollowUpSection from './components/FollowUpSection';
import GeneralOrderSection from './components/GeneralOrderSection';
import IssuesSection from './components/IssuesSection';

function Order() {
  const { orderId } = useParams();

  const { loading, error, data } = useQuery(GET_ORDER_BY_ID, {
    variables: { id: orderId },
  });

  const clientAttachedFiles = useMemo(
    () =>
      data?.getOrderById?.attachedFiles.map((file) => {
        const {
          title,
          key,
          presignedGetFileUrl: { presignedUrl },
        } = file;
        const fileType = key.split('.')[1];
        const document = { title, url: presignedUrl, fileType, key };
        return { document, key };
      }),
    [data],
  );

  if (error) {
    return (
      <MainBox>
        <Error />
      </MainBox>
    );
  }
  if (loading)
    return (
      <MainBox>
        <img src={storeLoader} alt="store-loader" width="110px" />
        <Typography>Cargando información</Typography>
      </MainBox>
    );

  const {
    businessType,
    comment,
    customer,
    delivery,
    discountsValue,
    invoiceURL,
    items,
    issuesData,
    parsedCreatedAt: createdAt,
    hasEmptyPickingList,
    orderCurrency,
    seller: { name: sellerName },
    sourceOrderId,
    shippingValue,
    status,
    statusHistory,
    system: { id: platformName },
    totalValue,
    valuesWithTaxes,
    warehouse: { name: warehouseName },
    wmsId,
  } = data.getOrderById;

  const generalOrderInfo = {
    businessType,
    comment,
    createdAt,
    platformName,
    sellerName,
    sourceOrderId,
    warehouseName,
    wmsId,
    itemsDetails: {
      items,
      summary: {
        discountsValue,
        shippingValue,
        valuesWithTaxes,
        totalValue,
      },
      currency: orderCurrency,
    },
  };

  const pickingListURL = hasEmptyPickingList
    ? null
    : `/orders/${orderId}/picking-list-label`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0.65,
        margin: 'auto',
        paddingTop: 3,
      }}
    >
      <PageTitle>
        Orden #{sourceOrderId || orderId}
        <Chip
          label={
            <Typography sx={{ fontWeigth: 500 }}>
              {ORDER_STATUS_LABEL_MAPPING[status.value]}
            </Typography>
          }
          color={STATUS_COLOR_MAPPING[status.value]}
          variant="outlined"
          sx={{ marginLeft: 3 }}
        />
        <IconButton component={Link} to="pdf">
          <PictureAsPdfOutlinedIcon />
        </IconButton>
      </PageTitle>

      <GeneralOrderSection generalOrderInfo={generalOrderInfo} />
      <FollowUpSection
        statusHistory={statusHistory}
        currentStatus={status.value}
      />
      <CustomerSection customer={customer} />
      <DeliverySection delivery={delivery} orderId={orderId} />
      <DocumentsSection
        attachedFiles={clientAttachedFiles}
        invoiceURL={invoiceURL}
        pickingListURL={pickingListURL}
      />
      <IssuesSection
        issues={issuesData}
        orderId={orderId}
        sourceOrderId={sourceOrderId}
      />
    </Box>
  );
}

export default Order;
