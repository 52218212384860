import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';

import storeLoader from '../../../../assets/images/store-loader.gif';
import Error from '../../../../components/Error';
import TableBox from '../../../../components/table/TableBox';
import { GET_ORDER_BY_ID } from '../../../../gql/orders';
import dateParser from '../../../../helpers/dateParser';

function PickingList() {
  const styles = StyleSheet.create({
    pageRow: {
      flexDirection: 'row',
      textAlign: 'center',
      alignItems: 'flex-end',
    },
    pageColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    summaryColumn: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: 0,
    },
    section: {
      width: 80,
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',

      paddingTop: 2,
      paddingBottom: 2,
      textAlign: 'center',
      marginLeft: 4,
      marginTop: 2,
    },
  });
  const [orderData, setOrderData] = useState({});
  const { orderId } = useParams();
  const { loading, error } = useQuery(GET_ORDER_BY_ID, {
    variables: { id: orderId },
    onCompleted: ({ getOrderById }) => {
      setOrderData({
        ...getOrderById,
        pickingList: getOrderById.pickingList.map((pickingProduct) => ({
          ...pickingProduct,
          ...pickingProduct.product,
        })),
      });
    },
  });
  const lineSeparator = useMemo(
    () => (
      <Text
        style={{
          fontSize: 7,
          width: `100%`,
          fontWeight: 700,
          margin: 2,
        }}
      >
        - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      </Text>
    ),
    [],
  );

  const tableData = useMemo(
    () => ({
      columnsName: ['Producto', 'Cantidad'],
      columns: ['name', 'quantity'],
      orderData: orderData.pickingList,
    }),
    [orderData],
  );
  if (loading) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <img src={storeLoader} alt="store-loader" width="110px" />
          <Typography>Cargando información</Typography>
        </TableBox>
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <Error message="No pudimos encontrar la información de esta orden." />
        </TableBox>
      </Box>
    );
  }
  return (
    <Box
      style={{
        margin: 'auto',
        minHeight: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <PDFViewer
        style={{
          width: '100%',
          height: window.innerHeight,
          alignSelf: 'center',
        }}
      >
        <Document>
          <Page size="A8" wrap={false} style={{ padding: 10 }}>
            <View style={styles.pageColumn}>
              <Text
                style={{
                  fontSize: 9,
                  width: `100%`,
                  fontWeight: 700,
                  margin: 5,
                  textAlign: 'center',
                }}
              >
                Store Central
              </Text>
              <Text
                style={{
                  fontSize: 9,
                  width: `100%`,
                  fontWeight: 700,
                  margin: 5,
                }}
              >
                {`#Orden WMS: ${
                  orderData.wmsId ? orderData.wmsId : 'Sin asignar'
                }\n`}
              </Text>
              {lineSeparator}
            </View>
            <View style={styles.summaryColumn}>
              <Text
                style={{
                  fontSize: 6,
                  width: `100%`,
                  fontWeight: 700,
                  margin: 2,
                }}
              >
                {`Método de envío: ${
                  orderData.delivery.shippingMethod
                    ? orderData.delivery.shippingMethod[0].toUpperCase() +
                      orderData.delivery.shippingMethod.slice(1).toLowerCase()
                    : 'Sin asignar'
                }\n
              Vendedor: ${orderData.seller.name}\n
              Creación: ${dateParser(orderData.createdAt)}
              `}
              </Text>
              {lineSeparator}
            </View>
            <View style={styles.summaryColumn}>
              <Text
                style={{
                  fontSize: 6,
                  width: `100%`,
                  fontWeight: 700,
                  margin: 2,
                }}
              >
                {`${orderData.delivery.dropOffAddress.sourceAddress}\n
                ${orderData.customer?.phone}\n
                ${orderData.customer?.email}`}
              </Text>
              {lineSeparator}
            </View>

            <View style={styles.rowView}>
              {tableData.columnsName.map((c) => (
                <Text
                  key={c}
                  style={{
                    fontSize: 8,
                    width: `${100 / tableData.columnsName.length}%`,
                    fontWeight: 100,
                  }}
                >
                  {c}
                </Text>
              ))}
            </View>
            {tableData.orderData.map((rowData) => (
              <View key={rowData.sku} style={styles.rowView}>
                {tableData.columns.map((c) => (
                  <Text
                    key={rowData.sku}
                    style={{
                      fontSize: 6,
                      width: `${100 / tableData.columns.length}%`,
                    }}
                  >
                    {c === 'name'
                      ? `${rowData[c]} (sku: ${rowData.sku})`
                      : rowData[c]}
                  </Text>
                ))}
              </View>
            ))}
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
}

export default PickingList;
