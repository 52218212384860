import {
  DEFAULT_LINEAL_UNIT_MEASURE,
  DEFAULT_WEIGHT_UNIT_MEASURE,
} from 'lib/productUnitMeasures';

const PACK_TYPE_DYNAMIC_VALUE = 'dynamic';

function translateNewPack(data) {
  const { sellerId, productName, sku, items, measures } = data;
  const components = items.map((item) => {
    const { id, quantity } = item;
    const quantityInt = parseInt(quantity, 10);
    return {
      productId: id,
      ...(data.packType === PACK_TYPE_DYNAMIC_VALUE
        ? { maxComponentQuantity: quantityInt }
        : { quantity: quantityInt }),
    };
  });
  const pack = {
    sellerId,
    name: productName,
    sku,
    components,
    measures: {
      width: {
        value: Number(measures.width.value),
        unit: DEFAULT_LINEAL_UNIT_MEASURE,
      },
      height: {
        value: Number(measures.height.value),
        unit: DEFAULT_LINEAL_UNIT_MEASURE,
      },
      length: {
        value: Number(measures.length.value),
        unit: DEFAULT_LINEAL_UNIT_MEASURE,
      },
      weight: {
        value: Number(measures.weight.value),
        unit: DEFAULT_WEIGHT_UNIT_MEASURE,
      },
    },
  };
  return pack;
}
export default translateNewPack;
