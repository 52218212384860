import { useState, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import CustomDrawer from '../../../../components/containers/CustomDrawer';
import { UPDATE_ORDER_ADDRESS } from '../../../../gql/orders';
import { isInDeliveryApps } from '../../../../lib/integratedCarrierApps';
import { ORDER_STATUSES } from '../../../../lib/orderStatuses';
import OrderAddressForm from './OrderAddressForm';

const UNKNOWN = 'desconocido';

function getAddressAlarm({ isVerified, isPartialMatch, systemName }) {
  switch (true) {
    case isInDeliveryApps({ systemName }):
      return {
        isVerifiedByOps: false,
        isVerifiedByGoogle: false,
        missingVerification: false,
      };
    case isVerified:
      return {
        isVerifiedByOps: true,
        isVerifiedByGoogle: false,
        missingVerification: false,
      };
    case isPartialMatch && !isVerified:
      return {
        isVerifiedByOps: false,
        isVerifiedByGoogle: false,
        missingVerification: true,
      };
    default:
      return {
        isVerifiedByOps: false,
        isVerifiedByGoogle: true,
        missingVerification: false,
      };
  }
}

function OrderAddress({
  id,
  systemName,
  sourceOrderId,
  dropOffAddress,
  status,
}) {
  const { computedAddress, sourceAddress } = dropOffAddress || {};
  const { isVerified, isVerifiedBy, isVerifiedAt, isPartialMatch } =
    computedAddress || {};
  const {
    register,
    formState: { errors, defaultValues },
    handleSubmit,
    watch,
    reset: resetForm,
    control,
    setValue,
  } = useForm();

  const [showForm, setShowForm] = useState(false);
  const customComputedAddressLabel = useMemo(() => {
    const {
      street,
      addressNumber,
      complement,
      municipality,
      subRegion,
      region,
      country,
      postalCode,
    } = computedAddress;
    return [
      street,
      addressNumber,
      complement,
      municipality,
      subRegion,
      region,
      country,
      postalCode,
    ]
      .filter(Boolean)
      .join(', ');
  }, [computedAddress]);

  const [updateOrderAddress, { loading, error, reset }] = useMutation(
    UPDATE_ORDER_ADDRESS,
    {
      onError: () => {},
      onCompleted: () => setShowForm(false),
    },
  );
  const onClickUpdateAddress = (formData) => {
    const { complement, addressNumber, municipality, postalCode } = formData;

    const newComputedAddress = {
      ...formData.computedAddress,
      postalCode: postalCode || '',
      municipality: municipality || formData.computedAddress.municipality,
      addressNumber: addressNumber || formData.computedAddress.addressNumber,
      complement,
    };
    updateOrderAddress({
      variables: { id, computedAddress: newComputedAddress },
    });
  };
  const onClickShowForm = () => {
    resetForm();
    setShowForm(true);
  };
  const onClickHideForm = () => {
    reset();
    setShowForm(false);
  };
  const onSubmit = handleSubmit(onClickUpdateAddress);
  const canUpdateAddress = useMemo(() => {
    const orderStatuses = Object.keys(ORDER_STATUSES);
    return (
      orderStatuses.indexOf(ORDER_STATUSES.readyAtStore) >
        orderStatuses.indexOf(ORDER_STATUSES[status]) &&
      !isInDeliveryApps({ systemName })
    );
  }, [status, systemName]);
  const { isVerifiedByOps, isVerifiedByGoogle, missingVerification } = useMemo(
    () => getAddressAlarm({ isVerified, isPartialMatch, systemName }),
    [isVerified, isPartialMatch, systemName],
  );
  const isVerifiedAtMessage = useMemo(() => {
    if (!isVerifiedAt) {
      return '';
    }
    return `(${isVerifiedAt})`;
  }, [isVerifiedAt]);
  return (
    <Box
      id={`update-order-address-id-${id}`}
      key={`update-order-address-key-${id}`}
      sx={{ display: 'flex', alignItems: 'center', width: 200 }}
    >
      <Typography
        variant="caption"
        color={missingVerification ? 'error' : undefined}
      >
        {customComputedAddressLabel}
      </Typography>
      {isVerifiedByOps && (
        <Tooltip
          title={`Verificación manual por ${
            isVerifiedBy || UNKNOWN
          } ${isVerifiedAtMessage}`}
        >
          <DoneAllIcon color="success" />
        </Tooltip>
      )}
      {isVerifiedByGoogle && (
        <Tooltip title="Verificada por sistema">
          <DoneIcon color="success" />
        </Tooltip>
      )}
      {missingVerification && (
        <Tooltip title="Verificación necesaria, es probable que exista un error en la dirección">
          <PriorityHighIcon color="error" />
        </Tooltip>
      )}
      {!showForm && canUpdateAddress && (
        <Tooltip title="Actualizar dirección de entrega">
          <IconButton
            id="invoiceButton"
            variant="body2"
            onClick={onClickShowForm}
            target="_blank"
            rel="noopener noreferrer"
          >
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      <CustomDrawer
        drawerKey={`update-order-address-drawer-key${id}`}
        title={<Typography>#Orden: {sourceOrderId}</Typography>}
        open={showForm}
        onClose={onClickHideForm}
        width={500}
      >
        <Typography variant="h5">Actualizar dirección de entrega</Typography>
        <Typography>
          Dirección ingresada por el usuario: {sourceAddress}
        </Typography>
        <OrderAddressForm
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          onSubmit={onSubmit}
          loading={loading}
          mutationError={error}
          defaultValues={defaultValues}
          setValue={setValue}
          resetForm={resetForm}
        />
      </CustomDrawer>
    </Box>
  );
}
OrderAddress.propTypes = {
  id: PropTypes.string.isRequired,
  systemName: PropTypes.string.isRequired,
  sourceOrderId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dropOffAddress: PropTypes.shape({
    sourceAddress: PropTypes.string,
    computedAddress: PropTypes.shape({
      addressNumber: PropTypes.string,
      country: PropTypes.string,
      label: PropTypes.string,
      street: PropTypes.string,
      complement: PropTypes.string,
      postalCode: PropTypes.string,
      municipality: PropTypes.string,
      region: PropTypes.string,
      subRegion: PropTypes.string,
      isVerified: PropTypes.bool,
      isVerifiedBy: PropTypes.string,
      isVerifiedAt: PropTypes.string,
      isPartialMatch: PropTypes.bool,
    }),
    setValue: PropTypes.func,
  }).isRequired,
};
export default OrderAddress;
