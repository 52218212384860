import { object, array, string, number, setLocale } from 'yup';

const STORAGE_TYPES = ['DRY', 'REFRIGERATED', 'FROZEN'];

setLocale({
  mixed: {
    default: 'Campo inválido',
    required: ({ path }) => {
      const index = Number(path.match(/\[(.*?)\]/)[1]);
      return { index, path, message: 'Campo obligatorio' };
    },
    oneOf: ({ value, values, path }) => {
      const index = Number(path.match(/\[(.*?)\]/)[1]);
      return {
        index,
        path,
        message: `${value} no pertenece a las opciones permitidas: ${values}`,
      };
    },
    notType: ({ path, value }) => {
      const index = Number(path.match(/\[(.*?)\]/)[1]);
      return {
        index,
        path,
        message: `${value} no pertenece al tipo correcto`,
      };
    },
  },
});

const productsSchema = array().of(
  object({
    sku: string().required(),
    assignedSku: string(),
    storageType: string().oneOf(STORAGE_TYPES).required(),
    deliveryStorageType: string().oneOf(STORAGE_TYPES).required(),
    barcode: array().of(string()),
    measures: object({
      length: object({
        value: number().required(),
        unit: string().oneOf(['cm', 'm']).required(),
      }).required(),
      width: object({
        value: number().required(),
        unit: string().oneOf(['cm', 'm']).required(),
      }).required(),
      height: object({
        value: number().required(),
        unit: string().oneOf(['cm', 'm']).required(),
      }).required(),
      weight: object({
        value: number().required(),
        unit: string().oneOf(['kg', 'g']).required(),
      }).required(),
    }).required(),
  }),
);
export default productsSchema;
