import Typography from '@mui/material/Typography';

import ChipNumber from '../../../../../components/chips/ChipNumber';
import HeaderListData from '../../../../commons/HeaderListData';

const columns = () => [
  {
    Header: <HeaderListData>Sku</HeaderListData>,
    accessor: 'sku',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Cantidad transferida</HeaderListData>,
    accessor: 'quantityDelta',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (value === 0 || value) && <ChipNumber label={value} />,
  },
];
export default columns;
