import PropTypes from 'prop-types';

import MultipleSelect from 'components/selects/MultiSelect';

function OrderIntegrationFilters({ filters, setFilters, sellersOptions }) {
  const hasOneSeller = sellersOptions.length === 1;
  return (
    <div>
      {!hasOneSeller && (
        <MultipleSelect
          id="order-filter-multiselect-seller"
          helperText="Filtrar por vendedor"
          selectLabel="Vendedor"
          sx={{ width: 180 }}
          value={filters.id}
          setValue={setFilters}
          options={sellersOptions}
          eventName="id"
        />
      )}
    </div>
  );
}
OrderIntegrationFilters.propTypes = {
  filters: PropTypes.shape({
    id: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  sellersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

OrderIntegrationFilters.defaultProps = {
  sellersOptions: [],
};
export default OrderIntegrationFilters;
