import { useCallback } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function Field({ label, value, type, href, hasClipboard }) {
  const handleClipboardButton = useCallback(() => {
    navigator.clipboard
      .writeText(href || value)
      // eslint-disable-next-line no-alert
      .then(() => alert('¡Texto copiado al portapapeles!'))
      // eslint-disable-next-line no-alert
      .catch((error) => alert(`Error al copiar el texto: ${error}`));
  }, [hasClipboard]);

  return (
    <Box
      sx={{
        paddingRight: '18px',
        paddingY: '32px',
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
      }}
    >
      <Typography
        sx={{
          color: '#757575',
          fontSize: '14px',
          fontWeight: 500,
          marginBottom: '15px',
        }}
      >
        {label}
        {((hasClipboard && value && !href) || (type === 'link' && href)) && (
          <Button onClick={handleClipboardButton}>
            <ContentCopyIcon
              sx={{ marginLeft: '6px', width: '16px', height: 'auto' }}
            />
          </Button>
        )}
      </Typography>

      {type === 'link' && href ? (
        <Link
          href={href}
          color="#0099EC"
          target="_blank"
          sx={{
            marginBottom: '16px',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          {value}
          <OpenInNewIcon sx={{ marginLeft: 0.5 }} />
        </Link>
      ) : (
        <Typography variant="h6" align="justify" sx={{ maxWidth: 900 }}>
          {(type === 'text' && value) || '-'}
        </Typography>
      )}
    </Box>
  );
}

Field.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.oneOf(['text', 'link']),
  href: PropTypes.string,
  hasClipboard: PropTypes.bool,
};

Field.defaultProps = {
  type: 'text',
  href: null,
  hasClipboard: false,
  value: null,
};

export default Field;
