import {
  seenTourListOrdersVar,
  seenTourListInventoryVar,
  seenTourListProductsVar,
  seenTourListOrdersIntegrationsVar,
} from '../../apollo/reactiveVariables';

function showTourGuideListOrdersLocalStorage() {
  localStorage.setItem('seenTourListOrders', 'true');
  seenTourListOrdersVar(true);
}
function showTourGuideListInventoryLocalStorage() {
  localStorage.setItem('seenTourListInventory', 'true');
  seenTourListInventoryVar(true);
}
function showTourGuideListProductsLocalStorage() {
  localStorage.setItem('seenTourListProducts', 'true');
  seenTourListProductsVar(true);
}
function showTourGuideListOrdersIntegrationsLocalStorage() {
  localStorage.setItem('seenTourListOrdersIntegrations', 'true');
  seenTourListOrdersIntegrationsVar(true);
}
export {
  showTourGuideListOrdersLocalStorage,
  showTourGuideListInventoryLocalStorage,
  showTourGuideListProductsLocalStorage,
  showTourGuideListOrdersIntegrationsLocalStorage,
};
