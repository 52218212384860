const keyMirror = require('keymirror');

const DEFAULT_VALUE = 0;

const CURRENCIES = keyMirror({
  CLP: null,
  PEN: null,
});

const CLPFormatter = Intl.NumberFormat('es-CL', {
  currency: CURRENCIES.CLP,
  style: 'currency',
});
const PENFormatter = Intl.NumberFormat('es-PE', {
  currency: CURRENCIES.PEN,
  style: 'currency',
});

const CURRENCY_FORMATTER = {
  [CURRENCIES.CLP]: CLPFormatter,
  [CURRENCIES.PEN]: PENFormatter,
};

const COUNTRY_CODE = keyMirror({
  CL: null,
  PE: null,
});

const COUNTRY_CODE_MAPPER = {
  [CURRENCIES.CLP]: COUNTRY_CODE.CL,
  [CURRENCIES.PEN]: COUNTRY_CODE.PE,
};

function formatCurrencyValue({ currency, value }) {
  if (currency)
    return CURRENCY_FORMATTER[currency].format(value || DEFAULT_VALUE);
  return value;
}

const FLAG_ICONS = 'https://flagcdn.com/w20';

export {
  CURRENCIES,
  CURRENCY_FORMATTER,
  COUNTRY_CODE_MAPPER,
  FLAG_ICONS,
  formatCurrencyValue,
};
