/* eslint-disable no-plusplus */

import { ApolloProvider } from '@apollo/client';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from 'date-fns/locale';
import { RouterProvider } from 'react-router-dom';

import client from 'apollo/client';
import router from 'routes/router';
import theme from 'styles/theme';

function App() {
  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
