import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import STORAGE_OPTIONS from 'lib/storageOptions';

const { SHORT_REQUIRED_FIELD, SHORT_INVALID_VALUE } = require('lib/form');

const CHARACTER_WIDTH_STORAGE_FIELD = '17ch';
const CHARACTER_WIDTH_MEASURES_FIELD = '14ch';
const CHARACTER_WIDTH_NAME_FIELD = '25ch';
// TO DO refactor products inline edit with this shared file
export const updateVariables = (formData, productRow) => {
  const variables = {
    input: {
      id: productRow.id,
      name: formData.name,
      storageType: formData.storageType.value,
    },
  };
  if (formData.measures.weight.value) {
    variables.input.measures = {
      height: {
        value: parseFloat(formData.measures.height.value),
        unit: 'cm',
      },
      length: {
        value: parseFloat(formData.measures.length.value),
        unit: 'cm',
      },
      width: {
        value: parseFloat(formData.measures.width.value),
        unit: 'cm',
      },
      weight: {
        value: parseFloat(formData.measures.weight.value),
        unit: 'kg',
      },
    };
  }
  return variables;
};

export const inlineForms = {
  name: (register, errors) => (
    <TextField
      size="small"
      label="Nombre"
      error={Boolean(errors.name)}
      helperText={(errors.name && errors.name.message) || ' '}
      // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
      {...register('name', {
        required: SHORT_REQUIRED_FIELD, // JS only: <p>error message</p> TS only support string
      })}
      sx={{ m: 1, width: CHARACTER_WIDTH_NAME_FIELD }}
    />
  ),
  measures: (register, errors, getValues) => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <TextField
        label="Largo"
        type="number"
        size="small"
        error={Boolean(errors.measures?.length)}
        helperText={
          errors.measures?.length && errors.measures.length.value.message
        }
        // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
        {...register('measures.length.value', {
          validate: {
            measureMissing: (v) =>
              !!v ===
                Object.values(getValues('measures')).some((el) => el.value) ||
              SHORT_REQUIRED_FIELD,
            positive: (v) => parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
          },
        })}
        sx={{ m: 1, width: CHARACTER_WIDTH_MEASURES_FIELD }}
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          inputProps: { min: 0, step: 'any' },
        }}
      />
      <TextField
        label="Ancho"
        type="number"
        size="small"
        error={Boolean(errors.measures?.width)}
        helperText={
          errors.measures?.width && errors.measures.width.value.message
        }
        // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
        {...register('measures.width.value', {
          validate: {
            measureMissing: (v) =>
              !!v ===
                Object.values(getValues('measures')).some((el) => el.value) ||
              SHORT_REQUIRED_FIELD,
            positive: (v) => parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
          },
        })}
        sx={{ m: 1, width: CHARACTER_WIDTH_MEASURES_FIELD }}
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          inputProps: { min: 0, step: 'any' },
        }}
      />
      <TextField
        label="Alto"
        type="number"
        size="small"
        error={Boolean(errors.measures?.height)}
        helperText={
          (errors.measures?.height && errors.measures.height.value.message) ||
          ' '
        }
        // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
        {...register('measures.height.value', {
          validate: {
            measureMissing: (v) =>
              !!v ===
                Object.values(getValues('measures')).some((el) => el.value) ||
              SHORT_REQUIRED_FIELD,
            positive: (v) => parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
          },
        })}
        sx={{ m: 1, width: CHARACTER_WIDTH_MEASURES_FIELD }}
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          inputProps: { min: 0, step: 'any' },
        }}
      />
    </Box>
  ),
  weight: (register, errors, getValues) => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <TextField
        label="Peso"
        type="number"
        size="small"
        error={Boolean(errors.measures?.weight)}
        helperText={
          (errors.measures?.weight && errors.measures.weight.value.message) ||
          ' '
        }
        // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
        {...register('measures.weight.value', {
          validate: {
            measureMissing: (v) =>
              !!v ===
                Object.values(getValues('measures')).some((el) => el.value) ||
              SHORT_REQUIRED_FIELD,
            positive: (v) => parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
          },
        })}
        sx={{ width: CHARACTER_WIDTH_MEASURES_FIELD }}
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          inputProps: { min: 0, step: 'any' },
        }}
      />
    </Box>
  ),
  storageType: (register, initialValue) => (
    <TextField
      label="Tipo de almacenaje"
      select
      size="small"
      helperText=" "
      // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
      {...register('storageType')}
      sx={{ width: CHARACTER_WIDTH_STORAGE_FIELD }}
      defaultValue={STORAGE_OPTIONS.find(
        // eslint-disable-next-line react/prop-types
        (el) => el.value === initialValue.value,
      )}
    >
      {STORAGE_OPTIONS.map((option) => (
        <MenuItem
          key={`${option.value}`}
          value={STORAGE_OPTIONS.find((el) => el.value === option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  ),
};
