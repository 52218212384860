import { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const MENU_ITEM_HYPERLINK_COMPONENT = 'a';
const TARGET_BLANK = '_blank';
const REL = 'noopener noreferrer';

function SimpleMenu({
  anchorEl,
  setAnchorEl,
  options,
  renderButton,
  categories,
}) {
  const open = Boolean(anchorEl);
  const isMissingHref = useCallback(
    (href, component) => component === MENU_ITEM_HYPERLINK_COMPONENT && !href,
    [],
  );
  const menuOptions = useMemo(
    () =>
      options.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.category]: [...(acc[curr.category] || []), curr],
        }),
        {},
      ),
    [options],
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const button = renderButton({ onClick: handleClick });
  return (
    <>
      {button}
      {!!options.length && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {Object.keys(menuOptions).map((category) => (
            <Box key={category}>
              <MenuItem sx={{ pointerEvents: 'none' }} component="span">
                <ListSubheader>{categories[category].label}</ListSubheader>
              </MenuItem>
              {menuOptions[category].map((option) => (
                <Tooltip
                  key={`menu-item-tooltip-${option.id || option.label}`}
                  title={option.disabled && option.disabledMessage}
                >
                  <span
                    key={`menu-item-tooltip-span-${option.id || option.label}`}
                  >
                    <MenuItem
                      sx={{ ml: 1 }}
                      component={option.component}
                      href={option.href}
                      key={`menu-item-simple-menu-${option.id || option.label}`}
                      target={
                        isMissingHref(option.href, option.component)
                          ? undefined
                          : TARGET_BLANK
                      }
                      rel={
                        isMissingHref(option.href, option.component)
                          ? undefined
                          : REL
                      }
                      disabled={option.disabled}
                      onClick={option.onClick}
                    >
                      {option.icon && (
                        <ListItemIcon>{option.icon}</ListItemIcon>
                      )}
                      <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                  </span>
                </Tooltip>
              ))}
            </Box>
          ))}
        </Menu>
      )}
    </>
  );
}
SimpleMenu.propTypes = {
  renderButton: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      component: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      disabledMessage: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  categories: PropTypes.shape({}),
};

SimpleMenu.defaultProps = {
  anchorEl: null,
  categories: null,
};
export default SimpleMenu;
