import { useState, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useForm, useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SubmitButton from 'components/buttons/SubmitButton';
import AlertDialog from 'components/dialogs/AlertDialog';
import FileInput from 'components/input-files/FileInput';
import FileTemplate from 'components/input-files/FileTemplate';
import PageTitle from 'components/layout/PageTitle';
import Table from 'components/table/Table';
import { CREATE_BATCH_ORDERS } from 'gql/orders';
import FILE_TYPES from 'lib/fileTypes';
import { REQUIRED_FIELD } from 'lib/form';
import NewReplenishmentSellerWarehouse from 'views/components/forms/SellerWarehouseInput';

import columns from './components/columns';
import OrderItems from './components/OrderItems';
import OrdersDataErrors from './components/OrdersDataErrors';
import TutorialDialog from './components/TutorialDialog';
import useUploadOrders from './hooks/useUploadOrders';

const { REACT_APP_FILE_BATCH_ORDERS_TEMPLATE_PATH } = process.env;
const CREATE_BATCH_ORDERS_ERROR_MESSAGE =
  'Ocurrió un error al enviar la solicitud.';

function NewBatchOrdersForm() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [showTutorialDialog, setShowTutorialDialog] = useState(false);
  const [createBatchOrders, { loading, error, reset: resetMutation }] =
    useMutation(CREATE_BATCH_ORDERS, {
      onCompleted: () => {
        resetMutation();
        setOpenDialog(true);
        setDialogContent(
          `Una vez terminada la solicitud, podrás ver las órdenes creadas en la sección de "Órdenes" y se enviará un reporte a tu correo sobre la creación masiva. `,
        );
      },
    });
  const {
    register,
    control,
    resetField,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      sellerId: '',
      warehouseId: '',
      isExcelValid: {
        uploaded: false,
        headers: false,
        data: false,
        rowId: false,
      },
    },
  });

  const {
    field: { value: isExcelValid },
  } = useController({
    name: 'isExcelValid',
    control,
    rules: {
      validate: (value) =>
        (value.headers && value.data && value.rowId) || REQUIRED_FIELD,
      required: REQUIRED_FIELD,
    },
  });
  const watchSellerId = watch('sellerId');
  const watchWarehouseId = watch('warehouseId');
  const { orders, onSubmit, fileParser, removeOrder, validationErrors } =
    useUploadOrders({
      createBatchOrders,
      setValue,
      watchSellerId,
      watchWarehouseId,
    });

  const renderSubComponent = (row) => {
    const {
      original: { items, orderCurrency },
    } = row;
    return <OrderItems items={items} currency={orderCurrency} />;
  };
  const tableColumns = useMemo(() => columns({ removeOrder }), [orders]);
  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <PageTitle handleClick={() => setShowTutorialDialog(true)}>
            Crear múltiples órdenes
          </PageTitle>
          <TutorialDialog
            setShowDialog={setShowTutorialDialog}
            showDialog={showTutorialDialog}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PriorityHighOutlinedIcon color="warning" />
              <Typography variant="caption">
                Todos los precios de la orden tienen incluido el impuesto
              </Typography>
            </Box>
            <NewReplenishmentSellerWarehouse
              title="1. Vendedor y tienda"
              register={register}
              resetField={resetField}
              control={control}
              setValue={setValue}
              keyFilterOptions="orderFilterOptions"
            />
            <Divider flexItem />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="subtitle1">
                2. Archivo Excel con órdenes
              </Typography>
              <FileTemplate
                filePath={REACT_APP_FILE_BATCH_ORDERS_TEMPLATE_PATH}
                title="Excel de ejemplo"
              />
              <FileInput
                fileTypes={[FILE_TYPES.XLSX, FILE_TYPES.CSV]}
                fileLabel="XLSX"
                fileParser={fileParser}
                error={Boolean(errors.isExcelValid)}
                errorMessage={errors?.isExcelValid?.message}
              />
            </Box>
            {isExcelValid.uploaded && !isExcelValid.headers && (
              <Alert severity="error">
                El formato del excel no está correcto. Revisa que siga las
                indicaciones del template.
              </Alert>
            )}
            {isExcelValid.uploaded && !isExcelValid.data && (
              <OrdersDataErrors errors={validationErrors} />
            )}
            {isExcelValid.uploaded && !isExcelValid.rowId && (
              <Alert severity="error">
                Existen filas en el archivo sin el número de la orden
                (orderNumber). Debes agregarlo para que el sistema pueda
                identificar las órdenes.
              </Alert>
            )}
            {!!orders.length && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="h5">Órdenes cargadas</Typography>
                <Table
                  data={orders}
                  columns={tableColumns}
                  renderSubComponent={renderSubComponent}
                  hasSearchBox={false}
                  pagination={{
                    hasPaginator: false,
                    controlledPageCount: orders.length,
                  }}
                  disableSortBy
                  hasStickyHeader={false}
                />
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {!!error && (
                  <Typography
                    color="error"
                    gutterBottom
                    align="center"
                    paragraph
                  >
                    {CREATE_BATCH_ORDERS_ERROR_MESSAGE}
                  </Typography>
                )}
                <SubmitButton
                  label="CREAR ÓRDENES"
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <AlertDialog
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
            navigate('/orders');
          }}
          dialogTitle="Creación de órdenes"
          dialogContent={dialogContent}
        />
      </Box>
    </Container>
  );
}
export default NewBatchOrdersForm;
