import { useState, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import SimpleSelect from 'components/selects/SimpleSelect';
import { UPDATE_ORDER_STATUS } from 'gql/orders';
import STATUS_COLOR_MAPPING from 'helpers/statusColorMapping';
import { ORDER_STATUS_LABEL_MAPPING } from 'lib/orderStatuses';

function OrderStatus({ id, value, options }) {
  const [showSelectStatus, setShowSelectStatus] = useState(false);
  const [statusSelected, setStatusSelected] = useState('');
  const [updateStatus, { loading, error, reset }] = useMutation(
    UPDATE_ORDER_STATUS,
    {
      onError: () => setShowSelectStatus(false),
      onCompleted: () => setShowSelectStatus(false),
    },
  );
  const onClickEditStatus = () => {
    reset();
    setStatusSelected('');
    setShowSelectStatus(true);
  };
  const onClickCancelUpdateStatus = () => {
    setShowSelectStatus(false);
  };
  const selectOptions = useMemo(
    () =>
      options.map(({ value: statusValue }) => ({
        value: statusValue,
        label: (
          <Typography variant="caption">
            {ORDER_STATUS_LABEL_MAPPING[statusValue]}
          </Typography>
        ),
      })),
    [value, options],
  );
  const isDisabledEditButton = useMemo(
    () => selectOptions.length === 0,
    [selectOptions],
  );
  return (
    <Box
      key={`update-order-status${id}-box`}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!showSelectStatus && (
          <Tooltip title="La orden tiene esta secuencia de estados: pendiente, procesando, lista en tienda, delivery confirmado, entregado a transportista, en camino y entregada.">
            <Chip
              label={
                <Typography variant="caption" sx={{ color: 'inherit' }}>
                  {ORDER_STATUS_LABEL_MAPPING[value]}
                </Typography>
              }
              sx={{ borderWidth: '2px' }}
              color={STATUS_COLOR_MAPPING[value]}
              variant="outlined"
            />
          </Tooltip>
        )}
        {showSelectStatus && (
          <SimpleSelect
            id={`update-order-status${id}`}
            variant="outlined"
            options={selectOptions}
            label="Estado"
            controlled={{
              controlledValue: statusSelected,
              setControlledValue: setStatusSelected,
            }}
            disabled={loading}
            InputProps={{
              sx: {
                width: 150,
                height: '32px',
                borderRadius: '20px',
                borderWidth: '2px',
              },
            }}
          />
        )}
        {!showSelectStatus && (
          <Tooltip
            title={
              isDisabledEditButton
                ? 'No es posible cambiar de estado'
                : 'Editar estado de la orden'
            }
          >
            <span>
              <IconButton
                id="invoiceButton"
                variant="body2"
                onClick={onClickEditStatus}
                target="_blank"
                rel="noopener noreferrer"
                disabled={isDisabledEditButton}
              >
                <EditOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {showSelectStatus && (
          <Tooltip title="Cancelar acción">
            <span>
              <IconButton
                id="invoiceButton"
                variant="body2"
                onClick={onClickCancelUpdateStatus}
                disabled={loading}
                target="_blank"
                rel="noopener noreferrer"
              >
                <UndoOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {showSelectStatus && (
          <Tooltip title="Actualizar estado">
            <span>
              {!loading && (
                <IconButton
                  id="invoiceButton"
                  variant="body2"
                  onClick={() => {
                    updateStatus({
                      variables: { id, status: statusSelected },
                    });
                  }}
                  disabled={statusSelected === ''}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={(theme) => ({ color: theme.palette.icons.valid })}
                >
                  <SaveOutlinedIcon />
                </IconButton>
              )}
            </span>
          </Tooltip>
        )}
        {loading && <CircularProgress size={15} color="secondary" />}
      </Box>
      {error && (
        <Typography
          color="error"
          variant="caption"
          noWrap
          key={`update-order-status${id}-error`}
        >
          Error para actualizar el estado
        </Typography>
      )}
    </Box>
  );
}

OrderStatus.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ).isRequired,
};
export default OrderStatus;
