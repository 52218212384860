const keyMirror = require('keymirror');

const ORDER_STATUSES = keyMirror({
  created: null,
  pendingApproval: null,
  pending: null,
  backorder: null,
  processing: null,
  readyAtStore: null,
  invoiced: null,
  deliveryConfirmed: null,
  releasedToCarrier: null,
  outForDelivery: null,
  delivered: null,
  canceled: null,
  error: null,
});

const ORDER_STATUSES_SEQUENCE = [
  ORDER_STATUSES.created,
  ORDER_STATUSES.pendingApproval,
  ORDER_STATUSES.pending,
  ORDER_STATUSES.backorder,
  ORDER_STATUSES.processing,
  ORDER_STATUSES.readyAtStore,
  ORDER_STATUSES.invoiced,
  ORDER_STATUSES.deliveryConfirmed,
  ORDER_STATUSES.releasedToCarrier,
  ORDER_STATUSES.outForDelivery,
  ORDER_STATUSES.delivered,
  ORDER_STATUSES.canceled,
  ORDER_STATUSES.error,
];

const ORDER_STATUS_LABEL_MAPPING = {
  [ORDER_STATUSES.created]: 'CREADA',
  [ORDER_STATUSES.pendingApproval]: 'PENDIENTE',
  [ORDER_STATUSES.pending]: 'PENDIENTE',
  [ORDER_STATUSES.processing]: 'EN PROCESO',
  [ORDER_STATUSES.readyAtStore]: 'LISTA EN TIENDA',
  [ORDER_STATUSES.invoiced]: 'LISTA EN TIENDA',
  [ORDER_STATUSES.deliveryConfirmed]: 'DELIVERY CONFIRMADO',
  [ORDER_STATUSES.releasedToCarrier]: 'ENTREGADA A TRANSPORTISTA',
  [ORDER_STATUSES.outForDelivery]: 'EN CAMINO',
  [ORDER_STATUSES.delivered]: 'ENTREGADA',
  [ORDER_STATUSES.canceled]: 'CANCELADA',
  [ORDER_STATUSES.error]: 'ERROR',
  [ORDER_STATUSES.backorder]: 'BACKORDER',
};

export { ORDER_STATUSES, ORDER_STATUS_LABEL_MAPPING, ORDER_STATUSES_SEQUENCE };
