import Typography from '@mui/material/Typography';

import ChipNumber from '../../../../../components/chips/ChipNumber';
import { CURRENCY_FORMATTER } from '../../../../../lib/currency';
import HeaderListData from '../../../../commons/HeaderListData';

const columns = () => [
  {
    Header: <HeaderListData>Sku</HeaderListData>,
    accessor: 'sku',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Nombre</HeaderListData>,
    accessor: 'productName',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Cantidad informada</HeaderListData>,
    accessor: 'quantity',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (value === 0 || value) && <ChipNumber label={value} />,
  },
  {
    Header: <HeaderListData>Cantidad recepcionada</HeaderListData>,
    accessor: 'quantityDelta',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (value === 0 || value) && <ChipNumber label={value} />,
  },
  {
    Header: <HeaderListData>Costo unitario</HeaderListData>,
    accessor: 'unitCost',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { costCurrency },
      },
    }) => (
      <Typography>
        {CURRENCY_FORMATTER[costCurrency]
          ? CURRENCY_FORMATTER[costCurrency].format(value)
          : ''}
      </Typography>
    ),
  },
  {
    Header: <HeaderListData>Número de lote</HeaderListData>,
    accessor: 'lotNumber',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData>Fecha vencimiento</HeaderListData>,
    accessor: 'expirationDate',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
];
export default columns;
