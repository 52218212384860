import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

function ArrayFormTextField({
  textFieldKey,
  register,
  width,
  error,
  helperText,
  inputType,
  select,
  defaultValue,
  label,
  placeholder,
  required,
}) {
  const { onChange, onBlur, name, ref } = register();

  const textFieldProps = {
    sx: { width },
    key: textFieldKey,
    ref,
    size: 'small',
    type: inputType,
    error,
    onChange,
    onBlur,
    name,
    helperText,
    select: select.select,
    defaultValue,
    label,
    placeholder,
    required,
  };

  if (!select.select) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TextField {...textFieldProps} />;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...textFieldProps}>
      {select.options.map(({ value: optionValue, label: optionLabel }) => (
        <MenuItem key={`${optionValue}`} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
    </TextField>
  );
}

ArrayFormTextField.propTypes = {
  textFieldKey: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  register: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputType: PropTypes.string.isRequired,
  select: PropTypes.shape({
    select: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
  control: PropTypes.shape({}),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
ArrayFormTextField.defaultProps = {
  width: undefined,
  error: false,
  helperText: '',
  select: { select: false },
  control: {},
  defaultValue: undefined,
  label: undefined,
  placeholder: undefined,
  required: false,
};
export default ArrayFormTextField;
