import { useMemo, useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

function ProductsDataErrors({ errors }) {
  const [showDetails, setShowDetails] = useState(false);
  const errorMessages = useMemo(
    () =>
      errors.reduce(
        (acc, current) => ({
          ...acc,
          [current.product.sku ||
          `Producto excel ${current.product.assignedSku + 1}`]: [
            ...(acc[current.product.sku] || []),
            current,
          ],
        }),
        {},
      ),
    [errors],
  );
  return (
    <Alert
      severity="error"
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => setShowDetails((prev) => !prev)}
        >
          {showDetails
            ? 'OCULTAR PRODUCTOS CON ERROR'
            : 'VER PRODUCTOS CON ERROR'}
        </Button>
      }
    >
      <AlertTitle>
        Los siguientes productos cargados presentan errores:
      </AlertTitle>
      Revisa el excel de ejemplo para corregirlas
      {showDetails && (
        <List>
          {Object.keys(errorMessages).map((sku) => (
            <ListItem key={sku} sx={{ paddingX: 0 }}>
              <ListItemText secondary={sku} />
            </ListItem>
          ))}
        </List>
      )}
    </Alert>
  );
}
ProductsDataErrors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({ sku: PropTypes.string }).isRequired,
      message: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ),
};
ProductsDataErrors.defaultProps = {
  errors: [],
};
export default ProductsDataErrors;
