import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import styles from './styles';

function TableView({ columnsNames, columns, data, tableName, itemKey }) {
  return (
    <>
      <View style={{ alignItems: 'left', paddingTop: 4 }}>
        <Text
          key="Items"
          style={{
            fontSize: 12,
            fontWeight: 400,
            margin: 2,
          }}
        >
          {tableName}
        </Text>
      </View>
      <View style={styles.rowView}>
        {columnsNames.map((c) => (
          <Text
            key={c}
            style={{
              fontSize: 9,
              width: `${100 / columnsNames.length}%`,
              fontWeight: 100,
            }}
          >
            {c}
          </Text>
        ))}
      </View>
      {data.map((rowData) => (
        <View key={rowData[itemKey]} style={styles.rowView}>
          {columns.map((c) => (
            <Text
              key={rowData[itemKey]}
              style={{
                fontSize: 8,
                width: `${100 / columns.length}%`,
              }}
            >
              {rowData[c]}
            </Text>
          ))}
        </View>
      ))}
    </>
  );
}
TableView.propTypes = {
  columnsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableName: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
};

export default TableView;
