import { useMemo } from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import FormTextField from 'components/text-fields/FormTextField';
import {
  REQUIRED_FIELD,
  POSITIVE_NUMBER,
  MAX_NUMBER,
  NATURAL_NUMBER_FIELD,
} from 'lib/form';
import CustomDialog from 'views/commons/CustomDialog';

import useGenerateOrderLabel from '../hooks/useGenerateOrderLabel';

const DIALOG_CONTENT_TEXT =
  'Se generarán etiquetas para cada bulto del pedido. De esta manera se podrán registrar las salidas de los bultos del hub.';
const DIALOG_CONTENT_TEXT_SUCCESS_OPERATION =
  'Se han generado correctamente las etiquetas de cada bulto.';
const DIALOG_CONTENT_TEXT_ERROR_OPERATION =
  'No hemos podido cargar la etiqueta en el tiempo esperado. Si no se generó correctamente, intenta de nuevo.';

const PACKAGES_LIMIT_NUMBER = 10;

function PackagesDialogForm({ sourceOrderId, orderId, open, setOpen }) {
  const dialogTitle = useMemo(
    () => ({ title: `Generar etiqueta propia de: ${sourceOrderId}` }),
    [sourceOrderId],
  );
  const {
    orderLabels,
    loading,
    error,
    onSubmit,
    resetGenerateOrderLabelsOperation,
    generateOrderLabelsData,
  } = useGenerateOrderLabel({
    orderId,
  });

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: { quantity: '' },
  });
  const dialogContent = useMemo(() => {
    if (error) {
      return DIALOG_CONTENT_TEXT_ERROR_OPERATION;
    }
    if (orderLabels && !loading) {
      return DIALOG_CONTENT_TEXT_SUCCESS_OPERATION;
    }
    return DIALOG_CONTENT_TEXT;
  }, [orderLabels, error, loading]);

  const resetDialog = () => {
    resetForm();
    resetGenerateOrderLabelsOperation();
  };

  const handleCloseDialog = () => {
    setOpen(false);
    resetDialog();
  };

  const isOperationExecuted = Boolean(
    (orderLabels && generateOrderLabelsData) || error,
  );

  return (
    <CustomDialog
      dialogTitle={dialogTitle}
      dialogContentText={dialogContent}
      handleCloseDialog={handleCloseDialog}
      dialogContent={
        <DialogContent
          register={register}
          control={control}
          showForm={!isOperationExecuted}
          loading={loading}
          packagesLabelURL={orderLabels}
        />
      }
      dialogActions={
        <DialogActions
          handleSubmit={handleSubmit(onSubmit)}
          handleCloseDialog={handleCloseDialog}
          loading={loading}
          isOperationExecuted={isOperationExecuted}
        />
      }
      openDialog={open}
    />
  );
}
PackagesDialogForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  sourceOrderId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

function DialogContent({
  register,
  control,
  showForm,
  loading,
  packagesLabelURL,
}) {
  if (showForm) {
    return (
      <FormTextField
        fieldName="quantity"
        label="Ingresa la cantidad de bultos del pedido"
        placeholder="Ingresa la cantidad de bultos del pedido"
        sx={{ width: '100%' }}
        control={control}
        register={() =>
          register('quantity', {
            required: REQUIRED_FIELD,
            min: {
              value: 1,
              message: POSITIVE_NUMBER,
            },
            max: {
              value: PACKAGES_LIMIT_NUMBER,
              message: `${MAX_NUMBER}: ${PACKAGES_LIMIT_NUMBER}`,
            },
            validate: {
              validateNumber: (value) =>
                Math.floor(Number(value)) === Number(value) ||
                NATURAL_NUMBER_FIELD,
            },
          })
        }
        disabled={loading}
        variant="standard"
        type="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
      />
    );
  }
  return (
    <Box sx={{ mt: 2 }}>
      {packagesLabelURL && (
        <Link
          href={packagesLabelURL}
          color="#0099EC"
          target="_blank"
          sx={{
            marginBottom: '16px',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          Ver las etiquetas de los bultos
          <OpenInNewIcon sx={{ marginLeft: 0.5 }} />
        </Link>
      )}
    </Box>
  );
}
DialogContent.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  showForm: PropTypes.bool,
  loading: PropTypes.bool,
  packagesLabelURL: PropTypes.string,
};
DialogContent.defaultProps = {
  showForm: true,
  loading: false,
  packagesLabelURL: undefined,
};

function DialogActions({
  handleSubmit,
  handleCloseDialog,
  loading,
  isOperationExecuted,
}) {
  return (
    <>
      {!isOperationExecuted && (
        <Button size="small" onClick={handleCloseDialog}>
          Cancelar
        </Button>
      )}
      {isOperationExecuted ? (
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleCloseDialog}
        >
          OK
        </Button>
      ) : (
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleSubmit}
          disabled={loading}
          endIcon={loading && <CircularProgress size={15} />}
        >
          Generar etiqueta
        </Button>
      )}
    </>
  );
}
DialogActions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isOperationExecuted: PropTypes.bool,
};
DialogActions.defaultProps = {
  loading: false,
  isOperationExecuted: false,
};

export default PackagesDialogForm;
