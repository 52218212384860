import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from 'components/table/Table';
import GET_ORDERS_INTEGRATIONS from 'gql/orders-integrations';

function OrdersIntegrations({ sellerId }) {
  const [ordersIntegrations, setOrdersIntegrations] = useState(false);
  const [getData, { data, error }] = useLazyQuery(GET_ORDERS_INTEGRATIONS);

  useEffect(() => {
    getData({
      variables: { filters: { sellerId } },
    });
  }, []);

  useEffect(() => {
    if (data) {
      const {
        getOrdersIntegrations: { nodes },
      } = data;
      setOrdersIntegrations(nodes);
    }
  }, [data]);
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Plataforma</Typography>,
        accessor: 'systemName',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Estado</Typography>,
        accessor: 'enabled',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {value ? (
              <DoneOutlinedIcon color="success" />
            ) : (
              <CloseOutlinedIcon color="error" />
            )}
          </Box>
        ),
      },
    ],
    [],
  );

  if (error) {
    return (
      <Typography>
        Ha ocurrido un error al buscar las plataformas integradas. Inténtalo
        nuevamente más tarde.
      </Typography>
    );
  }
  if (!ordersIntegrations) {
    return <CircularProgress color="secondary" />;
  }
  if (ordersIntegrations.length === 0) {
    return (
      <Typography>
        No existen integraciones con plataformas para este vendedor
      </Typography>
    );
  }
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Plataformas integradas
      </Typography>
      <Table
        data={ordersIntegrations}
        columns={columns}
        pagination={false}
        sxPaper={{ boxShadow: 'none' }}
        hasSearchBox={false}
      />
    </Box>
  );
}

OrdersIntegrations.propTypes = {
  sellerId: PropTypes.string.isRequired,
};

export default OrdersIntegrations;
