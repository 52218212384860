import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

function EditableCell({
  initialValue,
  rowIndex,
  editableRowIndex,
  renderCell,
  renderCellEditing,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return rowIndex === editableRowIndex
    ? renderCellEditing()
    : renderCell(value);
}

EditableCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  rowIndex: PropTypes.number.isRequired,
  editableRowIndex: PropTypes.number,
  renderCell: PropTypes.func.isRequired,
  renderCellEditing: PropTypes.func.isRequired,
};
EditableCell.defaultProps = {
  initialValue: undefined,
  editableRowIndex: -1,
};

export default EditableCell;
