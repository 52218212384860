import { ORDER_STATUSES } from './orderStatuses';

const STATUS_ORDER_MAPPING = {
  created: ORDER_STATUSES.pending,
  pending: ORDER_STATUSES.pending,
  pendingApproval: ORDER_STATUSES.pending,
  processing: ORDER_STATUSES.processing,
  readyAtStore: ORDER_STATUSES.readyAtStore,
  invoiced: ORDER_STATUSES.readyAtStore,
  releasedToCarrier: ORDER_STATUSES.releasedToCarrier,
  outForDelivery: ORDER_STATUSES.releasedToCarrier,
  deliveredConfirmed: ORDER_STATUSES.readyAtStore,
  delivered: ORDER_STATUSES.delivered,
  canceled: null,
  error: ORDER_STATUSES.pending,
};

const ORDER_STATUS_STEPPER = [
  ORDER_STATUSES.pending,
  ORDER_STATUSES.processing,
  ORDER_STATUSES.readyAtStore,
  ORDER_STATUSES.releasedToCarrier,
  ORDER_STATUSES.delivered,
];

const STATUS_ORDER_MAPPING_LABEL = {
  [ORDER_STATUSES.pending]: 'Pendiente',
  [ORDER_STATUSES.processing]: 'Procesando',
  [ORDER_STATUSES.readyAtStore]: 'Listo en tienda',
  [ORDER_STATUSES.releasedToCarrier]: 'En camino',
  [ORDER_STATUSES.delivered]: 'Entregado',
};

export {
  STATUS_ORDER_MAPPING,
  ORDER_STATUS_STEPPER,
  STATUS_ORDER_MAPPING_LABEL,
};
