import { from, HttpLink, ApolloClient, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';

import loginRedirect, {
  getTokenWithRefreshToken,
  AUTHENTICATION_ERROR_MESSAGE,
  AUTHORIZATION_ERROR_MESSAGE,
} from '../auth/utils';
import cache from './cache';

const { REACT_APP_PHOENIX_API_URL, REACT_APP_VOLANS_API_URL } = process.env;

const httpLink = new HttpLink({ uri: REACT_APP_PHOENIX_API_URL });
const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem('access_token');
  const tokenType = localStorage.getItem('token_type');

  return {
    headers: {
      ...headers,
      authorization: `${tokenType} ${accessToken}`,
    },
  };
});

const errorLink = onError((obj) => {
  const { graphQLErrors, operation, forward } = obj;
  const tokenType = localStorage.getItem('token_type');
  if (graphQLErrors) {
    const { message } = graphQLErrors[0];

    if (message.startsWith(AUTHENTICATION_ERROR_MESSAGE)) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        return loginRedirect();
      }
      return new Observable((observer) => {
        getTokenWithRefreshToken({ refreshToken })
          .then(({ data: refreshResponseData }) => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `${tokenType} ${refreshResponseData.access_token}`,
              },
            }));

            Object.keys(refreshResponseData).map((key) =>
              localStorage.setItem(key, refreshResponseData[key]),
            );
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            // No refresh or client token available, we force user to login
            loginRedirect();
            observer.error(error);
          });
      });
    }
    if (message.startsWith(AUTHORIZATION_ERROR_MESSAGE)) {
      window.location.replace('/auth-error');
    }
  }
  return undefined;
});

const apolloLink = from([errorLink, authLink, httpLink]);
const client = new ApolloClient({
  link: apolloLink,
  cache,
});

const httpRestLink = new RestLink({
  uri: REACT_APP_VOLANS_API_URL,
});

const restClient = new ApolloClient({
  link: httpRestLink,
  cache,
});

export { restClient };
export default client;
