import { useEffect, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { GET_PRODUCT_AT_WAREHOUSE_BY_IDS } from '../../../../../gql/catalogs';
import {
  REQUIRED_FIELD,
  POSITIVE_NUMBER,
  QUANTITY_AVAILABLE_EXCEEDED,
} from '../../../../../lib/form';

const UNDEFINED_QUANTITY_AVAILABLE = 0;

function ItemQuantityCell({
  productId,
  watchWarehouseId,
  watchSellerId,
  fields,
  prevFields,
  errors,
  register,
  quantityAvailable,
}) {
  const [
    getProductAtWarehouse,
    { loading: loadingInventory, error: errorInventory, data: dataInventory },
  ] = useLazyQuery(GET_PRODUCT_AT_WAREHOUSE_BY_IDS);
  const currentItemIndex = useMemo(
    () => fields.findIndex((item) => item.value === productId),
    [fields],
  );
  const currentQuantityAvailable = useMemo(
    () =>
      quantityAvailable ||
      (dataInventory?.getProductAtWarehouseByIds?.quantityAvailable ??
        UNDEFINED_QUANTITY_AVAILABLE),
    [dataInventory, quantityAvailable],
  );
  useEffect(() => {
    const lastItem =
      currentItemIndex + 1 === fields.length &&
      fields.length > prevFields.length;
    if (!quantityAvailable && quantityAvailable !== 0) {
      getProductAtWarehouse({
        variables: {
          sellerId: watchSellerId,
          warehouseId: watchWarehouseId,
          productId,
        },
        fetchPolicy: lastItem ? 'cache-and-network' : 'cache-first',
      });
    }
  }, []);
  if (loadingInventory) return <CircularProgress size={25} color="secondary" />;
  if (errorInventory)
    return (
      <Typography color="error" variant="caption">
        Error para validar inventario
      </Typography>
    );
  return (
    <Box
      key={`quantity-item${productId}-box`}
      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
    >
      <TextField
        key={`items[${fields.findIndex(
          (item) => item.value === productId,
        )}].quantity`}
        sx={{ width: 80 }}
        size="small"
        type="number"
        error={Boolean(
          errors.items && errors.items[currentItemIndex]?.quantity,
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(`items[${currentItemIndex}].quantity`, {
          required: REQUIRED_FIELD,
          min: {
            value: 1,
            message: POSITIVE_NUMBER,
          },
          max: {
            value: currentQuantityAvailable,
            message: QUANTITY_AVAILABLE_EXCEEDED,
          },
        })}
        helperText={
          errors.items && errors.items[currentItemIndex]?.quantity?.message
        }
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="caption">
          {currentQuantityAvailable} unidades disponibles
        </Typography>
        <Typography variant="caption">
          {dataInventory?.getProductAtWarehouseByIds?.lastUpdate}
        </Typography>
      </Box>
    </Box>
  );
}
ItemQuantityCell.propTypes = {
  productId: PropTypes.string.isRequired,
  quantityAvailable: PropTypes.number,
  watchWarehouseId: PropTypes.string.isRequired,
  watchSellerId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prevFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errors: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.shape({ message: PropTypes.string }),
      }),
    ),
  }),
  register: PropTypes.func.isRequired,
};
ItemQuantityCell.defaultProps = {
  errors: null,
  quantityAvailable: null,
};
export default ItemQuantityCell;
