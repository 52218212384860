import axios from 'axios';

const {
  REACT_APP_HERCULES_AUTHORIZE_URL,
  REACT_APP_HERCULES_TOKEN_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_SCOPES,
  REACT_APP_CODE_CHALLENGE_METHOD,
  REACT_APP_HERCULES_END_SESSION,
} = process.env;

const AUTHENTICATION_ERROR_MESSAGE = 'Unauthenticated';
const AUTHORIZATION_ERROR_MESSAGE = 'Not authorized';

function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

function base64urlencode(a) {
  let str = '';
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

async function generateCodeChallengeFromVerifier(v) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

function dec2hex(dec) {
  return `0${dec.toString(16)}`.substr(-2);
}

function generateRandomStringVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

function getCodeChallenge() {
  const codeVerifier = generateRandomStringVerifier();
  const codeChallenge = generateCodeChallengeFromVerifier(codeVerifier);
  return [codeChallenge, codeVerifier];
}

async function loginRedirect() {
  const [codeChallenge, codeVerifier] = await Promise.all(getCodeChallenge());
  localStorage.setItem('code_verifier', codeVerifier);
  const authUri = axios.getUri({
    url: REACT_APP_HERCULES_AUTHORIZE_URL,
    params: {
      client_id: REACT_APP_CLIENT_ID,
      redirect_uri: REACT_APP_REDIRECT_URI,
      response_type: 'code',
      scope: REACT_APP_SCOPES,
      code_challenge_method: REACT_APP_CODE_CHALLENGE_METHOD,
      code_challenge: codeChallenge,
    },
  });
  window.location.replace(authUri);
}
function getBodyParams(body) {
  const bodyParams = Object.keys(body)
    .map((key) => `${key}=${body[key]}`)
    .join('&');
  return bodyParams;
}
async function getToken(code) {
  const codeVerifier = localStorage.getItem('code_verifier');
  const authParams = {
    grant_type: 'authorization_code',
    client_id: REACT_APP_CLIENT_ID,
    redirect_uri: REACT_APP_REDIRECT_URI,
    code,
    code_verifier: codeVerifier,
  };
  return axios.post(REACT_APP_HERCULES_TOKEN_URL, getBodyParams(authParams));
}
async function getTokenWithRefreshToken({ refreshToken }) {
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('grant_type', 'refresh_token');
  bodyFormData.append('client_id', REACT_APP_CLIENT_ID);
  bodyFormData.append('refresh_token', refreshToken);
  return axios.post(REACT_APP_HERCULES_TOKEN_URL, bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

function logout() {
  const keys = [
    'expires_in',
    'scope',
    'code_verifier',
    'access_token',
    'token_type',
    'refresh_token',
  ];
  keys.map((key) => localStorage.removeItem(key));
  window.location.replace(REACT_APP_HERCULES_END_SESSION);
}
export default loginRedirect;
export {
  getToken,
  getTokenWithRefreshToken,
  logout,
  AUTHENTICATION_ERROR_MESSAGE,
  AUTHORIZATION_ERROR_MESSAGE,
};
