import { useState, useMemo } from 'react';

import { gql } from '@apollo/client';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import client from 'apollo/client';
import SimpleMenu from 'components/menus/SimpleMenu';
import { ORDER_STATUS_LABEL_MAPPING, ORDER_STATUSES } from 'lib/orderStatuses';

import PackagesDialogForm from './PackagesDialogForm';

const { REACT_APP_VELA_URL } = process.env;
const GENERATE_LABELS_ORDER_STATUSES = [
  ORDER_STATUSES.pending,
  ORDER_STATUSES.processing,
];

function OrderFilesMenu({
  id,
  sourceOrderId,
  shippingLabelURL,
  invoiceURL,
  packagesLabelURL,
  hasPickingList,
}) {
  const [andromedaAnchorEl, setAndromedaAnchorEl] = useState(null);
  const [packagesDialogOpen, setPackagesDialogOpen] = useState(false);
  // if the user generates a new label, the label will be in the cache
  const order = useMemo(() =>
    client.readFragment({
      id: `Order:${id}`,
      fragment: gql`
        fragment OrderObject on Order {
          id
          packages {
            labelURL
          }
          status
        }
      `,
    }),
  );
  const categories = useMemo(
    () => ({
      files: { label: 'Archivos', icon: <RemoveRedEyeOutlinedIcon /> },
      actions: { label: 'Acciones', icon: <ModeOutlinedIcon /> },
    }),
    [],
  );
  const canGenerateOrderLabel = useMemo(
    () => GENERATE_LABELS_ORDER_STATUSES.includes(order?.status.value),
    [order],
  );

  const andromedaFilesOptions = useMemo(
    () => [
      {
        id: 'order',
        label: 'Ver orden',
        href: `/orders/${id}`,
        component: 'a',
        icon: <ListAltOutlinedIcon />,
        category: 'files',
      },
      {
        id: 'packagesLabelURL',
        label: 'Ver etiqueta propia',
        href: order?.packages.labelURL || packagesLabelURL,
        component: 'a',
        disabled: !(order?.packages.labelURL || packagesLabelURL),
        disabledMessage: 'La etiqueta no ha sido generada',
        icon: <DocumentScannerOutlinedIcon />,
        category: 'files',
      },
      {
        id: 'generatePackageLabelURL',
        label: 'Generar etiqueta propia',
        component: 'button',
        onClick: () => setPackagesDialogOpen(true),
        disabled: !canGenerateOrderLabel,
        disabledMessage: `No se puede generar la etiqueta en el estado ${
          ORDER_STATUS_LABEL_MAPPING[order?.status.value]
        }`,
        icon: <DocumentScannerOutlinedIcon />,
        category: 'actions',
      },
      {
        id: 'shippingLabelURL',
        label: 'Ver etiqueta de envío',
        href: shippingLabelURL,
        component: 'a',
        disabled: !shippingLabelURL,
        disabledMessage: 'Sin etiqueta de envío',
        icon: <LocalShippingIcon />,
        category: 'files',
      },
      {
        id: 'trackingUrl',
        label: 'Ver URL de seguimiento',
        href: `${REACT_APP_VELA_URL}/${id}`,
        component: 'a',
        icon: <DeliveryDiningOutlinedIcon />,
        category: 'files',
      },
      {
        id: 'invoice',
        label: 'Ver boleta',
        href: invoiceURL,
        component: 'a',
        disabled: !invoiceURL,
        disabledMessage: 'Sin boleta',
        icon: <ReceiptIcon />,
        category: 'files',
      },
      {
        id: 'pickingList',
        label: 'Ver comanda',
        href:
          // eslint-disable-next-line react/prop-types
          hasPickingList
            ? // eslint-disable-next-line react/prop-types
              `/orders/${id}/picking-list-label`
            : undefined,
        component: 'a',
        disabled: !hasPickingList,
        disabledMessage: 'Sin comanda',
        icon: <ReceiptLongOutlinedIcon />,
        category: 'files',
      },
    ],
    [
      id,
      shippingLabelURL,
      invoiceURL,
      hasPickingList,
      order,
      canGenerateOrderLabel,
    ],
  );

  const renderButtonAndromedaFile = (props) => (
    <Tooltip title="Acciones">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <IconButton {...props}>
        <MoreVertOutlinedIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <SimpleMenu
        anchorEl={andromedaAnchorEl}
        setAnchorEl={setAndromedaAnchorEl}
        options={andromedaFilesOptions}
        renderButton={renderButtonAndromedaFile}
        categories={categories}
      />
      <PackagesDialogForm
        orderId={id}
        sourceOrderId={sourceOrderId}
        packagesLabelURL={packagesLabelURL}
        open={packagesDialogOpen}
        setOpen={setPackagesDialogOpen}
      />
    </Box>
  );
}

OrderFilesMenu.propTypes = {
  id: PropTypes.string.isRequired,
  sourceOrderId: PropTypes.string.isRequired,
  shippingLabelURL: PropTypes.string,
  invoiceURL: PropTypes.string,
  packagesLabelURL: PropTypes.string,
  hasPickingList: PropTypes.bool,
  hasAttachedFiles: PropTypes.bool,
};
OrderFilesMenu.defaultProps = {
  shippingLabelURL: undefined,
  packagesLabelURL: undefined,
  invoiceURL: undefined,
  hasPickingList: false,
  hasAttachedFiles: false,
};
export default OrderFilesMenu;
