import { red, green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const NUMBER_FORMAT_ZONE = 'es-CL';
const GREEN = green[400];
const RED = red[400];

function QuantityDelta({ quantity }) {
  const formattedQuantity = new Intl.NumberFormat(NUMBER_FORMAT_ZONE, {
    signDisplay: 'exceptZero',
  }).format(quantity);
  return (
    <Typography color={quantity > 0 ? GREEN : RED} variant="subtitle1">
      {formattedQuantity}
    </Typography>
  );
}
QuantityDelta.propTypes = {
  quantity: PropTypes.number.isRequired,
};
export default QuantityDelta;
