import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Table from 'components/table/Table';

const PAGE_SIZE_OPTIONS = [10, 20, 30, 40];

function ListServerData({
  query,
  queryVariables,
  handleGlobalFilter,
  initGlobalFilter,
  dataKey,
  columns,
  renderSubComponent,
  rowEdition,
  report,
  filters,
  disableSortBy,
  sxCell,
}) {
  // Server Table with pagination and prefetch
  const [listData, setListData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [getData, { data, loading, error, client, networkStatus }] =
    useLazyQuery(query, {
      notifyOnNetworkStatusChange: true,
    });
  const prefetch = ({ variables }) => {
    client.query({ query, variables });
  };
  useEffect(() => {
    // paginator pageSize by default is the first item of pageSizeOptions
    getData({
      variables: { offset: 0, limit: PAGE_SIZE_OPTIONS[0], ...queryVariables },
    });
  }, []);

  useEffect(() => {
    if (data) {
      const {
        [dataKey]: { nodes, pageCount: pageCountData },
      } = data;
      setListData(nodes);
      setPageCount(pageCountData);
    }
  }, [data]);

  return (
    <Table
      data={listData}
      columns={columns}
      renderSubComponent={renderSubComponent}
      rowEdition={rowEdition}
      report={report}
      pagination={{
        hasPaginator: true,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        controlledPageCount: pageCount,
        queryVariables,
        fetchMore: getData,
        prefetch,
        networkStatus,
      }}
      handleGlobalFilter={handleGlobalFilter}
      initGlobalFilter={initGlobalFilter}
      error={error}
      loading={loading}
      filters={filters}
      disableSortBy={disableSortBy}
      sxCell={sxCell}
    />
  );
}

ListServerData.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  queryVariables: PropTypes.object,
  handleGlobalFilter: PropTypes.func,
  initGlobalFilter: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderSubComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  rowEdition: PropTypes.shape({
    canEdit: PropTypes.bool,
    editFormProperties: PropTypes.oneOfType([
      PropTypes.shape({
        formValues: PropTypes.arrayOf(PropTypes.string),
        updateVariables: PropTypes.func,
        formHooks: PropTypes.shape({
          handleSubmit: PropTypes.func,
          formReset: PropTypes.func,
          getValues: PropTypes.func,
          // eslint-disable-next-line react/forbid-prop-types
          errors: PropTypes.object,
        }),
        mutationFunction: PropTypes.func,
        loading: PropTypes.bool,
        editableRowIndex: PropTypes.number,
        setEditableRowIndex: PropTypes.func,
      }),
      PropTypes.bool,
    ]),
  }),
  report: PropTypes.shape({
    hasReport: PropTypes.bool,
    reportProperties: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      mutation: PropTypes.object,
      type: PropTypes.string,
      dialogFunctions: PropTypes.shape({
        open: PropTypes.bool,
        setOpen: PropTypes.func,
        handleClickOpen: PropTypes.func,
        handleClose: PropTypes.func,
      }),
      filterOptions: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        warehouses: PropTypes.arrayOf(PropTypes.object),
        // eslint-disable-next-line react/forbid-prop-types
        sellers: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
  filters: PropTypes.shape({
    components: PropTypes.node,
    helperTextSearchBox: PropTypes.string,
  }),
  disableSortBy: PropTypes.bool,
  sxCell: PropTypes.shape({}),
};

ListServerData.defaultProps = {
  renderSubComponent: false,
  rowEdition: { canEdit: false },
  report: { hasReport: false },
  queryVariables: {},
  handleGlobalFilter: () => {},
  initGlobalFilter: '',
  filters: {
    components: <div />,
  },
  disableSortBy: false,
  sxCell: { padding: '10px' },
};

export default ListServerData;
