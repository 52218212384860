import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function SubmitButton({ onClick, label, loading, disabled }) {
  return (
    <Button
      size="large"
      variant="contained"
      color="secondary"
      onClick={onClick}
      sx={(theme) => ({
        backgroundColor: theme.palette.submitButtonBackgroundColor.main,
        borderRadius: 1,
        alignSelf: 'end',
        '&:hover': {
          backgroundColor: theme.palette.submitButtonBackgroundColor.hover,
        },
      })}
      endIcon={loading && <CircularProgress size={15} />}
      disabled={typeof disabled === 'boolean' ? disabled : loading}
    >
      <Typography>{label}</Typography>
    </Button>
  );
}
SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
SubmitButton.defaultProps = {
  loading: false,
  disabled: undefined,
};
export default SubmitButton;
