import { useEffect, useRef, useState } from 'react';

import { NetworkStatus } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import TableMui from '@mui/material/Table';
import TableContainerMui from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
  useRowSelect,
  useExpanded,
  useTable,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from 'react-table';

import emptyDataImage from 'assets/images/server-error.svg';
import storeLoader from 'assets/images/store-loader.gif';
import sortParser from 'helpers/sortParser';
import ExportDataForm from 'views/commons/ExportDataForm';

import PaperBox from '../containers/PaperBox';
import TableError from '../Error';
import { addCheckbox, addEdit, addExpander } from './helper';
import Paginator from './Paginator';
import TableBody from './TableBody';
import TableBox from './TableBox';
import TableFilters from './TableFilters';
import TableHeader from './TableHeader';

const FIRST_PAGE = 0;
function Table({
  data,
  columns,
  pagination: {
    hasPaginator,
    customPageSize,
    fetchMore, // if pagination is on the frontend side, fetchMore is false
    prefetch,
    queryVariables,
    pageSizeOptions,
    controlledPageCount,
    networkStatus,
  },
  filters,
  handleGlobalFilter, // TODO: delete and use currentTableStateVar
  initGlobalFilter,
  hasCheckbox,
  hasSearchBox,
  hasStickyHeader,
  renderSubComponent,
  rowEdition,
  report,
  loading,
  error,
  // styles
  sxPaper,
  sxCell,
  disableSortBy,
  paperBoxProps,
  getCellProps,
}) {
  const tableContainerRef = useRef();
  const firstUpdate = useRef(true);
  const [showEmptyData, setShowEmptyData] = useState(false);
  const [inputVariables, setInputVariables] = useState(queryVariables);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    page, // Instead of using 'rows', we'll use page (if pagination is active)
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      ...(((hasPaginator && pageSizeOptions[0] > 0) ||
        customPageSize > 0 ||
        data.length > 0) && {
        initialState: {
          pageSize: hasPaginator
            ? pageSizeOptions[0]
            : customPageSize || data.length,
        },
      }),
      manualGlobalFilter: !!fetchMore,
      manualPagination: !!fetchMore,
      ...(fetchMore && { pageCount: controlledPageCount }), // if table has server side pagination
      manualSortBy: !!fetchMore,
      disableSortBy,
      editFormProperties: rowEdition.editFormProperties,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (hasCheckbox) {
        hooks.visibleColumns.push(addCheckbox);
      }
      if (renderSubComponent) {
        hooks.visibleColumns.push(addExpander);
      }
      if (rowEdition.canEdit) {
        hooks.visibleColumns.push(addEdit);
      }
    },
  );

  const fetchPage = async () => {
    await fetchMore({
      variables: {
        offset: pageSize * pageIndex,
        limit: pageSize,
        ...inputVariables,
      },
    });
  };

  const prefetchPage = () => {
    prefetch({
      variables: {
        offset: pageSize * (pageIndex + 1),
        limit: pageSize,
        ...inputVariables,
      },
    });
  };

  const onChangeSearchInput = ({ target: { value } }) => {
    setGlobalFilter(value);
    handleGlobalFilter(value);
  };
  const onFetchDataDebounced = useAsyncDebounce(fetchPage, 100);
  const onPrefetchDataDebounced = useAsyncDebounce(prefetchPage, 100);
  const onSetShowEmptyData = useAsyncDebounce(
    () => setShowEmptyData(!data.length && !loading && !error),
    500,
  );

  useEffect(() => {
    const { 0: order } = sortBy || [null];
    setInputVariables((prev) => ({
      ...prev,
      ...queryVariables,
      ...(!disableSortBy && { sort: sortParser(order) }),
    }));
  }, [sortBy, queryVariables]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (fetchMore) {
      onFetchDataDebounced();
    }
    if (prefetch) {
      onPrefetchDataDebounced();
    }
  }, [pageIndex, pageSize, inputVariables]);

  useEffect(() => {
    if (hasPaginator) {
      gotoPage(FIRST_PAGE);
    }
  }, [pageSize, inputVariables]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.refetch) {
      gotoPage(FIRST_PAGE);
    }
  }, [networkStatus]);

  useEffect(() => {
    onSetShowEmptyData();
    if (rowEdition.canEdit) {
      rowEdition.editFormProperties.setEditableRowIndex(-1);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (customPageSize) {
      setPageSize(customPageSize);
    }
  }, [customPageSize]);

  return (
    <>
      {hasSearchBox && (
        <TableFilters
          value={globalFilter || initGlobalFilter}
          onChange={onChangeSearchInput}
          filters={filters.components}
          helperText={filters.helperTextSearchBox}
          handleClickOpenExportDataForm={
            report.hasReport
              ? report.reportProperties.dialogFunctions.handleClickOpen
              : false
          }
        />
      )}
      <PaperBox
        elevation={2}
        sx={{
          width: '100%',
          overflow: 'hidden',
          ...sxPaper,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...paperBoxProps}
      >
        {loading && <LinearProgress color="secondary" />}
        <TableContainerMui
          ref={tableContainerRef}
          sx={{ ...(hasStickyHeader && { maxHeight: 'calc(100vh - 350px)' }) }}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <TableMui stickyHeader {...getTableProps()}>
            <TableHeader headerGroups={headerGroups} sxCell={sxCell} />
            {data && !error && (
              <TableBody
                getTableBodyProps={getTableBodyProps}
                getCellProps={getCellProps}
                rows={page || rows}
                prepareRow={prepareRow}
                hasHover={hasCheckbox}
                visibleColumns={visibleColumns}
                renderSubComponent={renderSubComponent}
                sxCell={sxCell}
              />
            )}
          </TableMui>
        </TableContainerMui>
        {loading && !data.length && (
          <TableBox>
            <img src={storeLoader} alt="store-loader" width="110px" />
            <Typography>Cargando información</Typography>
          </TableBox>
        )}
        {error && (
          <TableBox>
            <TableError />
          </TableBox>
        )}
        {showEmptyData && (
          <TableBox>
            <TableError
              message="Parece que no encontramos datos"
              img={emptyDataImage}
            />
          </TableBox>
        )}
        {hasPaginator && (
          <Paginator
            tableContainerRef={tableContainerRef}
            pageIndex={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
          />
        )}
      </PaperBox>
      {report.hasReport && (
        <ExportDataForm
          reportProperties={report.reportProperties}
          filterOptions={report.reportProperties.filterOptions}
          inputVariables={inputVariables}
          openExportDataForm={report.reportProperties.dialogFunctions.open}
          setOpenExportDataForm={
            report.reportProperties.dialogFunctions.setOpen
          }
          handleCloseExportDataForm={
            report.reportProperties.dialogFunctions.handleClose
          }
        />
      )}
    </>
  );
}

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasCheckbox: PropTypes.bool,
  hasSearchBox: PropTypes.bool,
  hasStickyHeader: PropTypes.bool,
  renderSubComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  rowEdition: PropTypes.shape({
    canEdit: PropTypes.bool,
    editFormProperties: PropTypes.oneOfType([
      PropTypes.shape({
        formValues: PropTypes.arrayOf(PropTypes.string),
        updateVariables: PropTypes.func,
        formHooks: PropTypes.shape({
          handleSubmit: PropTypes.func,
          formReset: PropTypes.func,
          getValues: PropTypes.func,
          // eslint-disable-next-line react/forbid-prop-types
          errors: PropTypes.object,
        }),
        mutationFunction: PropTypes.func,
        loading: PropTypes.bool,
        editableRowIndex: PropTypes.number,
        setEditableRowIndex: PropTypes.func,
      }),
      PropTypes.bool,
    ]),
  }),
  report: PropTypes.shape({
    hasReport: PropTypes.bool,
    reportProperties: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      mutation: PropTypes.object,
      type: PropTypes.string,
      dialogFunctions: PropTypes.shape({
        open: PropTypes.bool,
        setOpen: PropTypes.func,
        handleClickOpen: PropTypes.func,
        handleClose: PropTypes.func,
      }),
      filterOptions: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        warehouses: PropTypes.arrayOf(PropTypes.object),
        // eslint-disable-next-line react/forbid-prop-types
        sellers: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
  }),
  pagination: PropTypes.oneOfType([
    PropTypes.shape({
      hasPaginator: PropTypes.bool,
      customPageSize: PropTypes.number,
      fetchMore: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
      prefetch: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
      pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
      networkStatus: PropTypes.number,
    }),
    PropTypes.bool,
  ]),
  handleGlobalFilter: PropTypes.func,
  initGlobalFilter: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
  filters: PropTypes.shape({
    components: PropTypes.node,
    helperTextSearchBox: PropTypes.string,
  }),
  // styles
  // eslint-disable-next-line react/forbid-prop-types
  sxPaper: PropTypes.object,
  sxCell: PropTypes.shape({}),
  disableSortBy: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  paperBoxProps: PropTypes.object,
  getCellProps: PropTypes.func,
};

Table.defaultProps = {
  hasCheckbox: false,
  renderSubComponent: false,
  rowEdition: { canEdit: false },
  report: { hasReport: false },
  pagination: {
    hasPaginator: true,
    fetchMore: false, // if pagination is on the frontend side, fetchMore is false
    prefetch: false,
    pageSizeOptions: [5, 10, 20, 50],
    networkStatus: NetworkStatus.ready,
  },
  handleGlobalFilter: () => {},
  initGlobalFilter: '',
  hasSearchBox: true,
  hasStickyHeader: true,
  loading: false,
  error: false,
  sxPaper: {},
  sxCell: { padding: '10px' },
  filters: { components: <div />, helperTextSearchBox: 'Buscar' },
  disableSortBy: true,
  paperBoxProps: {},
  getCellProps: () => ({}),
};
export default Table;
