import { useEffect, useState } from 'react';

import { useMutation, gql } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import cache from 'apollo/cache';
import client from 'apollo/client';
import AutocompleteSelect from 'components/selects/AutoCompleteSelect';
import { GET_PRODUCTS } from 'gql/catalogs';

import SubmitButton from '../../../../components/buttons/SubmitButton';
import AlertDialog from '../../../../components/dialogs/AlertDialog';
import FileInput from '../../../../components/input-files/FileInput';
import FileTemplate from '../../../../components/input-files/FileTemplate';
import PageTitle from '../../../../components/layout/PageTitle';
import FormTextField from '../../../../components/text-fields/FormTextField';
import { CREATE_REPLENISHMENT } from '../../../../gql/catalogs';
import FILE_TYPES from '../../../../lib/fileTypes';
import { REQUIRED_FIELD } from '../../../../lib/form';
import NewReplenishmentSellerWarehouse from '../../../components/forms/SellerWarehouseInput';
import ReplenishmentTable from './components/ReplenishmentTable';
import useErrorMessage from './custom-hooks/useErrorMessage';
import readCSV from './lib/readCSV';

const { REACT_APP_FILE_REPLENISHMENT_TEMPLATE_PATH } = process.env;

function NewReplenishment() {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sellerId: '',
      warehouseId: '',
      comment: '',
      reference: '',
    },
  });

  const [selectedItem, setSelectedItem] = useState(null);

  const [filters, setFilters] = useState({
    sellerId: '',
    globalFilter: '',
    hasComponents: false,
  });

  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });

  const navigate = useNavigate();

  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'items',
    rules: { required: REQUIRED_FIELD },
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  useEffect(() => {
    setFilters((prevFilters) => ({ ...prevFilters, sellerId: watchSellerId }));
  }, [watchSellerId]);

  const [createReplenishment, { loading, error, reset }] = useMutation(
    CREATE_REPLENISHMENT,
    {
      onCompleted: (data) => {
        reset();
        setOpenDialog(true);
        setDialogContent(
          `Revisa que el abastecimiento con número: ${data?.createReplenishment?.reference} se haya creado correctamente. Puedes verlo en la vista de abastecimientos.`,
        );
      },
    },
  );

  const { mutationErrorMessage } = useErrorMessage({ error });

  const appendDataToForm = (tableData) => {
    tableData.forEach((row) => append(row));
  };

  const onCompleteReadCSV = (tableData) => {
    replace([]);
    appendDataToForm(tableData);
  };

  const onSubmit = (formData) => {
    const { sellerId, warehouseId, items, reference, comment } = formData;
    createReplenishment({
      variables: {
        input: { sellerId, warehouseId, items, reference, comment },
      },
    });
  };

  const addItem = () => {
    if (!fields.some(({ value }) => value === selectedItem)) {
      const cacheObject = cache.identify({
        __typename: 'Product',
        id: selectedItem,
      });
      const product = client.readFragment({
        id: cacheObject,
        fragment: gql`
          fragment ProductObject on Product {
            id
            sku
            name
            value
            label
            storageType
            measures {
              width {
                value
                unit
              }
              height {
                value
                unit
              }
              length {
                value
                unit
              }
              weight {
                value
                unit
              }
            }
          }
        `,
      });
      append({
        sku: product.sku,
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minWidth: 600,
        }}
      >
        <PageTitle>Crear abastecimiento</PageTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <NewReplenishmentSellerWarehouse
            title="1. Vendedor y tienda"
            register={register}
            resetField={resetField}
            control={control}
            setValue={setValue}
            keyFilterOptions="orderFilterOptions" // TODO: add replenishment filter options to getFilters
          />
          <Divider flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              2. Carga de datos de abastecimiento
            </Typography>

            <Typography>
              Carga el abastecimiento de productos de forma manual y/o mediante
              un archivo CSV:
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
              <AutocompleteSelect
                query={GET_PRODUCTS}
                label="Producto"
                filters={filters}
                setFilters={setFilters}
                setSelectedValue={setSelectedItem}
                size="small"
                disabled={!watchSellerId}
                validateFilters={(_filters) => Boolean(_filters.sellerId)}
                inputError={
                  errors?.items
                    ? { message: errors?.items?.root?.message }
                    : false
                }
              />
              <Button
                size="medium"
                variant="contained"
                sx={{
                  backgroundColor: '#01B0BB',
                  borderRadius: 1,
                  width: 250,
                }}
                startIcon={<AddOutlinedIcon />}
                disabled={!selectedItem}
                onClick={addItem}
              >
                Agregar producto
              </Button>
            </Box>
            <FileTemplate
              filePath={REACT_APP_FILE_REPLENISHMENT_TEMPLATE_PATH}
              title="CSV de ejemplo"
            />
            <FileInput
              fileTypes={[FILE_TYPES.CSV]}
              fileLabel="CSV"
              fileParser={(files) => readCSV(files, onCompleteReadCSV)}
              error={Boolean(errors && errors.items)}
            />
            {!!fields.length && (
              <ReplenishmentTable
                control={control}
                register={register}
                setValue={setValue}
                replenishmentData={fields}
                remove={remove}
              />
            )}
            <Divider flexItem />
            <Typography variant="subtitle1">3. ID de referencia</Typography>
            <FormTextField
              fieldName="reference"
              label="ID de referencia"
              sx={{ width: '100%' }}
              control={control}
              register={() =>
                register('reference', { required: REQUIRED_FIELD })
              }
            />
          </Box>
          <Divider flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              4. Comentario (opcional)
            </Typography>
            <FormTextField
              fieldName="comment"
              control={control}
              label="Comentario"
              sx={{ width: '100%' }}
              register={() => register('comment')}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {mutationErrorMessage && (
              <Typography color="error" gutterBottom align="center" paragraph>
                {mutationErrorMessage}
              </Typography>
            )}
            <SubmitButton
              label="INGRESAR ABASTECIMIENTO"
              onClick={handleSubmit(onSubmit)}
              loading={loading}
            />
          </Box>
        </Box>
      </Box>
      <AlertDialog
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
          navigate('/catalogs/replenishments');
        }}
        dialogTitle="Creación de abastecimiento"
        dialogContent={dialogContent}
      />
    </Box>
  );
}

export default NewReplenishment;
