import { useState, useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useForm, useController } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SubmitButton from 'components/buttons/SubmitButton';
import AlertDialog from 'components/dialogs/AlertDialog';
import FileInput from 'components/input-files/FileInput';
import FileTemplate from 'components/input-files/FileTemplate';
import PageTitle from 'components/layout/PageTitle';
import Table from 'components/table/Table';
import FormTextField from 'components/text-fields/FormTextField';
import { CREATE_BATCH_PRODUCTS } from 'gql/catalogs';
import GET_FILTERS from 'gql/filters';
import FILE_TYPES from 'lib/fileTypes';
import { REQUIRED_FIELD } from 'lib/form';

import columns from './components/columns';
import ProductsDataErrors from './components/ProductsDataErrors';
import TutorialDialog from './components/TutorialDialog';
import useUploadProducts from './hooks/useUploadProducts';

const CREATE_BATCH_PRODUCTS_ERROR_MESSAGE =
  'Ocurrió un error al enviar la solicitud.';
const { REACT_APP_FILE_BATCH_PRODUCTS_TEMPLATE_PATH } = process.env;

function NewBatchProductsForm() {
  const [sellerOptions, setSellerOptions] = useState([]);
  const [showTutorialDialog, setShowTutorialDialog] = useState(false);
  useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        catalogFilterOptions: { sellers: sellersFilterOptions },
      },
    }) => {
      const sellers = sellersFilterOptions.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setSellerOptions(sellers);
    },
  });
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [createBatchProducts, { loading, error, reset: resetMutation }] =
    useMutation(CREATE_BATCH_PRODUCTS, {
      onCompleted: () => {
        resetMutation();
        setOpenDialog(true);
        setDialogContent(
          `Una vez terminada la solicitud, podrás ver los productos creados en la sección de "Productos" y se enviará un reporte a tu correo sobre la creación masiva. `,
        );
      },
    });
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      sellerId: '',
      isExcelValid: { uploaded: false, headers: false, data: false },
    },
  });
  const {
    field: { value: isExcelValid },
  } = useController({
    name: 'isExcelValid',
    control,
    rules: {
      validate: (value) => (value.headers && value.data) || REQUIRED_FIELD,
      required: REQUIRED_FIELD,
    },
  });
  const watchSellerId = watch('sellerId');
  const { products, onSubmit, fileParser, removeProduct, validationErrors } =
    useUploadProducts({
      createBatchProducts,
      setValue,
      sellerId: watchSellerId,
    });

  const tableColumns = useMemo(() => columns({ removeProduct }), [products]);
  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <PageTitle handleClick={() => setShowTutorialDialog(true)}>
            Crear múltiples productos
          </PageTitle>
          <TutorialDialog
            setShowDialog={setShowTutorialDialog}
            showDialog={showTutorialDialog}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <FormTextField
              fieldName="sellerId"
              label="Vendedor"
              control={control}
              register={() =>
                register('sellerId', { required: REQUIRED_FIELD })
              }
              select={{ select: true, options: sellerOptions }}
              sx={{ marginBottom: 2, marginY: 0 }}
            />
            <Divider flexItem />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="subtitle1">
                2. Archivo Excel con productos
              </Typography>
              <FileTemplate
                filePath={REACT_APP_FILE_BATCH_PRODUCTS_TEMPLATE_PATH}
                title="Excel de ejemplo"
              />
              <FileInput
                fileTypes={[FILE_TYPES.XLSX, FILE_TYPES.CSV]}
                fileLabel="XLSX y CSV"
                fileParser={fileParser}
                error={Boolean(errors.isExcelValid)}
                errorMessage="Campo obligatorio"
              />
            </Box>
            {isExcelValid.uploaded && !isExcelValid.headers && (
              <Alert severity="error">
                El formato del excel no está correcto. Revisa que siga las
                indicaciones del template.
              </Alert>
            )}
            {isExcelValid.uploaded && !isExcelValid.data && (
              <ProductsDataErrors errors={validationErrors} />
            )}
            {!!products.length && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="h5">Productos cargados</Typography>
                <Table
                  data={products}
                  columns={tableColumns}
                  hasSearchBox={false}
                  pagination={{
                    hasPaginator: false,
                    controlledPageCount: products.length,
                  }}
                  disableSortBy
                  hasStickyHeader={false}
                />
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {!!error && (
                  <Typography
                    color="error"
                    gutterBottom
                    align="center"
                    paragraph
                  >
                    {CREATE_BATCH_PRODUCTS_ERROR_MESSAGE}
                  </Typography>
                )}
                <SubmitButton
                  label="CREAR PRODUCTOS"
                  onClick={handleSubmit(onSubmit)}
                  loading={loading}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <AlertDialog
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
            navigate('/catalogs');
          }}
          dialogTitle="Creación de productos"
          dialogContent={dialogContent}
        />
      </Box>
    </Container>
  );
}
export default NewBatchProductsForm;
