import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';

function LinkButton({ text, icon, to, open }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link to={to} style={{ textDecoration: 'none', color: 'currentColor' }}>
      <ListItemButton
        selected={!!match}
        sx={[
          (theme) => ({
            '&.Mui-selected': {
              backgroundColor: `${theme.palette.light.main} !important`,
              color: theme.palette.secondary.main,
              '& .activeListItemIcon': {
                color: theme.palette.secondary.main,
              },
            },
            '&:hover': {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.light.main,
              '& .activeListItemIcon': {
                color: theme.palette.secondary.main,
              },
            },
          }),
          { pl: 2 },
        ]}
      >
        <ListItemIcon
          className="activeListItemIcon"
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </Link>
  );
}

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default LinkButton;
