import { useState, useCallback, Suspense } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useLoaderData, Outlet, Await } from 'react-router-dom';

import AppBar from './AppBar';
import SideBar from './SideBar';
import WrapperView from './WrapperView';

const openedMixin = (theme) => ({
  width: theme.drawer.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

function Layout() {
  const data = useLoaderData();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  });

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  });
  return (
    <Suspense fallback={<LinearProgress color="secondary" />}>
      <Await resolve={data.isAuthenticated}>
        <Box sx={{ display: 'flex' }}>
          <AppBar open={open} />
          <SideBar
            variant="permanent"
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            openedMixin={openedMixin}
            closedMixin={closedMixin}
          />
          <WrapperView>
            <Outlet />
          </WrapperView>
        </Box>
      </Await>
    </Suspense>
  );
}

export default Layout;
