import { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { replenishmentFilterVar } from '../../../../apollo/reactiveVariables';
import PageTitle from '../../../../components/layout/PageTitle';
import SkeletonListData from '../../../../components/skeletons/ListData';
import useGetFilters from '../../../../custom-hooks/useGetFilters';
import { GET_REPLENISHMENTS } from '../../../../gql/catalogs';
import ListServerData from '../../../commons/ListServerData';
import columns from './components/columns';
import ReplenishmentFilters from './components/ReplenishmentFilters';

const NEW_REPLENISHMENT_ROUTE = '/catalogs/replenishments/new';

function ReplenishmentList() {
  const replenishmentFilter = useReactiveVar(replenishmentFilterVar);

  const { filters, setFilters, filterOptions, loading } = useGetFilters({
    filterVar: replenishmentFilterVar,
    filterOptionsKey: 'orderFilterOptions', // TODO: add replenishmentFilterOptions
  });

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({
      ...prev,
      globalFilter,
    }));
  };

  const memoizedColumns = useMemo(() => columns(), []);
  if (loading)
    return (
      <>
        <PageTitle>Abastecimientos</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <PageTitle width="auto">Abastecimientos</PageTitle>
        <Button
          startIcon={<AddIcon />}
          color="secondary"
          href={NEW_REPLENISHMENT_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
        >
          CREAR ABASTECIMIENTO
        </Button>
      </Box>
      <ListServerData
        query={GET_REPLENISHMENTS}
        filters={{
          components: (
            <ReplenishmentFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
              warehousesOptions={filterOptions.warehouses}
            />
          ),
          helperTextSearchBox: 'Buscar por referencia',
        }}
        dataKey="replenishments"
        columns={memoizedColumns}
        queryVariables={{ filters }}
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={replenishmentFilter.globalFilter}
      />
    </>
  );
}
export default ReplenishmentList;
