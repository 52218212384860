import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import storeLoader from 'assets/images/store-loader.gif';
import Error from 'components/Error';
import DefaultPdf from 'components/pdf/DefaultPdf';
import TableBox from 'components/table/TableBox';
import { GET_TRANSFER } from 'gql/catalogs';
import dateParser from 'helpers/dateParser';
import { TRANSFER_STATUS_LABEL_MAPPING } from 'lib/transferStatuses';

function TransferPdf() {
  const [transferData, setTransferData] = useState();
  const { id } = useParams();

  const [getTransfer, { loading, error }] = useLazyQuery(GET_TRANSFER);

  useEffect(() => {
    getTransfer({
      variables: { id },
      onCompleted: ({ transfer }) => {
        setTransferData({
          ...transfer,
        });
      },
    });
  }, []);

  const tableData = useMemo(() => {
    if (!transferData) return null;
    return {
      summaryData: {
        Estado: TRANSFER_STATUS_LABEL_MAPPING[transferData.status],
        Vendedor: transferData.seller?.name,
        'Tienda origen': transferData.originWarehouse?.name,
        'Tienda destino': transferData.destinationWarehouse?.name,
        'Fecha ingreso': dateParser(transferData.createdAt),
        Comentario: transferData.comment,
      },
      title: { type: 'Transferencia', name: transferData.reference },
      bodyData: {
        columnsNames: ['Sku', 'Cantidad transferida'],
        columns: ['sku', 'quantityDelta'],
        data: transferData.items,
      },
    };
  }, [transferData]);

  if (error) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <Error message="No pudimos encontrar la información de este abastecimiento." />
        </TableBox>
      </Box>
    );
  }
  if (loading || !transferData) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <img src={storeLoader} alt="store-loader" width="110px" />
          <Typography>Cargando información</Typography>
        </TableBox>
      </Box>
    );
  }

  return (
    <DefaultPdf
      summaryData={tableData.summaryData}
      title={tableData.title}
      bodyData={tableData.bodyData}
    />
  );
}

export default TransferPdf;
