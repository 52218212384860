import { useMemo, useState, useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const NEW_PRODUCT_ROUTE = '/catalogs/products/new';
const NEW_BATCH_PRODUCTS_ROUTE = '/catalogs/products/new-batch';

function CreateProductButton() {
  const [anchorEl, setAnchorEl] = useState();

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  return (
    <>
      <Button
        startIcon={<AddIcon />}
        color="secondary"
        onClick={handleClick}
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        CREAR PRODUCTO
      </Button>
      <Menu
        id="create-product-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component="button"
          href={NEW_PRODUCT_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Crear un producto
        </MenuItem>
        <MenuItem
          component="button"
          href={NEW_BATCH_PRODUCTS_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Crear múltiples productos
        </MenuItem>
      </Menu>
    </>
  );
}
export default CreateProductButton;
