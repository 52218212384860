import { useState, useEffect, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import {
  orderFilterVar,
  seenTourListOrdersVar,
} from 'apollo/reactiveVariables';
import PageTitle from 'components/layout/PageTitle';
import { showTourGuideListOrdersLocalStorage } from 'components/onboarding/helpers';
import TourGuide from 'components/onboarding/TourGuide';
import SkeletonListData from 'components/skeletons/ListData';
import GET_FILTERS from 'gql/filters';
import GET_ORDERS from 'gql/orders';
import { GENERATE_ORDERS_REPORT } from 'gql/reports';
import ListServerData from 'views/commons/ListServerData';

import columns from './columns';
import CreateOrderButton from './CreateOrderButton';
import OrderFilters from './OrderFilters';
import OrderItems from './OrderItems';

function ListOrders({ title, customFilters, hasProcessingTime }) {
  const orderFilter = useReactiveVar(orderFilterVar);
  const seenTourListOrders = useReactiveVar(seenTourListOrdersVar);

  const TOUR_STEPS = [
    {
      title: 'Órdenes',
      target: '#pageTitle',
      content: 'Ahora puedes visualizar las órdenes de tus canales integrados',
    },
    {
      title: 'Filtros',
      target: '#filterBox',
      content:
        'Puedes filtrar por vendedor, tienda, rango de fecha de ingreso y buscar por orden',
    },
    {
      title: 'Ordenar',
      target: '#tableSort',
      content: 'Puedes ordenar por fecha de ingreso de la orden',
    },
    {
      title: 'Productos',
      target: '#tableSubRow',
      content: 'Puedes ver los productos de la orden',
    },
    {
      title: 'Boleta',
      target: '#invoiceButton',
      content: 'Puedes visualizar la boleta si está en nuestro sistema',
    },
    {
      title: 'Tracking',
      target: '#trackingButton',
      content: 'Puedes ver el seguimiento del pedido que ve tu cliente',
    },
  ];
  const [run, setRun] = useState(!seenTourListOrders);
  const [filterOptions, setFilterOptions] = useState({
    warehouses: [],
    sellers: [],
  });
  const [filters, setFilters] = useState(orderFilter);
  const [queryVariables, setQueryVariables] = useState(orderFilter);

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        orderFilterOptions: { sellers: _sellers, warehouses: _warehouses },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const warehouses = _warehouses.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers, warehouses });
      setFilters((prev) => ({
        ...prev,
        warehouseId: orderFilter.warehouseId,
        sellerId: orderFilter.sellerId,
      }));
    },
  });
  useEffect(() => {
    orderFilterVar(filters);
  }, [filters]);

  useEffect(() => {
    setQueryVariables({
      ...filters,
      warehouseId:
        filters.warehouseId.length > 0 ? filters.warehouseId : undefined,
      sellerId: filters.sellerId.length > 0 ? filters.sellerId : undefined,
    });
  }, [filters]);

  const renderSubComponent = (row) => {
    const {
      original: {
        items,
        orderCurrency,
        shippingValue,
        discountsValue,
        totalValue,
        valuesWithTaxes,
      },
    } = row;

    return (
      <OrderItems
        items={items}
        currency={orderCurrency}
        shippingValue={shippingValue}
        discountsValue={discountsValue}
        totalValue={totalValue}
        valuesWithTaxes={valuesWithTaxes}
      />
    );
  };

  const [openExportDataForm, setOpenExportDataForm] = useState(false);
  const handleClickOpenExportDataForm = () => {
    setOpenExportDataForm(true);
  };
  const handleCloseExportDataForm = () => {
    setOpenExportDataForm(false);
  };
  const report = {
    hasReport: true,
    reportProperties: {
      mutation: GENERATE_ORDERS_REPORT,
      type: 'orders',
      dialogFunctions: {
        open: openExportDataForm,
        setOpen: setOpenExportDataForm,
        handleClickOpen: handleClickOpenExportDataForm,
        handleClose: handleCloseExportDataForm,
      },
      filterOptions,
    },
  };
  const memoizedColumns = useMemo(() => columns({ hasProcessingTime }), []);
  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({ ...prev, globalFilter }));
  };

  if (loading)
    return (
      <>
        <PageTitle>{title}</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <PageTitle
          handleClick={() => {
            setRun(true);
          }}
          width="auto"
        >
          {title}
        </PageTitle>
        <CreateOrderButton />
      </Box>
      <TourGuide
        steps={TOUR_STEPS}
        run={run}
        setRun={setRun}
        finishTour={showTourGuideListOrdersLocalStorage}
      />
      <ListServerData
        query={GET_ORDERS}
        filters={{
          components: (
            <OrderFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
              warehousesOptions={filterOptions.warehouses}
            />
          ),
          helperTextSearchBox:
            'Buscar por número, estado, tipo de entrega, cliente o canal de venta de la orden',
        }}
        report={report}
        queryVariables={{
          filters: {
            ...queryVariables,
            ...customFilters,
          },
        }}
        dataKey="getOrders"
        columns={memoizedColumns}
        renderSubComponent={renderSubComponent}
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={orderFilter.globalFilter}
      />
    </>
  );
}
ListOrders.propTypes = {
  customFilters: PropTypes.shape({
    shippingMethod: PropTypes.arrayOf(PropTypes.string),
    ninStatus: PropTypes.arrayOf(PropTypes.string),
  }),
  hasProcessingTime: PropTypes.bool,
  title: PropTypes.string,
};
ListOrders.defaultProps = {
  customFilters: {},
  hasProcessingTime: false,
  title: 'Órdenes',
};
export default ListOrders;
