import { useMemo, useState, useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const NEW_ORDER_ROUTE = '/orders/new';
const NEW_BATCH_ORDERS_ROUTE = '/orders/new-batch';

function CreateOrderButton() {
  const [anchorEl, setAnchorEl] = useState();

  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  return (
    <>
      <Button startIcon={<AddIcon />} color="secondary" onClick={handleClick}>
        CREAR ORDEN
      </Button>
      <Menu
        id="create-order-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component="button"
          href={NEW_ORDER_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Crear una orden
        </MenuItem>
        <MenuItem
          component="button"
          href={NEW_BATCH_ORDERS_ROUTE}
          target="_blank"
          rel="noopener noreferrer"
        >
          Crear múltiples órdenes
        </MenuItem>
      </Menu>
    </>
  );
}
export default CreateOrderButton;
