import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { UPDATE_PRODUCT, CREATE_PRODUCT } from 'gql/catalogs';

import translateUpdatedProduct from '../edit/lib/translateUpdatedProduct';
import FORM_TYPES from '../lib/formTypes';
import translateNewProduct from '../new/lib/translateNewProduct';

const ACTION_MESSAGE = {
  [FORM_TYPES.new]: 'creado',
  [FORM_TYPES.edit]: 'actualizado',
};

function useSubmit({ setDialogContent, setVisibleDialog, formType = 'edit' }) {
  const mutation =
    FORM_TYPES.new === formType ? CREATE_PRODUCT : UPDATE_PRODUCT;
  const dataKey = mutation.definitions[0].name.value;
  const translateProduct =
    FORM_TYPES.new === formType ? translateNewProduct : translateUpdatedProduct;
  const [fetchMutation, { loading, error, reset }] = useMutation(mutation, {
    onCompleted: (data) => {
      const {
        [dataKey]: { sku, name },
      } = data;
      setDialogContent(
        `El producto ${name} con sku ${sku} se ha ${ACTION_MESSAGE[formType]} exitosamente`,
      );
      reset();
      setVisibleDialog(true);
    },
  });
  const onSubmit = useCallback((updatedFields) => {
    const product = translateProduct(updatedFields);
    fetchMutation({
      variables: {
        input: product,
      },
    });
  }, []);
  return { loading, error, onSubmit };
}
export default useSubmit;
