import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({
  palette: {
    stepper: {
      active: '#ea5b27',
      completed: '#ea5b27',
      disabled: '#C2C2C3',
      line: '#eaeaf0',
    },
    primary: {
      main: '#808080',
    },
    secondary: {
      main: '#FE5000',
    },
    error: {
      main: '#ff0000',
    },
    success: {
      main: '#009245',
    },
    warning: {
      main: '#FFB60A',
    },
    info: {
      main: '#4163fa',
    },
    light: {
      main: '#fdefe9',
    },
    primaryBrand: {
      main: '#3F2A56',
    },
    dropzoneBorder: {
      main: '#ced4da',
    },
    dropzoneBackgroundColor: {
      main: '#f8f9fa',
    },
    submitButtonBackgroundColor: {
      main: '#01B0BB',
    },
    icons: {
      disabled: '#E8E8E8',
      valid: '#02EB88',
    },
    tagColor: {
      textColor: {
        DEFAULT: '#595959',
        DRY: '#956E3F',
        FROZEN: '#447A97',
        REFRIGERATED: '#324CAF',
      },
      backgroundColor: {
        DEFAULT: '#F3F3F3',
        DRY: '#FDF6EC',
        FROZEN: '#EEFAFE',
        REFRIGERATED: '#F1F7FE',
      },
    },
  },
  drawer: {
    width: 300,
  },
  typography: {
    fontFamily: 'rubik, sans-serif;',
    subtitle1: {
      fontWeight: 600,
    },
    caption: {
      color: '#656565',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 30,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          borderWidth: 3,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #d5d0da',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#C2C2C3',
          '&.Mui-active': {
            color: '#ea5b27',
          },
          '&.Mui-completed': {
            color: '#999',
          },
        },
        labelContainer: {
          width: 'inherit',
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
});

export default theme;
