import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

function CustomDialog({
  dialogTitle,
  dialogContentText,
  dialogContent,
  dialogActions,
  openDialog,
  handleCloseDialog,
}) {
  return (
    <Dialog open={openDialog} onClose={() => handleCloseDialog()}>
      <DialogTitle color={dialogTitle.color ? dialogTitle.color : ''}>
        {dialogTitle.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
        {dialogContent}
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  dialogContentText: PropTypes.string,
  dialogContent: PropTypes.node.isRequired,
  dialogActions: PropTypes.node.isRequired,
  openDialog: PropTypes.bool.isRequired,
};
CustomDialog.defaultProps = {
  dialogContentText: '',
};
export default CustomDialog;
