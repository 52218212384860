const STATUS_COLOR_MAPPING = {
  created: 'warning',
  pendingApproval: 'warning',
  pending: 'warning',
  backorder: 'error',
  processing: 'warning',
  readyAtStore: 'info',
  invoiced: 'info',
  deliveryConfirmed: 'info',
  releasedToCarrier: 'info',
  outForDelivery: 'info',
  delivered: 'success',
  canceled: 'error',
  error: 'error',
};

export default STATUS_COLOR_MAPPING;
