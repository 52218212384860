import { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import ArrayFormTextFieldCell from '../../../../../components/text-fields/ArrayFormTextFieldCell';
import { CURRENCIES } from '../../../../../lib/currency';

const DATE_FORMAT = 'dd/MM/yyyy';
const MIN_NUMBER_VALIDATION = 1;
const DATE_PICKER_WIDTH = 160;
const DEFAULT_WIDTH = 100;
const QUANTITY_WIDTH = 80;
const CURRENCY_WIDTH = 80;

const columns = ({
  control,
  register,
  setValue,
  remove,
  replenishmentData,
}) => [
  {
    Header: <Typography>Sku</Typography>,
    accessor: 'sku',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === value,
      );
      return (
        <ArrayFormTextFieldCell
          formItemHeaderName="sku"
          currentItemIndex={currentItemIndex}
          control={control}
          register={register}
          width={DEFAULT_WIDTH}
        />
      );
    },
  },
  {
    Header: <Typography>Cantidad</Typography>,
    accessor: 'quantity',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { sku },
      },
    }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === sku,
      );

      return (
        <ArrayFormTextFieldCell
          formItemHeaderName="quantity"
          currentItemIndex={currentItemIndex}
          control={control}
          register={register}
          inputType="number"
          valueAsNumber
          validation={{ min: MIN_NUMBER_VALIDATION }}
          width={QUANTITY_WIDTH}
        />
      );
    },
  },
  {
    Header: <Typography>Costo unitario</Typography>,
    accessor: 'unitCost',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { sku },
      },
    }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === sku,
      );
      return (
        <ArrayFormTextFieldCell
          formItemHeaderName="unitCost"
          currentItemIndex={currentItemIndex}
          control={control}
          register={register}
          inputType="number"
          valueAsNumber
          validation={{ min: MIN_NUMBER_VALIDATION }}
          required={false}
          width={DEFAULT_WIDTH}
        />
      );
    },
  },
  {
    Header: <Typography>Moneda</Typography>,
    accessor: 'costCurrency',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { sku, costCurrency },
      },
    }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === sku,
      );
      return (
        <ArrayFormTextFieldCell
          formItemHeaderName="costCurrency"
          currentItemIndex={currentItemIndex}
          control={control}
          register={register}
          required={false}
          defaultValue={costCurrency || ''}
          select={{
            select: true,
            options: Object.keys(CURRENCIES).map((currency) => ({
              value: currency,
              label: currency,
            })),
          }}
          width={CURRENCY_WIDTH}
        />
      );
    },
  },
  {
    Header: <Typography>Número de lote</Typography>,
    accessor: 'lotNumber',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { sku },
      },
    }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === sku,
      );
      return (
        <ArrayFormTextFieldCell
          formItemHeaderName="lotNumber"
          currentItemIndex={currentItemIndex}
          control={control}
          register={register}
          required={false}
          width={DEFAULT_WIDTH}
        />
      );
    },
  },
  {
    Header: <Typography>Fecha de expiración</Typography>,
    accessor: 'expirationDate',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { sku },
      },
    }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === sku,
      );

      const [date, setDate] = useState(value ?? null);
      return (
        <DatePicker
          inputFormat={DATE_FORMAT}
          value={date}
          onChange={(event) => {
            setDate(event.toString());
            setValue(
              `items[${currentItemIndex}].expirationDate`,
              event.toString(),
            );
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              sx={{ width: DATE_PICKER_WIDTH }}
              size="small"
            />
          )}
        />
      );
    },
  },
  {
    Header: '',
    id: 'delete',
    accessor: 'sku',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => {
      const currentItemIndex = replenishmentData.findIndex(
        (item) => item.sku === value,
      );
      return (
        <Box
          key={`delete-item${value}-box`}
          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
        >
          <IconButton
            onClick={() => {
              remove(currentItemIndex);
            }}
          >
            <Tooltip title="Eliminar">
              <ClearIcon />
            </Tooltip>
          </IconButton>
        </Box>
      );
    },
  },
];
export default columns;
