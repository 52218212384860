import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';

import logo from 'assets/images/logo-store-central.png';
import storeLoader from 'assets/images/store-loader.gif';
import Error from 'components/Error';
import Separator from 'components/pdf/Separator';
import SummaryView from 'components/pdf/SummaryView';
import TableView from 'components/pdf/TableView';
import TableBox from 'components/table/TableBox';
import { GET_ORDER_BY_ID } from 'gql/orders';
import dateParser from 'helpers/dateParser';
import { formatCurrencyValue } from 'lib/currency';
import { ORDER_ISSUES_LABEL_MAPPING } from 'lib/orderIssues';
import { ORDER_STATUS_LABEL_MAPPING } from 'lib/orderStatuses';

function OrderPdf() {
  const [orderData, setOrderData] = useState();
  const { orderId } = useParams();

  const [getOrder, { loading, error }] = useLazyQuery(GET_ORDER_BY_ID);

  useEffect(() => {
    getOrder({
      variables: { id: orderId },
      onCompleted: ({ getOrderById }) => {
        setOrderData(getOrderById);
      },
    });
  }, []);

  const pdfData = useMemo(() => {
    if (!orderData) return null;
    return {
      title: { type: 'Orden', name: orderData.sourceOrderId },
      summaryData: {
        Estado: ORDER_STATUS_LABEL_MAPPING[orderData.status.value],
        Vendedor: orderData.seller?.name,
        Tienda: orderData.warehouse?.name,
        'Fecha Ingreso': dateParser(orderData.createdAt),
        'Tipo de negocio': orderData.businessType,
        Plataforma: orderData.system?.id,
        'ID Plataforma': orderData.sourceOrderId,
        'ID Wms': orderData.wmsId,
        Observaciones: orderData.comment,
      },
      itemsData: {
        columnsNames: [
          'Sku',
          'Descripción',
          'Cantidad',
          'Precio unitario',
          'Monto total',
        ],
        columns: ['sku', 'description', 'quantity', 'unitPrice', 'total'],
        data: [
          ...orderData.items.map((item) => {
            const unitCost = item.unitPrice - item.unitDiscount;
            return {
              sku: item.sku,
              key: item.sku,
              description: item.description,
              quantity: item.quantity,
              unitPrice: formatCurrencyValue({
                currency: orderData.orderCurrency,
                value: unitCost,
              }),
              total: formatCurrencyValue({
                currency: orderData.orderCurrency,
                value: unitCost * item.quantity,
              }),
            };
          }),
          {
            unitPrice: 'Subtotal items',
            total: formatCurrencyValue({
              currency: orderData.orderCurrency,
              value: orderData.items?.reduce(
                (acc, item) => acc + item.totalValue,
                0,
              ),
            }),
            key: 'subtotal',
          },
          {
            unitPrice: 'Despacho',
            total: formatCurrencyValue({
              currency: orderData.orderCurrency,
              value: orderData.shippingValue,
            }),
            key: 'delivery',
          },
          {
            unitPrice: 'Descuentos',
            total: formatCurrencyValue({
              currency: orderData.orderCurrency,
              value: orderData.discountsValue,
            }),
            key: 'discounts',
          },
          {
            unitPrice: orderData.valuesWithTaxes
              ? 'Total (Impuestos incluidos)'
              : 'Total (Sin impuestos)',
            total: formatCurrencyValue({
              currency: orderData.orderCurrency,
              value: orderData.totalValue,
            }),
            key: 'total',
          },
        ],
      },
      customerData: {
        Nombre: `${orderData.customer?.firstName} ${orderData.customer?.lastName}`,
        Teléfono: orderData.customer?.phone,
        Correo: orderData.customer?.email,
      },
      deliveryData: {
        'Tipo de envío': orderData.delivery?.shippingMethod,
        Courier: orderData.delivery?.systemName,
        Dirección: orderData.delivery?.dropOffAddress?.computedAddress?.label,
      },
      issuesTableData: {
        columnsNames: [
          'Fecha Creación',
          'Incidencia',
          'Reportado por',
          'Observaciones',
        ],
        columns: ['createdAt', 'issueType', 'createdBy', 'comment'],
        data: orderData.issuesData.map((issue) => ({
          ...issue,
          createdAt: dateParser(issue.createdAt),
          issueType: ORDER_ISSUES_LABEL_MAPPING[issue.issueType],
          key: issue.comment,
        })),
      },
    };
  }, [orderData]);
  if (error) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <Error message="No pudimos encontrar la información de esta orden." />
        </TableBox>
      </Box>
    );
  }
  if (loading || !orderData) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <img src={storeLoader} alt="store-loader" width="110px" />
          <Typography>Cargando información</Typography>
        </TableBox>
      </Box>
    );
  }
  return (
    <Box
      style={{
        margin: 'auto',
        minHeight: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      <PDFViewer
        style={{
          width: '100%',
          height: window.innerHeight,
          alignSelf: 'center',
        }}
      >
        <Document>
          <Page size="A4" style={{ padding: 20 }}>
            <View style={{ alignItems: 'left', width: '15%' }}>
              <Image src={logo} />
            </View>
            <View style={{ alignItems: 'center', width: '100%' }}>
              <Text
                key="Title"
                style={{
                  fontSize: 14,
                  fontWeight: 100,
                }}
              >
                {`${pdfData.title.type} #${pdfData.title.name}`}
              </Text>
            </View>
            <Separator />
            <SummaryView
              summaryData={pdfData.summaryData}
              summaryTitle="Información general de la orden"
            />
            <Separator />
            <TableView
              columnsNames={pdfData.itemsData.columnsNames}
              columns={pdfData.itemsData.columns}
              data={pdfData.itemsData.data}
              tableName="Items de la orden"
              itemKey="key"
            />
            <Separator />
            <SummaryView
              summaryData={pdfData.customerData}
              summaryTitle="Información del cliente"
            />
            <Separator />
            <SummaryView
              summaryData={pdfData.deliveryData}
              summaryTitle="Delivery"
            />
            <Separator />
            <TableView
              columnsNames={pdfData.issuesTableData.columnsNames}
              columns={pdfData.issuesTableData.columns}
              data={pdfData.issuesTableData.data}
              tableName="Incidencias"
              itemKey="key"
            />
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
}

export default OrderPdf;
