import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function HeaderListData({ children, align, fontSize, sx }) {
  return (
    <Typography
      variant="subtitle1"
      color={grey[700]}
      sx={{ fontSize, textAlign: align, ...sx }}
    >
      {children}
    </Typography>
  );
}

HeaderListData.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  sx: PropTypes.shape({}),
};
HeaderListData.defaultProps = {
  align: 'inherit',
  fontSize: '0.8rem',
  sx: {},
};
export default HeaderListData;
