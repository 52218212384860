const ERRORS = {
  UNKNOW_ERROR_MESSAGE: 'Unknown error',
  AUTHORIZATION_ERROR_MESSAGE: 'Not authorized',
  AUTHENTICATION_ERROR_MESSAGE: 'Unauthenticated',
  NOT_ENOUGH_STOCK_TO_FULFILL_THE_ORDER_ERROR_MESSAGE:
    'Not enough stock to fulfill the order',
  NOT_FOUND_WMS_INTEGRATION: 'Seller does not have Wms integration',
  NOT_FOUND_WMS_PRODUCT: 'Product not found in Wms',
  INVALID_PRODUCTS_CATALOG_MESSAGE: 'Some products are not registered',
  DUPLICATED_PRODUCTS_CATALOG_MESSAGE: 'Some SKUs are duplicated',
  ORIGIN_WAREHOUSE_IS_EQUAL_TO_DESTINATION_WAREHOUSE:
    'Origin warehouse must be different from the destination warehouse',
};

const LABEL_ERRORS = {
  UNKNOW_ERROR_MESSAGE: 'Error desconocido',
  USER_INPUT_MESSAGE:
    'Ocurrió un error. Revisa que los datos ingresados estén correctos',
  AUTHORIZATION_ERROR_MESSAGE:
    'No tienes los permisos para acceder a esta función',
  AUTHENTICATION_ERROR_MESSAGE: 'Error de autenticación: inicia sesión primero',
  NOT_ENOUGH_STOCK_TO_FULFILL_THE_ORDER_ERROR_MESSAGE:
    'No hay inventario suficiente para crear la orden',
  USER_INPUT_ORDER_MESSAGE:
    'No pudimos crear la orden, revisa si los datos ingresados están correctos',
  UNKNOW_ERROR_ORDER_MESSAGE:
    'No pudimos crear la orden, ocurrió un error imprevisto',
  NOT_FOUND_WMS_INTEGRATION: 'El vendedor no tiene integración con el Wms',
  NOT_FOUND_WMS_PRODUCT: 'Producto no encontrado en el Wms',
  INVALID_PRODUCTS_CATALOG_MESSAGE:
    'Hay SKUs ingresados que no están registrados en nuestro sistema',
  DUPLICATED_PRODUCTS_CATALOG_MESSAGE: 'Hay SKUs duplicados',
  USER_INPUT_REPLENISHMENT_MESSAGE:
    'No pudimos crear el abastecimiento, revisa si los datos ingresados están correctos',
  USER_INPUT_TRANSFER_MESSAGE:
    'No pudimos crear la transferencia, revisa si los datos ingresados están correctos',
  ORIGIN_WAREHOUSE_IS_EQUAL_TO_DESTINATION_WAREHOUSE:
    'La tienda de origen debe ser distinta a la tienda destino',
};

const STATUS_CODES = {
  BAD_REQUEST: 400,
};

export { ERRORS, LABEL_ERRORS, STATUS_CODES };
