import { useState, useEffect } from 'react';

import {
  ERRORS,
  LABEL_ERRORS,
  STATUS_CODES,
} from '../../../../../apollo/serverErrors';

function useErrorMessage({ error }) {
  const [mutationErrorMessage, setMutationErrorMessage] = useState('');
  useEffect(() => {
    if (error) {
      if (error.message === ERRORS.INVALID_PRODUCTS_CATALOG_MESSAGE) {
        setMutationErrorMessage(LABEL_ERRORS.INVALID_PRODUCTS_CATALOG_MESSAGE);
      } else if (error.networkError?.statusCode === STATUS_CODES.BAD_REQUEST) {
        setMutationErrorMessage(LABEL_ERRORS.USER_INPUT_REPLENISHMENT_MESSAGE);
      } else if (error.message === ERRORS.DUPLICATED_PRODUCTS_CATALOG_MESSAGE) {
        setMutationErrorMessage(
          LABEL_ERRORS.DUPLICATED_PRODUCTS_CATALOG_MESSAGE,
        );
      } else {
        setMutationErrorMessage(LABEL_ERRORS.UNKNOW_ERROR_MESSAGE);
      }
    }
  }, [error]);
  return { mutationErrorMessage };
}

export default useErrorMessage;
