import { useState, useEffect, useMemo, useRef, useCallback } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import getGoogleMapsAddressComponents from 'lib/googleMaps';

import { REQUIRED_FIELD } from '../../lib/form';
import GoogleMapsMapSkeleton from './GoogleMapsMapSkeleton';

const { REACT_APP_GOOGLE_MAPS_API_KEY, REACT_APP_GOOGLE_MAPS_API_URL } =
  process.env;
const AUTOCOMPLETE_LABEL = 'Ingresa la dirección de despacho';
const NO_OPTIONS_TEXT =
  'No hemos podido encontrar la dirección. Puedes ingresarla de nuevo';
const GOOGLE_AUTOCOMPLETE_COUNTRIES = ['cl', 'pe'];
const CLEAR_EVENT_TYPE = 'clear';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

function GoogleMapsAutocomplete({
  fieldName,
  setValue,
  disabled,
  control,
  showMap,
  getComputedAddress,
}) {
  const {
    field,
    formState: { errors },
  } = useController({
    name: fieldName,
    control,
    rules: { required: REQUIRED_FIELD },
  });
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const refMap = useRef(null);
  const map = useRef(null);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `${REACT_APP_GOOGLE_MAPS_API_URL}?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }
  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );
  const onChange = useCallback(
    (_event, newValue, reason) => {
      if (reason === CLEAR_EVENT_TYPE) {
        // set value to '' instead of null.
        setValue(fieldName, '');
      } else {
        const placeId = newValue.place_id;

        placesService.current.getDetails(
          {
            placeId,
            fields: ['address_components', 'formatted_address', 'geometry'],
          },
          (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if (!map.current) {
                map.current = new window.google.maps.Map(refMap.current, {
                  center: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  },
                  zoom: 16,
                });
              } else {
                map.current.setCenter(place.geometry.location);
              }
              const computedAddress = getGoogleMapsAddressComponents(place);
              getComputedAddress(computedAddress);
              // eslint-disable-next-line no-new
              new window.google.maps.Marker({
                position: place.geometry.location,
                map: map.current,
              });
            }
          },
        );
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(fieldName, newValue?.description);
      }
    },
    [options, fieldName],
  );
  const onInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);
  const isOptionEqualToValue = useCallback((option, value) => {
    if (!value) {
      return false;
    }
    if (typeof option === 'object') {
      return option.description === value;
    }
    return option === value;
  }, []);

  useEffect(() => {
    let active = true;
    if (!placesService.current && window.google && refMap.current) {
      placesService.current = new window.google.maps.places.PlacesService(
        refMap.current,
      );
    }
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!inputValue) {
      setOptions(field.value ? [field.value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: GOOGLE_AUTOCOMPLETE_COUNTRIES,
        },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (field.value) {
            newOptions = [field.value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [field.value, inputValue, fetch]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Autocomplete
        id="google-maps-autocomplete"
        sx={{ flexGrow: 1 }}
        size="small"
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={field.value || ''}
        noOptionsText={NO_OPTIONS_TEXT}
        onChange={onChange}
        onInputChange={onInputChange}
        disableClearable={disabled}
        isOptionEqualToValue={isOptionEqualToValue}
        popupIcon={disabled ? null : <ArrowDropDownOutlinedIcon />}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            disabled={disabled}
            helperText={
              errors && errors[fieldName] && errors[fieldName].message
            }
            error={Boolean(!field.value && errors[fieldName])}
            label={AUTOCOMPLETE_LABEL}
            fullWidth
            inputRef={field.ref}
          />
        )}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting?.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  {parts.map((part, index) => (
                    <Box
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Box>
        <div
          ref={refMap}
          id="map"
          hidden={!showMap || (showMap && !field.value)}
          style={{ height: '300px', marginBottom: 16, display: 'hidden' }}
        />
      </Box>
      {showMap && !field.value && <GoogleMapsMapSkeleton />}
    </Box>
  );
}
GoogleMapsAutocomplete.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({ description: PropTypes.string }),
    PropTypes.string,
  ]),
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  showMap: PropTypes.bool,
  getComputedAddress: PropTypes.func,
};
GoogleMapsAutocomplete.defaultProps = {
  value: null,
  disabled: false,
  showMap: false,
  getComputedAddress: () => {},
};
export default GoogleMapsAutocomplete;
