import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/Table';
import { formatCurrencyValue } from '../../../../lib/currency';

function ItemListTable({ items, summary, currency }) {
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Descripción</Typography>,
        accessor: 'description',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'quantity',
        align: 'right',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Precio unitario</Typography>,
        accessor: 'unitPrice',
        align: 'right',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { unitDiscount },
          },
        }) => {
          if (typeof unitDiscount === 'number') {
            return (
              <Typography>
                {formatCurrencyValue({ currency, value: value - unitDiscount })}
              </Typography>
            );
          }
          return value;
        },
      },
      {
        Header: <Typography>Monto total</Typography>,
        accessor: 'totalValue',
        align: 'right',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <Typography>{formatCurrencyValue({ currency, value })}</Typography>
        ),
      },
    ],
    [currency],
  );

  const itemsTotalValue = useMemo(
    () => items.reduce((acc, item) => acc + item.totalValue, 0),
    [items],
  );

  const { discountsValue, shippingValue, totalValue, valuesWithTaxes } =
    summary;

  const itemsWithSummary = [
    ...items,
    {
      unitPrice: 'Subtotal items',
      totalValue: itemsTotalValue,
      valueSummary: true,
    },
    { unitPrice: 'Despacho', totalValue: shippingValue, valueSummary: true },
    { unitPrice: 'Descuentos', totalValue: discountsValue, valueSummary: true },
    {
      unitPrice: (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>Total</Typography>
          <Typography variant="caption">
            {valuesWithTaxes ? 'Impuestos incluidos' : 'Sin impuestos'}
          </Typography>
        </Box>
      ),
      totalValue,
      valueSummary: true,
    },
  ];

  return (
    <>
      <Typography variant="h6" sx={{ marginY: 4 }}>
        Items de la orden
      </Typography>
      <Table
        data={itemsWithSummary}
        columns={columns}
        pagination={false}
        hasSearchBox={false}
      />
    </>
  );
}

ItemListTable.propTypes = {
  currency: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      quantitu: PropTypes.string,
      sku: PropTypes.string,
      totalValue: PropTypes.number,
      unitDiscount: PropTypes.number,
      unitPrice: PropTypes.number,
    }),
  ).isRequired,
  summary: PropTypes.shape({
    discountsValue: PropTypes.number,
    shippingValue: PropTypes.number,
    totalValue: PropTypes.number,
    valuesWithTaxes: PropTypes.bool,
  }).isRequired,
};

export default ItemListTable;
