import Checkbox from '@mui/material/Checkbox';

import EditButtons from './EditButtons';
import ExpandedCell from './ExpandedCell';
import ExpandedHeader from './ExpandedHeader';

const ACTIONS_COLUMN_ID = 'actions';

function addExpander(columns) {
  return [
    {
      id: 'expander',
      padding: 'checkbox',
      // eslint-disable-next-line react/prop-types
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <ExpandedHeader
          getToggleAllRowsExpandedProps={getToggleAllRowsExpandedProps}
          isAllRowsExpanded={isAllRowsExpanded}
        />
      ),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => <ExpandedCell row={row} />,
    },
    ...columns,
  ];
}

function addCheckbox(columns) {
  return [
    {
      id: 'selection',
      padding: 'checkbox',
      // eslint-disable-next-line react/prop-types
      Header: ({ getToggleAllRowsSelectedProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Checkbox color="secondary" {...getToggleAllRowsSelectedProps()} />
      ),
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <Checkbox
          color="secondary"
          // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
          {...row.getToggleRowSelectedProps()}
        />
      ),
    },
    ...columns,
  ];
}
function addEdit(columns) {
  const lastColumn = columns[columns.length - 1];
  const lastColumnId = lastColumn.id;
  const editColumn = {
    id: 'edit',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row,
      // eslint-disable-next-line react/prop-types
      editFormProperties,
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original,
      },
    }) => {
      const onClickUpdateRow = async (formData) => {
        // eslint-disable-next-line react/prop-types
        const variables = editFormProperties.updateVariables(
          formData,
          original,
        );

        // eslint-disable-next-line react/prop-types
        await editFormProperties.mutationFunction({
          variables,
        });
      };

      const onSubmit =
        // eslint-disable-next-line react/prop-types
        editFormProperties.formHooks.handleSubmit(onClickUpdateRow);

      // eslint-disable-next-line react/prop-types
      const currentRowIndex = row.index;
      const clickEdit = () => {
        // eslint-disable-next-line react/prop-types
        editFormProperties.setEditableRowIndex(currentRowIndex);
        // eslint-disable-next-line react/prop-types
        editFormProperties.formHooks.formReset(
          // eslint-disable-next-line react/prop-types
          editFormProperties.formValues.reduce(
            // eslint-disable-next-line react/prop-types
            (prev, curr) => ({ ...prev, [curr]: row.values[curr] }),
            {},
          ),
        );
      };
      const clickUndo = () => {
        // eslint-disable-next-line react/prop-types
        editFormProperties.setEditableRowIndex(-1);
        // eslint-disable-next-line react/prop-types
        editFormProperties.formHooks.formReset();
      };

      const clickSave = () => {
        onSubmit();
      };
      return (
        <EditButtons
          currentRowIndex={currentRowIndex}
          // eslint-disable-next-line react/prop-types
          editableRowIndex={editFormProperties.editableRowIndex}
          // eslint-disable-next-line react/prop-types
          loading={editFormProperties.loading}
          clickEdit={clickEdit}
          clickSave={clickSave}
          clickUndo={clickUndo}
          // eslint-disable-next-line react/prop-types
          errors={editFormProperties.formHooks.errors}
        />
      );
    },
  };
  if (lastColumnId === ACTIONS_COLUMN_ID) {
    return [...columns.slice(0, columns.length - 1), editColumn, lastColumn];
  }
  return [...columns, editColumn];
}

export { addExpander, addCheckbox, addEdit };
