import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import TaxiAlertOutlined from '@mui/icons-material/TaxiAlertOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useCountTime } from 'custom-hooks/useCountTime';
import {
  useReadyAtStoreAlarm,
  useDeliveredAlarm,
} from 'custom-hooks/useOrderAlert';
import { hasCustomerInfo } from 'helpers/customerParser';
import dateParser from 'helpers/dateParser';
import { ORDER_STATUS_LABEL_MAPPING } from 'lib/orderStatuses';
import getPlatformLogo from 'lib/platformLogo';
import HeaderListData from 'views/commons/HeaderListData';

import OrderAddress from './OrderAddress';
import OrderDelivery from './OrderDelivery';
import OrderFilesMenu from './OrderFilesMenu';
import OrderStatus from './OrderStatus';

const columns = ({ hasProcessingTime }) => [
  {
    Header: <HeaderListData fontSize="0.8rem">#Orden</HeaderListData>,
    accessor: 'sourceOrderId',
    disableSortBy: true,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: {
          // eslint-disable-next-line react/prop-types
          wmsId,
          // eslint-disable-next-line react/prop-types
          warehouse: { name: warehouseName },
          // eslint-disable-next-line react/prop-types
          seller: { name: sellerName },
          // eslint-disable-next-line react/prop-types
        },
      },
    }) => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="caption" sx={{ color: 'inherit' }}>
          {value}
        </Typography>
        <Typography variant="caption">ID WMS: {wmsId}</Typography>
        <Typography variant="caption">{warehouseName}</Typography>
        <Typography variant="caption">{sellerName}</Typography>
      </Box>
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Plataforma</HeaderListData>,
    id: 'platform',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: {
          // eslint-disable-next-line react/prop-types
          system: { id },
        },
      },
    }) => (
      <Box
        component="img"
        sx={{
          width: 80,
          height: 'auto',
        }}
        alt={id}
        src={getPlatformLogo(id)}
      />
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Fecha ingreso</HeaderListData>,
    accessor: 'createdAt',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: {
          // eslint-disable-next-line react/prop-types
          delivery: { shippingMethod },
          // eslint-disable-next-line react/prop-types
          status,
        },
      },
    }) => {
      const { formattedDistance: processingTime, minutesDifference } =
        useCountTime(value);

      const readyAtStoreAlarm = useReadyAtStoreAlarm({
        createdAt: value,
        minutesDifference,
        status,
        shippingMethod,
      });
      const deliveredAlarm = useDeliveredAlarm({
        createdAt: value,
        minutesDifference,
        shippingMethod,
      });
      const color = readyAtStoreAlarm?.color || deliveredAlarm?.color;
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography noWrap variant="caption">
            {dateParser(value)}
          </Typography>
          {hasProcessingTime && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                noWrap
                variant="caption"
                color={(theme) => theme.palette[color]?.main}
              >
                <b>{processingTime}</b>
              </Typography>
              {readyAtStoreAlarm.show && (
                <Tooltip title="Preparación atrasada">
                  <ProductionQuantityLimitsOutlinedIcon
                    color={readyAtStoreAlarm.color}
                  />
                </Tooltip>
              )}
              {deliveredAlarm.show && (
                <Tooltip title="Entrega atrasada">
                  <TaxiAlertOutlined color={deliveredAlarm.color} />
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
      );
    },
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Tipo</HeaderListData>,
    accessor: 'businessType',
    disableSortBy: true,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: {
          // eslint-disable-next-line react/prop-types
          businessType,
        },
      },
    }) => (
      <Box sx={{ display: 'flex', alignContent: 'center', gap: 1 }}>
        <Chip label={businessType} size="small" />
      </Box>
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Estado</HeaderListData>,
    accessor: 'status',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { value },
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { id, statusOptions },
      },
    }) => (
      <OrderStatus
        id={id}
        value={value}
        label={ORDER_STATUS_LABEL_MAPPING[value]}
        options={statusOptions}
      />
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Cliente</HeaderListData>,
    accessor: 'customer',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) =>
      hasCustomerInfo(value) ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption">
            {/* eslint-disable-next-line react/prop-types */}
            {value.firstName} {value.lastName}
          </Typography>
          <Typography variant="caption">
            {/* eslint-disable-next-line react/prop-types */}
            {value.phone}
          </Typography>
        </Box>
      ) : (
        <Typography variant="caption">Desconocido</Typography>
      ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Delivery</HeaderListData>,
    accessor: 'delivery',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: { shippingMethod, systemName, status },
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: {
          // eslint-disable-next-line react/prop-types
          id: orderId,
          // eslint-disable-next-line react/prop-types
          system: { id: systemId },
          // eslint-disable-next-line react/prop-types
          seller: { id: sellerId },
          // eslint-disable-next-line react/prop-types
          status: orderStatus,
        },
      },
    }) => (
      <OrderDelivery
        id={orderId}
        shippingMethod={shippingMethod}
        systemName={systemName}
        systemId={systemId}
        status={status}
        sellerId={sellerId}
        orderStatus={orderStatus}
      />
    ),
  },
  {
    id: 'address',
    Header: (
      <HeaderListData align="center" fontSize="0.8rem">
        Dirección
      </HeaderListData>
    ),
    accessor: 'delivery',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value: {
        // eslint-disable-next-line react/prop-types
        dropOffAddress,
      },
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: {
          // eslint-disable-next-line react/prop-types
          id,
          // eslint-disable-next-line react/prop-types
          system: { id: platformName },
          // eslint-disable-next-line react/prop-types
          sourceOrderId,
          // eslint-disable-next-line react/prop-types
          status: { value: statusValue },
        },
      },
    }) => (
      <OrderAddress
        id={id}
        systemName={platformName}
        sourceOrderId={sourceOrderId}
        dropOffAddress={dropOffAddress}
        status={statusValue}
      />
    ),
  },
  {
    id: 'actions',
    disableSortBy: true,
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original } }) => {
      const {
        // eslint-disable-next-line react/prop-types
        sourceOrderId,
        // eslint-disable-next-line react/prop-types
        invoiceURL,
        // eslint-disable-next-line react/prop-types
        pickingList,
        // eslint-disable-next-line react/prop-types
        delivery: { shippingLabelURL },
        // eslint-disable-next-line react/prop-types
        packages: { labelURL },
        // eslint-disable-next-line react/prop-types
        id,
        // eslint-disable-next-line react/prop-types
        attachedFiles,
      } = original;
      return (
        <OrderFilesMenu
          id={id}
          sourceOrderId={sourceOrderId}
          // eslint-disable-next-line react/prop-types
          hasAttachedFiles={!!attachedFiles?.length}
          invoiceURL={invoiceURL}
          packagesLabelURL={labelURL}
          // eslint-disable-next-line react/prop-types
          hasPickingList={!!pickingList?.length}
          shippingLabelURL={shippingLabelURL}
        />
      );
    },
  },
];
export default columns;
