import { useState, useEffect, useMemo } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  orderIntegrationFilterVar,
  seenTourListOrdersIntegrationsVar,
} from 'apollo/reactiveVariables';
import PageTitle from 'components/layout/PageTitle';
import { showTourGuideListOrdersIntegrationsLocalStorage } from 'components/onboarding/helpers';
import TourGuide from 'components/onboarding/TourGuide';
import SkeletonListData from 'components/skeletons/ListData';
import GET_FILTERS from 'gql/filters';
import GET_SELLERS from 'gql/sellers';
import HeaderListData from 'views/commons/HeaderListData';
import ListServerData from 'views/commons/ListServerData';
import OrdersIntegrations from 'views/integrations/orders/list/components/OrdersIntegrations';
import OrderIntegrationFilters from 'views/integrations/orders/list/components/OrdersIntegrationsFilters';

function OrdersIntegrationsList() {
  const orderIntegrationFilter = useReactiveVar(orderIntegrationFilterVar);
  const seenTourListOrdersIntegrations = useReactiveVar(
    seenTourListOrdersIntegrationsVar,
  );

  const TOUR_STEPS = [
    {
      title: 'Integraciones de órdenes',
      target: '#pageTitle',
      content:
        'Puedes visualizar todos los vendedores y su estado. También encontrarás las integraciones de órdenes de los vendedores',
    },
    {
      title: 'Filtros',
      target: '#filterBox',
      content: 'Puedes filtrar por vendedor ',
    },
  ];
  const [run, setRun] = useState(!seenTourListOrdersIntegrations);
  const [filterOptions, setFilterOptions] = useState({
    sellers: [],
  });

  const [filters, setFilters] = useState(orderIntegrationFilter);
  const [queryVariables, setQueryVariables] = useState(orderIntegrationFilter);

  useEffect(() => {
    orderIntegrationFilterVar(filters);
  }, [filters]);

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        sellerFilterOptions: { sellers: _sellers },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers });
      setFilters((prev) => ({
        ...prev,
        // eslint-disable-next-line no-underscore-dangle
        id: orderIntegrationFilter.id,
      }));
    },
  });
  useEffect(() => {
    setQueryVariables({
      ...filters,
      // eslint-disable-next-line no-underscore-dangle
      id: filters.id.length > 0 ? filters.id : undefined,
    });
  }, [filters]);
  const renderSubComponent = (row) => {
    const {
      original: { id },
    } = row;
    return <OrdersIntegrations sellerId={id} />;
  };

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Vendedor</HeaderListData>,
        accessor: 'name',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography>{value}</Typography>
          </Box>
        ),
      },
      {
        Header: <HeaderListData>Estado</HeaderListData>,
        accessor: 'enabled',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {value ? (
              <Avatar
                sx={{ bgcolor: 'success.main', height: '30px', width: '30px' }}
              >
                <DoneOutlinedIcon />
              </Avatar>
            ) : (
              <Avatar
                sx={{ bgcolor: 'error.main', height: '30px', width: '30px' }}
              >
                <CloseOutlinedIcon />
              </Avatar>
            )}
          </Box>
        ),
      },
    ],
    [],
  );

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({
      ...prev,
      globalFilter,
    }));
  };
  if (loading)
    return (
      <>
        <PageTitle>Integraciones de órdenes</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <PageTitle
        handleClick={() => {
          setRun(true);
        }}
      >
        Integraciones de órdenes
      </PageTitle>
      <TourGuide
        steps={TOUR_STEPS}
        run={run}
        setRun={setRun}
        finishTour={showTourGuideListOrdersIntegrationsLocalStorage}
      />
      <ListServerData
        query={GET_SELLERS}
        filters={{
          components: (
            <OrderIntegrationFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
            />
          ),
          helperTextSearchBox: 'Buscar por nombre de la integración',
        }}
        renderSubComponent={renderSubComponent}
        queryVariables={{
          filters: {
            ...queryVariables,
          },
        }}
        dataKey="getSellers"
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={orderIntegrationFilter.globalFilter}
        columns={columns}
      />
    </>
  );
}

export default OrdersIntegrationsList;
