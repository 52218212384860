import { parse, isValid } from 'date-fns';
import * as Papa from 'papaparse';

const DATE_FORMAT = 'dd/MM/yyyy';

const cellParser = {
  sku: (cellValue) => cellValue,
  quantity: (cellValue) => Number(cellValue),
  unitCost: (cellValue) => Number(cellValue),
  costCurrency: (cellValue) => cellValue,
  lotNumber: (cellValue) => cellValue,
  expirationDate: (cellValue) => {
    const parsedDate = parse(cellValue, DATE_FORMAT, new Date());
    return isValid(parsedDate) ? parsedDate : undefined;
  },
};

const readCSV = (files, onComplete) => {
  const file = files[0];
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    transform: (cellValue, headerName) => cellParser[headerName](cellValue),
    complete: (result) => {
      const tableData = result.data;
      onComplete(tableData);
    },
  });
};

export default readCSV;
