import {
  DEFAULT_LINEAL_UNIT_MEASURE,
  DEFAULT_WEIGHT_UNIT_MEASURE,
} from 'lib/productUnitMeasures';

import { PACK_TYPE_DYNAMIC_VALUE } from '../../lib/packTypes';

function translateUpdatedPack(data) {
  const { id, productName, measures, packType, items } = data;
  const components = items.map((item) => {
    const { id: productId, quantity } = item;
    const quantityInt = parseInt(quantity, 10);
    return {
      productId,
      ...(packType === PACK_TYPE_DYNAMIC_VALUE
        ? { maxComponentQuantity: quantityInt }
        : { quantity: quantityInt }),
    };
  });
  return {
    id,
    name: productName,
    measures: {
      width: {
        value: Number(measures.width.value),
        unit: measures.width.unit || DEFAULT_LINEAL_UNIT_MEASURE,
      },
      height: {
        value: Number(measures.height.value),
        unit: measures.height.unit || DEFAULT_LINEAL_UNIT_MEASURE,
      },
      length: {
        value: Number(measures.length.value),
        unit: measures.length.unit || DEFAULT_LINEAL_UNIT_MEASURE,
      },
      weight: {
        value: Number(measures.weight.value),
        unit: measures.weight.unit || DEFAULT_WEIGHT_UNIT_MEASURE,
      },
    },
    components,
  };
}
export default translateUpdatedPack;
