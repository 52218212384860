import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';

import MainBox from '../../../../components/containers/MainBox';
import Error from '../../../../components/Error';
import PageTitle from '../../../../components/layout/PageTitle';
import SkeletonListData from '../../../../components/skeletons/ListData';
import PAPER_BOX_PROPS from '../../../../components/table/styles';
import Table from '../../../../components/table/Table';
import { GET_CORRECTION } from '../../../../gql/catalogs';
import CorrectionSummary from '../../components/CatalogShowSummary';
import tableColumns from './components/columns';

const MIN_PAGE_SIZE = 1;

function Correction() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_CORRECTION, {
    variables: { id },
  });

  const correction = useMemo(() => {
    const {
      reference,
      seller,
      warehouse,
      items = [],
      comment,
      createdAt,
      status,
      createdBy,
    } = data?.correction || {};
    return {
      reference,
      seller,
      warehouse,
      items,
      createdAt,
      comment,
      status,
      createdBy,
    };
  }, [data]);

  const columns = useMemo(tableColumns, []);
  if (error) {
    return (
      <MainBox>
        <Error />
      </MainBox>
    );
  }
  if (loading)
    return (
      <>
        <PageTitle>Corrección de inventario</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <PageTitle width="auto">
          Corrección de inventario {correction.reference}
          <IconButton component={Link} to="pdf">
            <PictureAsPdfOutlinedIcon />
          </IconButton>
        </PageTitle>
      </Box>
      <CorrectionSummary catalogInstance={correction} />
      <Table
        data={correction.items}
        columns={columns}
        pagination={{
          hasPagination: false,
          customPageSize: correction.items.length || MIN_PAGE_SIZE,
        }}
        hasStickyHeader={false}
        hasSearchBox={false}
        paperBoxProps={PAPER_BOX_PROPS}
        loading={loading}
        error={error}
      />
    </Box>
  );
}
export default Correction;
