import { View } from '@react-pdf/renderer';

function Separator() {
  return (
    <View
      style={{
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 8,
        marginBottom: 8,
      }}
    />
  );
}

export default Separator;
