import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import CustomDialog from 'views/commons/CustomDialog';

const TUTORIAL_TITLE = '¿Cómo cargar múltiples productos?';

function TutorialContent() {
  return (
    <Box>
      <Typography>
        Descarga el excel de ejemplo y complétalo con la información de los
        productos que vas crear. El archivo tiene las siguientes columnas:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="sku (campo obligatorio)"
            secondary="Sku del producto: Es importante para que puedas identificar cada producto. Si no lo agregas, el sistema no podrá crearlo."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="name (campo obligatorio)"
            secondary="Nombre del producto."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="storageType (campo obligatorio)"
            secondary="Tipo de almacenaje: Puedes elegir entre: DRY, REFRIGERATED o FROZEN."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="deliveryStorageType (campo obligatorio)"
            secondary="Tipo de almacenaje del envío: Puedes elegir entre: DRY, REFRIGERATED o FROZEN."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="cmLength (campo obligatorio)"
            secondary="Largo: El valor en centímetros del largo del producto."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="cmHeight (campo obligatorio)"
            secondary="Alto: El valor en centímetros del alto del producto."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="cmWidth (campo obligatorio)"
            secondary="Ancho: El valor en centímetros del ancho del producto."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="kgWeight (campo obligatorio)"
            secondary="Peso: El valor en kilogramos del peso del producto."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="barcode"
            secondary="EAN: El número de barra o EAN."
          />
        </ListItem>
      </List>
    </Box>
  );
}
function TutorialDialog({ showDialog, setShowDialog }) {
  return (
    <CustomDialog
      dialogContent={<TutorialContent />}
      dialogTitle={{
        title: TUTORIAL_TITLE,
      }}
      dialogActions={
        <Button
          variant="outlined"
          size="small"
          onClick={() => setShowDialog(false)}
        >
          OK
        </Button>
      }
      openDialog={showDialog}
      handleCloseDialog={setShowDialog}
    />
  );
}

TutorialDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
export default TutorialDialog;
