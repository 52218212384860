import { useState } from 'react';

import { useMutation } from '@apollo/client';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { ERRORS, LABEL_ERRORS } from '../../../../../apollo/serverErrors';
import { SYNC_PRODUCT_AT_WAREHOUSE } from '../../../../../gql/catalogs';
import CustomDialog from '../../../../commons/CustomDialog';

// TODO: refactor
function InventorySync({ productAtWarehouseData }) {
  const [showSyncDialog, setShowSyncDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState({});
  const [syncInventoryData, setSyncInventoryData] = useState({});
  const handleClickSyncInventory = () => {
    setSyncInventoryData(productAtWarehouseData);
    setDialogTitle({ title: 'Confirmar sincronización de inventario' });
    setDialogMessage(
      <Typography>
        ¿Estás seguro que quieres sincronizar el producto{' '}
        <strong>{productAtWarehouseData.product.name}</strong> con sku{' '}
        <strong>{productAtWarehouseData.product.sku}</strong> en la tienda{' '}
        <strong>{productAtWarehouseData.warehouse.name}</strong>?
      </Typography>,
    );
    setShowSyncDialog(true);
  };

  const [
    syncProductAtWarehouse,
    { loading: syncLoading, reset, data: syncData, error: syncError },
  ] = useMutation(SYNC_PRODUCT_AT_WAREHOUSE, {
    onError: (error) => {
      const errorKey =
        Object.keys(ERRORS).find((err) => error.message === ERRORS[err]) ||
        ERRORS.UNKNOW_ERROR_MESSAGE;
      setDialogTitle({ title: 'No se ha podido sincronizar el inventario' });
      setDialogMessage(
        <Typography>
          {`Ha ocurrido un error: ${LABEL_ERRORS[errorKey]}`}
        </Typography>,
      );
    },
    onCompleted: (data) => {
      const { syncProductAtWarehouse: updatedProductAtWarehouse } = data;
      setDialogTitle({ title: 'Inventario sincronizado correctamente' });
      setDialogMessage(
        <Typography>
          <strong>{syncInventoryData.product.name}</strong> actualizado de:{' '}
          <strong>{syncInventoryData.quantityAvailable}</strong> a cantidad
          encontrada en el Wms:{' '}
          <strong>{updatedProductAtWarehouse.quantityAvailable}</strong>{' '}
        </Typography>,
      );
    },
  });
  const syncInventory = () => {
    const variables = {
      input: {
        sellerId: productAtWarehouseData.seller.id,
        warehouseId: productAtWarehouseData.warehouse.id,
        productId: productAtWarehouseData.product.id,
      },
    };
    syncProductAtWarehouse({
      variables,
    });
  };
  return (
    <>
      <Tooltip title="Sincronizar inventario de este producto con WMS asociado">
        <Button
          endIcon={<SyncOutlinedIcon />}
          variant="outlined"
          onClick={handleClickSyncInventory}
        >
          Sincronizar
        </Button>
      </Tooltip>
      {showSyncDialog && (
        <CustomDialog
          dialogContent={dialogMessage}
          dialogTitle={dialogTitle}
          dialogActions={
            !syncData && !syncError ? (
              <Box sx={{ display: 'flex' }}>
                <Button
                  onClick={() => setShowSyncDialog(false)}
                  disabled={syncLoading}
                >
                  Cancelar
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => syncInventory(syncInventoryData)}
                  endIcon={syncLoading && <CircularProgress size={15} />}
                  disabled={syncLoading}
                  sx={{ m: 1 }}
                >
                  Confirmar
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setShowSyncDialog(false);
                    reset();
                  }}
                  sx={{ m: 1 }}
                >
                  Continuar
                </Button>
              </Box>
            )
          }
          openDialog={showSyncDialog}
          handleCloseDialog={(bool) => {
            setShowSyncDialog(bool);
            reset();
          }}
        />
      )}
    </>
  );
}
InventorySync.propTypes = {
  productAtWarehouseData: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string,
      sku: PropTypes.string,
      name: PropTypes.string,
    }),
    warehouse: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    seller: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
export default InventorySync;
