import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/Table';
import { formatCurrencyValue } from '../../../../lib/currency';

function OrderItems({
  items,
  currency,
  shippingValue,
  discountsValue,
  totalValue,
  valuesWithTaxes,
}) {
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Descripción</Typography>,
        accessor: 'description',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'quantity',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Precio unitario</Typography>,
        accessor: 'unitPrice',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { unitDiscount },
          },
        }) => {
          if (typeof unitDiscount === 'number') {
            return (
              <Typography>
                {formatCurrencyValue({ currency, value: value - unitDiscount })}
              </Typography>
            );
          }
          return value;
        },
      },
      {
        Header: <Typography>Monto total</Typography>,
        accessor: 'totalValue',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <Typography>{formatCurrencyValue({ currency, value })}</Typography>
        ),
      },
    ],
    [currency],
  );

  const itemsTotalValue = useMemo(
    () => items.reduce((acc, item) => acc + item.totalValue, 0),
    [items],
  );

  const itemsWithSummary = [
    ...items,
    {
      unitPrice: 'Subtotal items',
      totalValue: itemsTotalValue,
      valueSummary: true,
    },
    { unitPrice: 'Despacho', totalValue: shippingValue, valueSummary: true },
    { unitPrice: 'Descuentos', totalValue: discountsValue, valueSummary: true },
    {
      unitPrice: (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>Total</Typography>
          <Typography variant="caption">
            {valuesWithTaxes ? 'Impuestos incluidos' : 'Sin impuestos'}
          </Typography>
        </Box>
      ),
      totalValue,
      valueSummary: true,
    },
  ];
  return (
    <Box sx={{ margin: 1 }}>
      <Table
        data={itemsWithSummary}
        columns={columns}
        pagination={false}
        sxPaper={{ boxShadow: 'none' }}
        hasSearchBox={false}
        getCellProps={(cell) => {
          if (cell.row.original.valueSummary) {
            return {
              style: { borderBottom: 'none', paddingBottom: 2 },
            };
          }
          return {};
        }}
      />
    </Box>
  );
}

OrderItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      description: PropTypes.string,
      quantity: PropTypes.number,
      unitDiscount: PropTypes.number,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  shippingValue: PropTypes.number.isRequired,
  discountsValue: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
  valuesWithTaxes: PropTypes.bool.isRequired,
};

export default OrderItems;
