import { gql } from '@apollo/client';

const GET_ORDERS_INTEGRATIONS = gql`
  query getOrdersIntegrations(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: OrderIntegrationFilters!
  ) {
    getOrdersIntegrations(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
    ) {
      nodes {
        id
        sellerId
        systemName
        enabled
      }
      total
      pageCount
    }
  }
`;
export default GET_ORDERS_INTEGRATIONS;
