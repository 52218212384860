import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import Field from './Field';
import SectionContainer from './SectionContainer';

function CustomerSection({ customer }) {
  const { email, phone, firstName, lastName } = customer;
  const fullName = `${firstName} ${lastName}`;
  return (
    <SectionContainer title="Información del cliente">
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Field label="Nombre cliente" value={fullName} />
        <Field label="Telefono" value={phone} />
        <Field label="Correo electrónico" value={email} />
      </Box>
    </SectionContainer>
  );
}

CustomerSection.propTypes = {
  customer: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default CustomerSection;
