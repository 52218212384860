const TEMPLATE_EXCEL_HEADERS = {
  A1: 'sku',
  B1: 'name',
  C1: 'storageType',
  D1: 'deliveryStorageType',
  E1: 'cmLength',
  F1: 'cmHeight',
  G1: 'cmWidth',
  H1: 'kgWeight',
  I1: 'barcode',
};
export default TEMPLATE_EXCEL_HEADERS;
