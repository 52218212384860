import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import DocumentField from './DocumentField';
import SectionContainer from './SectionContainer';

function DocumentsSection({ attachedFiles, invoiceURL, pickingListURL }) {
  return (
    <SectionContainer title="Documentos">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 6,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            marginBottom: 4,
          }}
        >
          <Typography variant="h6">Documentos del sistema</Typography>
          <Box
            sx={{
              display: 'flex',
              paddingY: 3,
              flexDirection: 'column',
              rowGap: 2,
            }}
          >
            <DocumentField
              document={{
                fileType: '-',
                title: 'Boleta',
                url: invoiceURL,
              }}
              key="invoiceFile"
            />
            <DocumentField
              document={{
                fileType: 'pdf',
                title: 'Comanda',
                url: pickingListURL,
              }}
              key="pickingListFile"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            paddingY: 3,
            flexDirection: 'column',
            rowGap: 2,
          }}
        >
          <Typography variant="h6">Documentos adjuntos del cliente</Typography>
          {attachedFiles.length === 0 ? (
            <Box>
              <Typography
                sx={{ color: '#757575', textAlign: 'center', paddingY: 5 }}
              >
                No tienes documentos adjuntos...
              </Typography>
            </Box>
          ) : (
            attachedFiles.map((file) => {
              const { document, key } = file;
              return <DocumentField document={document} key={key} />;
            })
          )}
        </Box>
      </Box>
    </SectionContainer>
  );
}

DocumentsSection.propTypes = {
  attachedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      document: PropTypes.shape({
        fileType: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
      }),
      key: PropTypes.string,
    }),
  ),
  invoiceURL: PropTypes.string,
  pickingListURL: PropTypes.string,
};

DocumentsSection.defaultProps = {
  attachedFiles: [],
  invoiceURL: null,
  pickingListURL: null,
};

export default DocumentsSection;
