import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import keyMirror from 'keymirror';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-hook-form';

import SubmitButton from 'components/buttons/SubmitButton';
import AlertDialog from 'components/dialogs/AlertDialog';
import PageTitle from 'components/layout/PageTitle';
import RadioButtonsGroup from 'components/radio-buttons/RadioButtonsGroup';
import FormTextField from 'components/text-fields/FormTextField';
import useGetFilters from 'custom-hooks/useGetFilters';
import { POSITIVE_NUMBER, REQUIRED_FIELD } from 'lib/form';
import {
  DEFAULT_LINEAL_UNIT_MEASURE,
  DEFAULT_WEIGHT_UNIT_MEASURE,
} from 'lib/productUnitMeasures';
import STORAGE_OPTIONS from 'lib/storageOptions';

import useDialog from '../hooks/useDialog';
import useError from '../hooks/useError';
import useSubmit from '../hooks/useSubmit';
import BarcodeForm from './BarcodeForm';

const INPUT_TYPE_NUMBER = 'number';
const FORM_TYPE_VALUES = keyMirror({
  edit: null,
  new: null,
});

const MEASURES_MIN = 0;
const UPDATE_PRODUCT_BUTTON_NAME = 'ACTUALIZAR PRODUCTO';
const CREATE_PRODUCT_BUTTON_NAME = 'CREAR PRODUCTO';

function ProductForm({ formType, defaultValues }) {
  const { filterOptions: formOptions } = useGetFilters({
    filterOptionsKey: 'catalogFilterOptions',
  });

  const { register, control, handleSubmit, reset } = useForm({
    defaultValues,
  });
  const { dirtyFields } = useFormState({ control });

  const resetForm = () => {
    reset(defaultValues);
  };

  const { dialog } = useDialog({ formType, resetForm });
  const { loading, error, onSubmit } = useSubmit({
    setDialogContent: dialog.setContent,
    setVisibleDialog: dialog.setOpen,
    formType,
  });

  const errorMessage = useError(error);

  const disabledSubmit = useMemo(() => {
    if (formType === FORM_TYPE_VALUES.new) {
      return loading;
    }
    return !Object.keys(dirtyFields).length || loading;
  });
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          padding: 2,
          flexDirection: 'column',
        }}
      >
        {formType === FORM_TYPE_VALUES.new ? (
          <PageTitle>Crear producto</PageTitle>
        ) : (
          <PageTitle>Editar producto</PageTitle>
        )}
        <Typography variant="subtitle1">1. Vendedor</Typography>
        <FormTextField
          fieldName="sellerId"
          label="Vendedor"
          control={control}
          register={() => register('sellerId', { required: REQUIRED_FIELD })}
          select={{
            select: true,
            options: formOptions.sellers,
          }}
          disabled={formType === FORM_TYPE_VALUES.edit}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="subtitle1">2. Sobre el producto</Typography>
          <FormTextField
            fieldName="productName"
            control={control}
            label="Nombre del producto"
            sx={{ width: '100%' }}
            register={() =>
              register('productName', { required: REQUIRED_FIELD })
            }
          />
          <FormTextField
            fieldName="sku"
            control={control}
            label="SKU"
            sx={{ width: '100%' }}
            register={() => register('sku', { required: REQUIRED_FIELD })}
            disabled={formType === FORM_TYPE_VALUES.edit}
          />

          <BarcodeForm control={control} />
          <RadioButtonsGroup
            label="Almacenaje"
            options={STORAGE_OPTIONS.map((storageOption) => ({
              value: storageOption.value,
              label: storageOption.label,
            }))}
            control={control}
            fieldName="storageType"
          />
          <RadioButtonsGroup
            label="Tipo de envío"
            options={STORAGE_OPTIONS.map((storageOption) => ({
              value: storageOption.value,
              label: storageOption.label,
            }))}
            control={control}
            fieldName="deliveryStorageType"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="subtitle1">3. Medidas</Typography>
          <Typography variant="subtitle3">Medidas lineales</Typography>
          <FormTextField
            fieldName="measures.height.value"
            control={control}
            label="Alto"
            sx={{ width: '100%' }}
            register={() =>
              register('measures.height.value', {
                required: REQUIRED_FIELD,
                valueAsNumber: true,
                min: {
                  value: MEASURES_MIN,
                  message: POSITIVE_NUMBER,
                },
              })
            }
            type={INPUT_TYPE_NUMBER}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {defaultValues.measures?.height?.unit ||
                    DEFAULT_LINEAL_UNIT_MEASURE}
                </InputAdornment>
              ),
            }}
          />
          <FormTextField
            fieldName="measures.width.value"
            control={control}
            label="Ancho"
            sx={{ width: '100%' }}
            register={() =>
              register('measures.width.value', {
                required: REQUIRED_FIELD,
                valueAsNumber: true,
                min: {
                  value: MEASURES_MIN,
                  message: POSITIVE_NUMBER,
                },
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {defaultValues.measures?.width?.unit ||
                    DEFAULT_LINEAL_UNIT_MEASURE}
                </InputAdornment>
              ),
            }}
            type={INPUT_TYPE_NUMBER}
          />
          <FormTextField
            fieldName="measures.length.value"
            control={control}
            label="Largo"
            sx={{ width: '100%' }}
            register={() =>
              register('measures.length.value', {
                required: REQUIRED_FIELD,
                valueAsNumber: true,
                min: {
                  value: MEASURES_MIN,
                  message: POSITIVE_NUMBER,
                },
              })
            }
            type={INPUT_TYPE_NUMBER}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {defaultValues.measures?.length?.unit ||
                    DEFAULT_LINEAL_UNIT_MEASURE}
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="subtitle3">Medidas de peso</Typography>
          <FormTextField
            fieldName="measures.weight.value"
            control={control}
            label="Peso"
            sx={{ width: '100%' }}
            register={() =>
              register('measures.weight.value', {
                required: REQUIRED_FIELD,
                valueAsNumber: true,
                min: {
                  value: MEASURES_MIN,
                  message: POSITIVE_NUMBER,
                },
              })
            }
            type={INPUT_TYPE_NUMBER}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {defaultValues.measures?.weight?.unit ||
                    DEFAULT_WEIGHT_UNIT_MEASURE}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {!!error && (
              <Typography color="error" align="center" gutterBottom>
                {errorMessage}
              </Typography>
            )}
            <SubmitButton
              size="large"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={disabledSubmit}
              loading={loading}
              label={
                formType === FORM_TYPE_VALUES.edit
                  ? UPDATE_PRODUCT_BUTTON_NAME
                  : CREATE_PRODUCT_BUTTON_NAME
              }
            />
          </Box>
        </Box>
        <AlertDialog
          open={dialog.open}
          handleClose={dialog.handleClose}
          dialogTitle={dialog.title}
          dialogContent={dialog.content}
        />
      </Box>
    </Container>
  );
}

ProductForm.propTypes = {
  formType: PropTypes.oneOf(['edit', 'new']).isRequired,
  defaultValues: PropTypes.shape({
    sellerId: PropTypes.string,
    productName: PropTypes.string,
    sku: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    measures: PropTypes.shape({
      width: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        unit: PropTypes.string,
      }),
      height: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        unit: PropTypes.string,
      }),
      length: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        unit: PropTypes.string,
      }),
      weight: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        unit: PropTypes.string,
      }),
    }),
    barcode: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
  }),
};
ProductForm.defaultProps = {
  defaultValues: {
    sellerId: '',
    productName: '',
    sku: '',
    measures: {
      width: { value: '', unit: DEFAULT_LINEAL_UNIT_MEASURE },
      height: { value: '', unit: DEFAULT_LINEAL_UNIT_MEASURE },
      length: { value: '', unit: DEFAULT_LINEAL_UNIT_MEASURE },
      weight: { value: '', unit: DEFAULT_WEIGHT_UNIT_MEASURE },
    },

    barcode: [],
  },
};
export default ProductForm;
