import { useState } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const useSnackbarAlert = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');

  const displaySnackbarAlert = ({ message, type }) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
  };
  const handleSnackbarAlertClose = () => {
    setSnackbarOpen(false);
  };
  function SnackbarAlert() {
    return (
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleSnackbarAlertClose}
        message={snackbarMessage}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarAlertClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  }

  return {
    SnackbarAlert,
    displaySnackbarAlert,
    handleSnackbarAlertClose,
  };
};
export default useSnackbarAlert;
