import { useMemo } from 'react';

import PropTypes from 'prop-types';

import PAPER_BOX_PROPS from '../../../../../components/table/styles';
import Table from '../../../../../components/table/Table';
import getColumns from './getColumns';

function ReplenishmentTable({
  control,
  register,
  setValue,
  replenishmentData,
  remove,
}) {
  const columns = useMemo(
    () =>
      getColumns({ control, register, setValue, remove, replenishmentData }),
    [replenishmentData],
  );
  return (
    <Table
      data={replenishmentData}
      columns={columns}
      pagination={{
        hasPagination: false,
        customPageSize: replenishmentData.length,
      }}
      hasStickyHeader={false}
      hasSearchBox={false}
      paperBoxProps={PAPER_BOX_PROPS}
    />
  );
}

ReplenishmentTable.propTypes = {
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  replenishmentData: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      quantity: PropTypes.number,
      unitCost: PropTypes.number,
      costCurrency: PropTypes.string,
      lotNumber: PropTypes.string,
      expirationDate: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
  remove: PropTypes.func.isRequired,
};

export default ReplenishmentTable;
