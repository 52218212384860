import * as Papa from 'papaparse';

const cellParser = {
  sku: (cellValue) => cellValue,
  quantity: (cellValue) => Number(cellValue),
  unitPrice: (cellValue) => Number(cellValue),
};

const readCSVSkus = (files, onComplete) => {
  const file = files[0];
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    transform: (cellValue, headerName) => cellParser[headerName](cellValue),
    complete: (result) => {
      const tableData = result.data;
      onComplete(tableData);
    },
  });
};

export default readCSVSkus;
