import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PaperBox from 'components/containers/PaperBox';

function GoogleMapsMapSkeleton() {
  return (
    <PaperBox
      variant="outlined"
      elevation={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '300px',
        alignItems: 'center',
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LocationOnOutlinedIcon fontSize="large" color="action" />
        <Typography variant="caption">
          Ingresa una dirección para poder ver el mapa
        </Typography>
      </Box>
    </PaperBox>
  );
}
export default GoogleMapsMapSkeleton;
