import { useState, useEffect } from 'react';

import { useQuery, useLazyQuery, gql } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import cache from '../../../apollo/cache';
import FormTextField from '../../../components/text-fields/FormTextField';
import GET_FILTERS from '../../../gql/filters';
import GET_RENTS from '../../../gql/warehouses';
import { REQUIRED_FIELD } from '../../../lib/form';

function SellerWarehouseInput({
  title,
  register,
  control,
  setValue,
  resetField,
  keyFilterOptions,
}) {
  const [formOptions, setFormOptions] = useState({
    sellers: [],
    warehouses: [],
  });

  useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        [keyFilterOptions]: { sellers: sellerOptions },
      },
    }) => {
      const sellers = sellerOptions.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFormOptions((prevState) => ({ ...prevState, sellers }));
    },
  });

  const [getRents, { loading: rentsLoading, client }] = useLazyQuery(
    GET_RENTS,
    {
      onCompleted: ({ rents: rentedWarehousesOptions }) => {
        const rentedWarehouses = rentedWarehousesOptions.map(
          ({ id, name, computedAddress }) => ({
            value: id,
            label: name,
            computedAddress,
          }),
        );
        setFormOptions((prevState) => ({
          ...prevState,
          warehouses: rentedWarehouses,
        }));
      },
    },
  );

  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });

  const watchWarehouseId = useWatch({
    name: 'warehouseId',
    control,
  });

  useEffect(() => {
    if (watchSellerId) {
      getRents({ variables: { sellerId: watchSellerId } });
    }
    resetField('warehouseId');
    resetField('shippingAddressLine1');
  }, [watchSellerId]);

  useEffect(() => {
    if (watchWarehouseId) {
      const cacheWarehouseIdObject = cache.identify({
        __typename: 'Warehouse',
        id: watchWarehouseId,
      });
      const warehouse = client.readFragment({
        id: cacheWarehouseIdObject,
        fragment: gql`
          fragment WarehouseObject on Warehouse {
            id
            name
            computedAddress {
              label
            }
          }
        `,
      });
      setValue('warehouseAddress', warehouse.computedAddress.label);
    }
  }, [watchWarehouseId]);

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="subtitle1">{title}</Typography>
        {rentsLoading && <CircularProgress size={20} color="secondary" />}
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormTextField
          fieldName="sellerId"
          label="Vendedor"
          control={control}
          register={() => register('sellerId', { required: REQUIRED_FIELD })}
          select={{ select: true, options: formOptions.sellers }}
          sx={{ marginBottom: 2, marginY: 0 }}
        />
        <FormTextField
          fieldName="warehouseId"
          label="Tienda"
          control={control}
          register={() => register('warehouseId', { required: REQUIRED_FIELD })}
          select={{ select: true, options: formOptions.warehouses }}
          disabled={!watchSellerId || rentsLoading}
          sx={{ marginBottom: 2, marginY: 0 }}
        />
      </Box>
    </Box>
  );
}
SellerWarehouseInput.propTypes = {
  register: PropTypes.func.isRequired,
  resetField: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  keyFilterOptions: PropTypes.string.isRequired,
};
export default SellerWarehouseInput;
