import { useState } from 'react';

import readExcel from 'lib/files/readExcel';
import validateExcelHeaders from 'lib/files/validateExcelHeaders';

import TEMPLATE_EXCEL_HEADERS from '../lib/templateExcelHeaders';
import translateProducts from '../lib/translateProducts';
import productsSchema from '../lib/validateProductsSchema';

function useUploadProducts({ setValue, createBatchProducts, sellerId }) {
  const [products, setProducts] = useState([]);
  const [validationErrors, setValidationErrors] = useState();
  const onCompleteReadFile = ({ data: tableData, success }) => {
    if (success) {
      const fileProducts = translateProducts({
        tableData,
        sellerId,
      });
      const currentProducts = Object.values(fileProducts);
      setProducts(Object.values(fileProducts));
      productsSchema
        .validate(Object.values(fileProducts), {
          abortEarly: false,
        })
        .then(() => {
          setValue(
            'isExcelValid',
            {
              uploaded: true,
              headers: true,
              data: Boolean(currentProducts.length),
            },
            { shouldValidate: true },
          );
        })
        .catch(function onError(err) {
          const dataErrors = err.errors.map((validationError) => ({
            ...validationError,
            product: Object.values(fileProducts)[validationError.index],
          }));
          setValidationErrors(dataErrors);
        });
      setValue(
        'isExcelValid',
        {
          uploaded: true,
          headers: true,
          data: false,
        },
        { shouldValidate: true },
      );
    } else {
      setProducts([]);
      setValue(
        'isExcelValid',
        { uploaded: true, headers: false, data: true },
        { shouldValidate: true },
      );
    }
  };
  const validateWorksheet = (worksheet) => {
    const isValid = validateExcelHeaders({
      worksheet,
      templateHeaders: TEMPLATE_EXCEL_HEADERS,
    });
    return isValid;
  };
  const onSubmit = () => {
    createBatchProducts({
      variables: {
        input: products.map((product) => ({
          ...product,
          sellerId,
        })),
      },
    });
  };
  const removeProduct = (inputSku) => {
    setProducts((prev) => prev.filter(({ sku }) => sku !== inputSku));
  };
  const fileParser = (files) =>
    readExcel(files, validateWorksheet, onCompleteReadFile);

  return { products, onSubmit, fileParser, removeProduct, validationErrors };
}
export default useUploadProducts;
