import { useMemo } from 'react';

import PropTypes from 'prop-types';

import SimpleSelect from '../../../../../components/selects/SimpleSelect';

const SELECT_WIDTH = 180;

function TransferFilters({ sellersOptions, setFilters, filters }) {
  const hasOneSeller = useMemo(
    () => sellersOptions.length === 1,
    [sellersOptions],
  );
  if (hasOneSeller) {
    return <div />;
  }
  return (
    <SimpleSelect
      id="filter-seller-select"
      variant="outlined"
      helperText="Filtrar por vendedor"
      selectLabel="Vendedor"
      options={sellersOptions}
      eventName="sellerId"
      controlled={{
        controlledValue: filters.sellerId,
        setControlledValue: setFilters,
      }}
      sx={{ width: SELECT_WIDTH }}
    />
  );
}
TransferFilters.propTypes = {
  filters: PropTypes.shape({
    sellerId: PropTypes.string,
    warehouseId: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  sellersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

TransferFilters.defaultProps = {
  sellersOptions: [],
};
export default TransferFilters;
