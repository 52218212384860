import { makeVar } from '@apollo/client';
import { subWeeks, endOfToday, startOfDay } from 'date-fns';

const globalLoaderVar = makeVar(false);
const isLoggedInVar = makeVar(false);
const isAuthorizedVar = makeVar(false);

const orderFilterVar = makeVar({
  warehouseId: [],
  sellerId: [],
  sinceDate: startOfDay(subWeeks(new Date(), 1)).toString(),
  toDate: endOfToday().toString(),
});
const inventoryFilterVar = makeVar({
  warehouseId: '',
  sellerId: '',
});

const orderIntegrationFilterVar = makeVar({
  id: [],
});

const productFilterVar = makeVar({
  sellerId: '',
});

const packFilterVar = makeVar({
  sellerId: '',
});

const correctionFilterVar = makeVar({
  warehouseId: '',
  sellerId: '',
});

const replenishmentFilterVar = makeVar({
  sellerId: '',
  warehouseId: '',
});

const seenTourListInventoryVar = makeVar(
  localStorage.getItem('seenTourListInventory') === 'true',
);
const seenTourListOrdersVar = makeVar(
  localStorage.getItem('seenTourListOrders') === 'true',
);
const seenTourListOrdersIntegrationsVar = makeVar(
  localStorage.getItem('seenTourListOrdersIntegrations') === 'true',
);
const seenTourListProductsVar = makeVar(
  localStorage.getItem('seenTourListProducts') === 'true',
);

export {
  globalLoaderVar,
  isLoggedInVar,
  isAuthorizedVar,
  orderFilterVar,
  inventoryFilterVar,
  packFilterVar,
  productFilterVar,
  orderIntegrationFilterVar,
  correctionFilterVar,
  replenishmentFilterVar,
  seenTourListOrdersIntegrationsVar,
  seenTourListProductsVar,
  seenTourListInventoryVar,
  seenTourListOrdersVar,
};
