import { gql } from '@apollo/client';

const GET_ORDERS = gql`
  query getOrders(
    $offset: Int
    $limit: Int
    $sort: String
    $filters: OrderFilters
  ) {
    getOrders(offset: $offset, limit: $limit, sort: $sort, filters: $filters) {
      nodes {
        id
        wmsId
        createdAt
        sourceOrderId
        status {
          value
        }
        statusOptions {
          value
        }
        createdAt
        invoiceURL
        warehouse {
          id
          name
        }
        seller {
          id
          name
        }
        system {
          id
        }
        delivery {
          id
          status
          systemName
          deliveryMethod
          shippingMethod
          shippingLabelURL
          dropOffAddress {
            id
            sourceAddress
            computedAddress {
              id
              street
              addressNumber
              complement
              municipality
              subRegion
              region
              country
              postalCode
              label
              isVerified
              isVerifiedBy
              isVerifiedAt
              isPartialMatch
            }
          }
        }
        customer {
          firstName
          lastName
          phone
          email
        }
        items {
          id
          sku
          description
          quantity
          unitPrice
          unitDiscount
          totalValue
        }
        pickingList {
          productId
          quantity
        }
        packages {
          labelURL
        }
        orderCurrency
        shippingValue
        discountsValue
        totalValue
        valuesWithTaxes
        attachedFiles {
          key
          title
        }
        businessType
      }
      total
      pageCount
    }
  }
`;
const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: ID!) {
    getOrderById(id: $id) {
      id
      comment
      wmsId
      statusHistory {
        event
        createdAt
        author
      }
      sourceOrderId
      system {
        id
      }
      seller {
        id
        name
        enabled
      }
      warehouse {
        id
        name
      }
      status {
        value
      }
      createdAt
      parsedCreatedAt @client
      invoiceURL
      issuesData {
        createdBy
        createdAt
        comment
        issueType
      }
      delivery {
        id
        systemName
        deliveryMethod
        shippingMethod
        shippingLabelURL
        trackingURL
        dropOffAddress {
          id
          computedAddress {
            label
            isVerified
            isVerifiedBy
            isVerifiedAt
            isPartialMatch
            street
            addressNumber
            complement
            municipality
            subRegion
            region
            country
            postalCode
          }
          sourceAddress
        }
        status
      }
      customer {
        email
        phone
        firstName
        lastName
      }
      statusOptions {
        value
      }
      items {
        id
        sku
        description
        quantity
        unitPrice
        unitDiscount
        totalValue
      }
      pickingList {
        productId
        product {
          id
          sku
          name
        }
        quantity
      }
      totalValue
      shippingValue
      discountsValue
      orderCurrency
      valuesWithTaxes
      attachedFiles {
        key
        bucket
        title
        presignedGetFileUrl {
          presignedUrl
          key
        }
      }
      packages {
        labelURL
      }
      businessType
      hasEmptyPickingList @client
    }
  }
`;
const GET_ORDER_ATTACHMENTS = gql`
  query getOrderById($id: ID!) {
    getOrderById(id: $id) {
      attachedFiles {
        title
        presignedGetFileUrl {
          key
          presignedUrl
        }
      }
    }
  }
`;
const UPDATE_ORDER_STATUS = gql`
  mutation updateOrderStatus($id: ID!, $status: EnumStatus!) {
    updateOrderStatus(id: $id, status: $status) {
      id
      status {
        value
      }
      statusOptions {
        value
      }
    }
  }
`;
const UPDATE_ORDER_ADDRESS = gql`
  mutation updateOrderAddress(
    $id: ID!
    $computedAddress: ComputedAddressInput!
  ) {
    updateOrderAddress(id: $id, computedAddress: $computedAddress) {
      id
      delivery {
        id
        dropOffAddress {
          id
          computedAddress {
            id
            street
            addressNumber
            complement
            municipality
            subRegion
            region
            country
            postalCode
            label
            isVerified
            isVerifiedBy
            isVerifiedAt
            isPartialMatch
          }
        }
      }
    }
  }
`;

const CREATE_ORDER = gql`
  mutation Mutation($input: CreateOrderInput!) {
    createOrder(input: $input) {
      sourceOrderId
    }
  }
`;
const CREATE_BATCH_ORDERS = gql`
  mutation Mutation($input: [CreateBatchOrderInput!]!) {
    createBatchOrders(input: $input) {
      success
    }
  }
`;
const LINK_ORDER_DELIVERY = gql`
  mutation linkOrderDelivery($input: LinkOrderDeliveryInput!) {
    linkOrderDelivery(input: $input) {
      success
    }
  }
`;

const UPDATE_ORDER_ISSUES = gql`
  mutation Mutation(
    $updateOrderIssuesId: ID!
    $issueType: EnumIssueType!
    $comment: String
  ) {
    updateOrderIssues(
      id: $updateOrderIssuesId
      issueType: $issueType
      comment: $comment
    ) {
      id
      issuesData {
        createdBy
        createdAt
        comment
        issueType
      }
    }
  }
`;

const GENERATE_ORDER_LABELS = gql`
  mutation generateOrderLabels($id: ID!, $quantity: Int!) {
    generateOrderLabels(id: $id, quantity: $quantity) {
      success
    }
  }
`;

export default GET_ORDERS;
export {
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ISSUES,
  CREATE_ORDER,
  CREATE_BATCH_ORDERS,
  GET_ORDER_BY_ID,
  GET_ORDER_ATTACHMENTS,
  LINK_ORDER_DELIVERY,
  GENERATE_ORDER_LABELS,
};
