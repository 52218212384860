import { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from '../../../../components/table/Table';
import Tag from '../../../../components/Tag';
import dateParser from '../../../../helpers/dateParser';
import { ORDER_ISSUES_LABEL_MAPPING } from '../../../../lib/orderIssues';
import AddIssueDialog from './AddIssueDialog';
import SectionContainer from './SectionContainer';

function IssuesSection({ issues, orderId, sourceOrderId }) {
  const [openDialog, setOpenDialog] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: <Typography>Fecha creación</Typography>,
        accessor: 'createdAt',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{dateParser(value)}</Typography>,
      },
      {
        Header: <Typography>Incidencia</Typography>,
        accessor: 'issueType',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const {
            // eslint-disable-next-line react/prop-types
            original: {
              // eslint-disable-next-line react/prop-types
              issueType,
            },
          } = row;
          // eslint-disable-next-line react/prop-types
          return (
            <Tag
              // eslint-disable-next-line react/prop-types
              label={ORDER_ISSUES_LABEL_MAPPING[issueType]}
              // eslint-disable-next-line react/prop-types
            />
          );
        },
      },
      {
        Header: <Typography>Reportado por</Typography>,
        accessor: 'createdBy',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Observaciones</Typography>,
        accessor: 'comment',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
        style: { width: '350px' },
      },
    ],
    [],
  );
  return (
    <>
      <AddIssueDialog
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        orderId={orderId}
        sourceOrderId={sourceOrderId}
      />
      <SectionContainer title="Incidencias">
        <Box
          sx={{
            display: 'flex',
            marginBottom: 4,
            flexDirection: 'column',
          }}
        >
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              marginBottom: 4,
              borderRadius: '4px',
              border: '0.5px solid #EB5D29',
              color: '#EB5D29',
              width: 'max-content',
              alignSelf: 'end',
            }}
          >
            Agregar incidencia
          </Button>
          <Table
            data={issues}
            columns={columns}
            pagination={{
              hasPaginator: false,
            }}
            hasStickyHeader={false}
            hasSearchBox={false}
          />
        </Box>
      </SectionContainer>
    </>
  );
}

IssuesSection.propTypes = {
  issues: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      createdAt: PropTypes.string,
      createdBy: PropTypes.string,
      issueType: PropTypes.string,
    }),
  ).isRequired,
  orderId: PropTypes.string.isRequired,
  sourceOrderId: PropTypes.string.isRequired,
};

export default IssuesSection;
