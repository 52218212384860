import {
  isAfter,
  isBefore,
  add,
  getYear,
  getDate,
  getMonth,
  getHours,
} from 'date-fns';

import { ORDER_STATUSES } from '../lib/orderStatuses';
import { SHIPPING_METHODS } from '../lib/shippingMethods';

const keyMirror = require('keymirror');

const EXPRESS_READY_AT_STORE_ERROR_MINUTES_THRESHOLD = 45;
const EXPRESS_READY_AT_STORE_INTIME_MINUTES_THRESHOLD = 30;
const EXPRESS_DELIVERED_ERROR_MINUTES_THRESHOLD = 120;
const EXPRESS_DELIVERED_INTIME_MINUTES_THRESHOLD = 105;
const SAME_DAY_HOUR_THRESHOLD = 12;
const SAME_DAY_READY_AT_STORE_INTIME_TIME_THRESHOLD = { hours: 11, minutes: 0 };
const SAME_DAY_READY_AT_STORE_ERROR_TIME_THRESHOLD = { hours: 11, minutes: 30 };
const SAME_DAY_DELIVERED_INTIME_TIME_THRESHOLD = { hours: 21, minutes: 30 };
const SAME_DAY_DELIVERED_ERROR_TIME_THRESHOLD = { hours: 23, minutes: 0 };

const LEVELS = keyMirror({ inTime: null, warning: null, error: null });
const COLOR_LEVELS = keyMirror({
  warning: null,
  error: null,
});

function getOrderLevelExpress({ minutesDifference }) {
  switch (true) {
    case minutesDifference > EXPRESS_READY_AT_STORE_ERROR_MINUTES_THRESHOLD:
      return LEVELS.error;
    case minutesDifference < EXPRESS_READY_AT_STORE_INTIME_MINUTES_THRESHOLD:
      return LEVELS.inTime;
    default:
      return LEVELS.warning;
  }
}
function getDeliveryDaySameDay({ createdAt }) {
  const createdAtDate = new Date(createdAt);
  const [createdAtHours, createdAtDay, createdAtMonth, createdAtYear] = [
    getHours(createdAtDate),
    getDate(createdAtDate),
    getMonth(createdAtDate),
    getYear(createdAtDate),
  ];
  const orderDate = new Date(createdAtYear, createdAtMonth, createdAtDay, 0, 0);
  if (createdAtHours >= SAME_DAY_HOUR_THRESHOLD) {
    return add(orderDate, { days: 1 });
  }
  return orderDate;
}

function getOrderLevelExpressReadyAtStore({ status, minutesDifference }) {
  const statuses = Object.keys(ORDER_STATUSES);
  const isReadyAtStore =
    statuses.indexOf(status.value) >=
    statuses.indexOf(ORDER_STATUSES.readyAtStore);
  if (!isReadyAtStore) {
    const level = getOrderLevelExpress({ minutesDifference });
    return { show: level !== LEVELS.inTime, color: COLOR_LEVELS[level] };
  }
  return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
}
function getOrderLevelSameDayReadyAtStore({ status, createdAt }) {
  const statuses = Object.keys(ORDER_STATUSES);
  const isReadyAtStore =
    statuses.indexOf(status.value) >=
    statuses.indexOf(ORDER_STATUSES.readyAtStore);
  const deliverySameDay = getDeliveryDaySameDay({ createdAt });
  if (!isReadyAtStore) {
    switch (true) {
      case isAfter(
        new Date(),
        add(deliverySameDay, SAME_DAY_READY_AT_STORE_ERROR_TIME_THRESHOLD),
      ):
        return { color: COLOR_LEVELS[LEVELS.error], show: true };
      case isBefore(
        new Date(),
        add(deliverySameDay, SAME_DAY_READY_AT_STORE_INTIME_TIME_THRESHOLD),
      ):
        return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
      default:
        return { color: COLOR_LEVELS[LEVELS.warning], show: true };
    }
  }
  return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
}
function getOrderLevelStandardReadyAtStore() {
  return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
}
function getOrderLevelExpressDelivered({ minutesDifference }) {
  switch (true) {
    case minutesDifference > EXPRESS_DELIVERED_ERROR_MINUTES_THRESHOLD:
      return { color: COLOR_LEVELS[LEVELS.error], show: true };
    case minutesDifference < EXPRESS_DELIVERED_INTIME_MINUTES_THRESHOLD:
      return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
    default:
      return { color: COLOR_LEVELS[LEVELS.warning], show: true };
  }
}
function getOrderLevelSameDayDelivered({ createdAt }) {
  const deliverySameDay = getDeliveryDaySameDay({ createdAt });
  switch (true) {
    case isAfter(
      new Date(),
      add(deliverySameDay, SAME_DAY_DELIVERED_ERROR_TIME_THRESHOLD),
    ):
      return { color: COLOR_LEVELS[LEVELS.error], show: true };
    case isBefore(
      new Date(),
      add(deliverySameDay, SAME_DAY_DELIVERED_INTIME_TIME_THRESHOLD),
    ):
      return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
    default:
      return { color: COLOR_LEVELS[LEVELS.warning], show: true };
  }
}
function getOrderLevelStandardDelivered() {
  return { color: COLOR_LEVELS[LEVELS.inTime], show: false };
}

const GET_LEVEL_MAPPER_READY_AT_STORE = {
  [SHIPPING_METHODS.EXPRESS]: getOrderLevelExpressReadyAtStore,
  [SHIPPING_METHODS.FLASH]: getOrderLevelExpressReadyAtStore,
  [SHIPPING_METHODS.SAME_DAY]: getOrderLevelSameDayReadyAtStore,
  [SHIPPING_METHODS.STANDARD]: getOrderLevelStandardReadyAtStore,
};
const GET_LEVEL_MAPPER_DELIVERED = {
  [SHIPPING_METHODS.EXPRESS]: getOrderLevelExpressDelivered,
  [SHIPPING_METHODS.FLASH]: getOrderLevelExpressDelivered,
  [SHIPPING_METHODS.SAME_DAY]: getOrderLevelSameDayDelivered,
  [SHIPPING_METHODS.STANDARD]: getOrderLevelStandardDelivered,
};

const useReadyAtStoreAlarm = ({
  createdAt,
  minutesDifference,
  status,
  shippingMethod,
}) =>
  GET_LEVEL_MAPPER_READY_AT_STORE[shippingMethod] &&
  GET_LEVEL_MAPPER_READY_AT_STORE[shippingMethod]({
    createdAt,
    minutesDifference,
    status,
  });
const useDeliveredAlarm = ({ createdAt, minutesDifference, shippingMethod }) =>
  GET_LEVEL_MAPPER_DELIVERED[shippingMethod] &&
  GET_LEVEL_MAPPER_DELIVERED[shippingMethod]({
    createdAt,
    minutesDifference,
  });

export { useReadyAtStoreAlarm, useDeliveredAlarm };
