import ArticleIcon from '@mui/icons-material/Article';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function DocumentField({ document }) {
  const { title, url, fileType } = document;

  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '0.5px solid #E7E7E7',
        padding: '12px',
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            padding: '4px',
            backgroundColor: '#F4F3F3',
            borderRadius: '4px',
            marginRight: 1,
            alignSelf: 'start',
          }}
        >
          <ArticleIcon sx={{ color: '#DBDBDB' }} />
        </Box>

        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <Box sx={{ flexDirection: 'column', flexGrow: 1 }}>
            <Typography>{title}</Typography>
            <Typography sx={{ color: '#B8B7B7', fontSize: '12px' }}>
              {fileType.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        {url ? (
          <Link
            sx={{
              border: ' 0.5px solid #EB5D29',
              borderRadius: '6px',
              color: '#EB5D29',
              paddingX: 2,
              paddingY: 1,
            }}
            target="_blank"
            href={url}
            underline="none"
          >
            Ver
          </Link>
        ) : (
          <Box sx={{ justifySelf: 'end' }}>
            <Typography sx={{ color: '#B8B7B7', fontSize: '12px' }}>
              Este documento no está disponible.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

DocumentField.propTypes = {
  document: PropTypes.shape({
    fileType: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
};

export default DocumentField;
