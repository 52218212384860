const keyMirror = require('keymirror');

const { SHIPPING_METHODS } = require('./shippingMethods');

const INTEGRATED_CARRIER_APPS = keyMirror({
  JUSTO: null,
  'GOOD-MEAL': null,
  RAPPI: null,
  'RAPPI-ALIADOS': null,
  'UBER-EATS': null,
  CORNERSHOP: null,
});

function isInDeliveryApps({ systemName, shippingMethod }) {
  if (SHIPPING_METHODS.PICK_UP === shippingMethod) {
    return false;
  }
  return Object.keys(INTEGRATED_CARRIER_APPS).includes(systemName);
}
export { INTEGRATED_CARRIER_APPS, isInDeliveryApps };
