import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import LinkButton from './LinkButton';

const SIDEBAR_LIST = [
  { text: 'Órdenes', icon: <ListAltOutlinedIcon />, to: '/orders' },
  {
    text: 'Productos',
    icon: <CategoryOutlinedIcon />,
    to: '/catalogs/products',
  },
  {
    text: 'Inventario',
    icon: <StorefrontOutlinedIcon />,
    to: '/catalogs/inventories',
  },
  {
    text: 'Abastecimientos',
    icon: <ArchiveOutlinedIcon />,
    to: '/catalogs/replenishments',
  },
  {
    text: 'Transferencias',
    icon: <CompareArrowsOutlinedIcon />,
    to: '/catalogs/transfers',
  },
  {
    text: 'Correcciones',
    icon: <RuleIcon />,
    to: '/catalogs/corrections',
  },
  {
    text: 'Integraciones',
    icon: <IntegrationInstructionsOutlinedIcon />,
    to: '/integrations/orders',
  },
];
function SidebarContent({ open }) {
  return (
    <Box>
      <Divider />
      <List>
        {SIDEBAR_LIST.map(({ text, icon, to }) => (
          <Tooltip key={text} arrow placement="right" title={open ? '' : text}>
            <span>
              <LinkButton
                text={text}
                icon={icon}
                to={to}
                open={open}
                disabled
              />
            </span>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
}

SidebarContent.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default SidebarContent;
