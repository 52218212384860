import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

function MenuButton(props) {
  return (
    <Tooltip title="Acciones">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <IconButton {...props}>
        <MoreVertOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

export default MenuButton;
