import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Link from '../../../../../components/links/Link';
import HeaderListData from '../../../../commons/HeaderListData';

const columns = () => [
  {
    Header: <HeaderListData>ID referencia</HeaderListData>,
    accessor: 'reference',
    disableSortBy: true,
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      row: {
        // eslint-disable-next-line react/prop-types
        original: { comment },
      },
    }) => (
      <Tooltip title={comment}>
        <span>
          <Typography>{value}</Typography>
        </span>
      </Tooltip>
    ),
  },
  {
    Header: <HeaderListData>Fecha creación</HeaderListData>,
    accessor: 'createdAt',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => <Typography>{value}</Typography>,
  },
  {
    id: 'link',
    accessor: 'id',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Link href={`/catalogs/corrections/${value}`} />,
  },
];
export default columns;
