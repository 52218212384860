import {
  DEFAULT_LINEAL_UNIT_MEASURE,
  DEFAULT_WEIGHT_UNIT_MEASURE,
} from 'lib/productUnitMeasures';

function translateNewProduct(data) {
  const { sellerId, productName, sku, storageType, measures, barcode } = data;
  return {
    sellerId,
    name: productName,
    storageType,
    sku,
    ...(barcode && { barcode: barcode.map(({ value }) => value) }),
    measures: {
      width: {
        value: Number(measures.width.value),
        unit: DEFAULT_LINEAL_UNIT_MEASURE,
      },
      height: {
        value: Number(measures.height.value),
        unit: DEFAULT_LINEAL_UNIT_MEASURE,
      },
      length: {
        value: Number(measures.length.value),
        unit: DEFAULT_LINEAL_UNIT_MEASURE,
      },
      weight: {
        value: Number(measures.weight.value),
        unit: DEFAULT_WEIGHT_UNIT_MEASURE,
      },
    },
  };
}
export default translateNewProduct;
