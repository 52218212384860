import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { REQUIRED_FIELD } from '../../../../../lib/form';

// TODO: add custom component to render textfield with use form

function NewOrderCustomer({ register, errors }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography variant="subtitle1">4. Cliente</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          size="small"
          label="Nombre"
          required
          error={Boolean(errors.firstName)}
          sx={{ flexGrow: 1 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('firstName', { required: REQUIRED_FIELD })}
          helperText={errors.firstName && errors.firstName.message}
        />
        <TextField
          size="small"
          label="Apellido"
          required
          error={Boolean(errors.lastName)}
          sx={{ flexGrow: 1 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('lastName', { required: REQUIRED_FIELD })}
          helperText={errors.lastName && errors.lastName.message}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          size="small"
          label="Correo"
          required
          error={Boolean(errors.email)}
          sx={{ flexGrow: 1 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email', { required: REQUIRED_FIELD })}
          helperText={errors.email && errors.email.message}
        />
        <TextField
          size="small"
          label="Celular"
          required
          error={Boolean(errors.phone)}
          sx={{ flexGrow: 1 }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('phone', {
            required: REQUIRED_FIELD,
          })}
          helperText={errors.phone && errors.phone.message}
        />
      </Box>
    </Box>
  );
}
NewOrderCustomer.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.shape({ message: PropTypes.string }),
    lastName: PropTypes.shape({ message: PropTypes.string }),
    email: PropTypes.shape({ message: PropTypes.string }),
    phone: PropTypes.shape({ message: PropTypes.string }),
  }).isRequired,
};
export default NewOrderCustomer;
