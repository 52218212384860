import { useState, useMemo, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Error from '../../../../components/Error';
import TableBox from '../../../../components/table/TableBox';
import FormTextField from '../../../../components/text-fields/FormTextField';
import GET_DELIVERY_INTEGRATIONS from '../../../../gql/delivery-integrations';
import { LINK_ORDER_DELIVERY } from '../../../../gql/orders';
import LINKABLE_DELIVERY_INTEGRATIONS from '../../../../lib/deliveryIntegrations';
import CustomDialog from '../../../commons/CustomDialog';

const REQUIRED_VALUE_MESSAGE = 'Campo requerido';
function OrderDeliveryDialog({ sellerId, orderId, openDialog, setOpenDialog }) {
  const [deliveryIntegrations, setDeliveryIntegrations] = useState([]);

  const [
    getDeliveryIntegrations,
    { loading: deliveryIntegrationsLoading, error: deliveryIntegrationsError },
  ] = useLazyQuery(GET_DELIVERY_INTEGRATIONS);

  useEffect(() => {
    if (openDialog) {
      getDeliveryIntegrations({
        variables: {
          filters: { sellerId, systemName: LINKABLE_DELIVERY_INTEGRATIONS },
        },
        onCompleted: ({ deliveryIntegrations: { nodes } }) => {
          setDeliveryIntegrations(nodes);
        },
      });
    }
  }, [openDialog]);

  const {
    register,
    control,
    handleSubmit,
    formState,
    reset: formReset,
  } = useForm({
    defaultValues: {
      sourceDeliveryId: '',
      deliveryIntegration: '',
    },
  });
  const { errors } = formState;

  const [showResultDialog, setShowResultDialog] = useState('');
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogTitle, setResultDialogTitle] = useState('');
  const [
    linkOrderDelivery,
    { loading: mutationLoading, reset: mutationReset },
  ] = useMutation(LINK_ORDER_DELIVERY, {
    onError: () => {
      setResultDialogMessage(
        `No se ha podido crear la solicitud para asociar el delivery a orden.`,
      );
      setResultDialogTitle('Error al asociar delivery a orden');
      setShowResultDialog(true);

      formReset();
      mutationReset();
      setOpenDialog(false);
    },
    onCompleted: () => {
      setResultDialogMessage(
        `Se ha iniciado el proceso para asociar el delivery a la orden, esto puede tomar un tiempo`,
      );
      setResultDialogTitle('Asociación de delivery creada');
      setShowResultDialog(true);

      formReset();
      mutationReset();
      setOpenDialog(false);
    },
  });
  const onSubmit = (formData) => {
    const input = {
      id: orderId,
      integrationId: formData.deliveryIntegration,
      sourceDeliveryId: formData.sourceDeliveryId,
    };
    linkOrderDelivery({ variables: { input } });
  };

  const dialogContent = useMemo(() => {
    if (deliveryIntegrationsLoading) {
      return (
        <Box
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress sx={{ alignSelf: 'center', color: 'secondary' }} />
        </Box>
      );
    }
    if (deliveryIntegrationsError) {
      return (
        <Box
          style={{
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <TableBox>
            <Error message="No pudimos encontrar las plataformas de delivery de esta orden." />
          </TableBox>
        </Box>
      );
    }
    return (
      <DialogContent>
        <DialogContentText>
          Ingresa la plataforma y el identificador del delivery.
        </DialogContentText>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 2,
            gap: 2,
          }}
        >
          <FormTextField
            fieldName="deliveryIntegration"
            label="Plataforma de delivery"
            variant="outlined"
            required
            select={{
              select: true,
              options: deliveryIntegrations.map(
                ({ systemName: platformSystemName, id: platformId }) => ({
                  value: platformId,
                  label: platformSystemName,
                }),
              ),
            }}
            defaultValue=""
            control={control}
            register={() =>
              register('deliveryIntegration', {
                required: REQUIRED_VALUE_MESSAGE,
              })
            }
          />
          <FormTextField
            fieldName="sourceDeliveryId"
            label="Identificador delivery"
            variant="outlined"
            control={control}
            required
            register={() =>
              register('sourceDeliveryId', {
                required: REQUIRED_VALUE_MESSAGE,
              })
            }
          />
        </Box>
      </DialogContent>
    );
  }, [deliveryIntegrationsLoading, deliveryIntegrationsError, formState]);
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          formReset();
          mutationReset();
          setOpenDialog(false);
        }}
      >
        <DialogTitle>Asociar delivery a orden</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              formReset();
              mutationReset();
              setOpenDialog(false);
            }}
            disabled={mutationLoading}
          >
            Cancelar
          </Button>
          {!mutationLoading ? (
            <Button
              variant="outlined"
              size="small"
              disabled={
                Boolean(errors.integrationId) ||
                Boolean(errors.sourceDeliveryId) ||
                !!deliveryIntegrationsError ||
                !!deliveryIntegrationsLoading
              }
              onClick={handleSubmit(onSubmit)}
            >
              Enviar
            </Button>
          ) : (
            <CircularProgress color="secondary" size="2rem" />
          )}
        </DialogActions>
      </Dialog>
      {showResultDialog && (
        <CustomDialog
          dialogContent={resultDialogMessage}
          dialogTitle={{
            title: resultDialogTitle,
          }}
          dialogActions={
            <Button
              variant="outlined"
              size="small"
              onClick={() => setShowResultDialog(false)}
            >
              Aceptar
            </Button>
          }
          openDialog={showResultDialog}
          handleCloseDialog={setShowResultDialog}
        />
      )}
    </>
  );
}
OrderDeliveryDialog.propTypes = {
  orderId: PropTypes.string.isRequired,
  sellerId: PropTypes.string.isRequired,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
};

export default OrderDeliveryDialog;
