import { InMemoryCache } from '@apollo/client';

import customerParser from '../helpers/customerParser';
import dateParser from '../helpers/dateParser';
import offsetLimitPagination from './pagination';

const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      fields: {
        createdAt: {
          read(createdAt) {
            return createdAt;
          },
        },
        customer: {
          read(customer) {
            return customerParser(customer);
          },
        },
        hasEmptyPickingList: {
          read(_, { readField }) {
            const pickingList = readField('pickingList');
            return pickingList?.length === 0;
          },
        },
        parsedCreatedAt: {
          read(_, { readField }) {
            const createdAt = readField('createdAt');
            return dateParser(createdAt);
          },
        },
        packages: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    ProductComponent: {
      fields: {
        componentType: {
          read(_, { readField }) {
            const maxComponentQuantity = readField('maxComponentQuantity');
            const quantity = readField('quantity');

            if (!quantity && !maxComponentQuantity) return '-';

            return maxComponentQuantity ? 'Dinámico' : 'Fijo';
          },
        },
      },
    },
    Product: {
      fields: {
        value: {
          read(_, { readField }) {
            return readField('id');
          },
        },
        label: {
          read(_, { readField }) {
            const name = readField('name');
            const sku = readField('sku');
            return `sku: ${sku} - ${name}`;
          },
        },
        volume: {
          read(_, { readField }) {
            const measures = readField('measures');

            if (!measures) return '-';

            const {
              length: lengthFromMeasures,
              height: heightFromMeasures,
              width: widthFromMeasures,
            } = measures;

            const length =
              !lengthFromMeasures?.unit || !lengthFromMeasures?.value
                ? '-'
                : `${lengthFromMeasures?.value} ${lengthFromMeasures?.unit} `;
            const height =
              !heightFromMeasures?.unit || !heightFromMeasures?.value
                ? '-'
                : `${heightFromMeasures?.value} ${heightFromMeasures?.unit}`;

            const width =
              !widthFromMeasures?.unit || !widthFromMeasures?.value
                ? '-'
                : `${widthFromMeasures?.value} ${widthFromMeasures?.unit}`;

            if (length === '-' && height === '-' && width === '-') {
              return '-';
            }

            return `${length} x ${width} x ${height}`;
          },
        },
        packType: {
          read(_, { readField }) {
            const components = readField('components');
            const hasMaxComponentQuantity =
              components.filter(
                (component) => component.maxComponentQuantity > 0,
              ).length > 0;

            if (hasMaxComponentQuantity) return 'Dinámico';

            return 'Fijo';
          },
        },
        weight: {
          read(_, { readField }) {
            const measures = readField('measures');

            if (!measures) return '-';
            const {
              weight: { unit: weightUnit, value: weightValue },
            } = measures;
            if (!weightValue || !weightUnit) return '-';
            return `${weightValue} ${weightUnit}`;
          },
        },
        componentsQuantity: {
          read(_, { readField }) {
            const components = readField('components');
            return components.length;
          },
        },
      },
    },
    ProductAtWarehouse: {
      fields: {
        lastUpdate: {
          read() {
            return dateParser(new Date());
          },
        },
      },
    },
    ComputedAddress: {
      fields: {
        isVerifiedAt: {
          read(isVerifiedAt) {
            return dateParser(isVerifiedAt);
          },
        },
      },
    },
    Correction: {
      fields: {
        createdAt: {
          read(createdAt) {
            return dateParser(createdAt);
          },
        },
      },
    },
    Replenishment: {
      fields: {
        createdAt: {
          read(createdAt) {
            return dateParser(createdAt);
          },
        },
      },
    },
    ReplenishmentItem: {
      fields: {
        expirationDate: {
          read(expirationDate) {
            return dateParser(expirationDate);
          },
        },
      },
    },
    Transfer: {
      fields: {
        createdAt: {
          read(createdAt) {
            return dateParser(createdAt);
          },
        },
      },
    },
    Seller: {
      keyFields: ['id', 'name'],
    },
    Query: {
      fields: {
        getOrders: offsetLimitPagination(['filters', 'sort']),
        getProductsAtWarehouses: offsetLimitPagination(['filters', 'sort']),
        getProducts: offsetLimitPagination(['filters', 'sort']),
        replenishments: offsetLimitPagination(['filters', 'sort']),
        corrections: offsetLimitPagination(['filters', 'sort']),
        getSellers: offsetLimitPagination(['filters', 'sort']),
      },
    },
  },
});

export default cache;
