import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

import MenuProps from './styles';

function MultipleSelect({
  id,
  sx,
  size,
  variant,
  selectLabel,
  helperText,
  options,
  value,
  setValue,
  eventName,
  backgroundColorInputLabel,
}) {
  const removeAllSelected = () => {
    if (eventName) {
      setValue((prev) => ({
        ...prev,
        [eventName]: [],
      }));
      return;
    }
    setValue([]);
  };
  const handleChange = (event) => {
    if (eventName) {
      setValue((prev) => ({
        ...prev,
        [eventName]: event.target.value,
      }));
      return;
    }
    setValue(event.target.value);
  };

  return (
    <FormControl sx={sx} size={size} variant={variant}>
      {selectLabel && (
        <InputLabel
          sx={{
            backgroundColor: backgroundColorInputLabel,
            padding: '0px 5px 0px 5px',
          }}
        >
          {selectLabel}
        </InputLabel>
      )}
      <Select
        color="secondary"
        labelId={`label-select-${id}`}
        id={id}
        multiple
        value={options.length ? value : ''}
        onChange={handleChange}
        displayEmpty
        input={
          <OutlinedInput
            endAdornment={
              <IconButton
                onClick={removeAllSelected}
                size="small"
                sx={{ position: 'absolute', right: '30px', padding: 0 }}
              >
                <CloseRoundedIcon sx={{ fontSize: 'large' }} />
              </IconButton>
            }
          />
        }
        renderValue={(selected) => {
          const joinedValues =
            selected &&
            selected.map(
              (sel) => options.find((opt) => opt.value === sel)?.label,
            );

          return (selected.length === options.length && options.length !== 1) ||
            !selected.length
            ? 'Todos'
            : joinedValues;
        }}
        MenuProps={MenuProps}
      >
        {options.map(({ value: optionValue, label }) => (
          <MenuItem
            key={optionValue}
            value={optionValue}
            sx={{ paddingLeft: '10px' }}
          >
            <Checkbox
              checked={value && value.indexOf(optionValue) > -1}
              sx={{ padding: '0px 10px 0px 0px' }}
              color="secondary"
            />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
MultipleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  selectLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setValue: PropTypes.func.isRequired,
  size: PropTypes.string,
  eventName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  backgroundColorInputLabel: PropTypes.string,
};

MultipleSelect.defaultProps = {
  selectLabel: '',
  helperText: '',
  variant: 'outlined',
  size: 'small',
  eventName: '',
  sx: {},
  backgroundColorInputLabel: grey[50],
};
export default MultipleSelect;
