import { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { globalLoaderVar } from '../../apollo/reactiveVariables';
import GET_FILTERS from '../../gql/filters';

function WrapperView({ children }) {
  const { loading } = useQuery(GET_FILTERS);
  useEffect(() => {
    globalLoaderVar(loading);
  }, [loading]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        flexWrap: 'wrap',
        p: 2,
        display: 'flex',
        mt: 8,
      }}
    >
      {children}
    </Box>
  );
}

WrapperView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WrapperView;
