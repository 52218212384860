const keyMirror = require('keymirror');

const DELIVERYRY_STATUSES = keyMirror({
  created: null,
  deliveryRequested: null,
  deliveryConfirmed: null,
  outForDelivery: null,
  delivered: null,
  canceled: null,
  error: null,
  skipped: null,
});

export default DELIVERYRY_STATUSES;
