import { useCallback, useState, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import FORM_TYPES from '../lib/formTypes';

const DIALOG_EDIT_PACK_TITLE = 'Edición de pack';
const DIALOG_NEW_PACK_TITLE = 'Creación de pack';
const DIALOG_TITLE = {
  [FORM_TYPES.new]: DIALOG_NEW_PACK_TITLE,
  [FORM_TYPES.edit]: DIALOG_EDIT_PACK_TITLE,
};

function useDialog({ formType, resetForm = () => {} }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const title = useMemo(() => DIALOG_TITLE[formType], []);
  const handleClose = useCallback(() => {
    setOpen(false);
    if (formType === FORM_TYPES.new) {
      navigate('/catalogs/packs');
    } else {
      resetForm();
    }
  }, [resetForm]);
  return { dialog: { open, setOpen, title, content, setContent, handleClose } };
}
export default useDialog;
