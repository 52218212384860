import { useState, useEffect } from 'react';

import { formatDistanceToNow, differenceInMinutes } from 'date-fns';
import { es } from 'date-fns/locale';

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;

const useCountTime = (startTime) => {
  const [formattedDistance, setFormattedDistance] = useState(
    formatDistanceToNow(new Date(startTime), {
      locale: es,
      addSuffix: true,
    }),
  );
  const [minutesDifference, setMinutesDifference] = useState(
    differenceInMinutes(new Date(), new Date(startTime)),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedDistance(
        formatDistanceToNow(new Date(startTime), {
          locale: es,
          addSuffix: true,
        }),
      );
      setMinutesDifference(
        differenceInMinutes(new Date(), new Date(startTime)),
      );
    }, ONE_MINUTE_IN_MILLISECONDS);

    return () => clearInterval(interval);
  }, []);

  return { formattedDistance, minutesDifference };
};
// eslint-disable-next-line import/prefer-default-export
export { useCountTime };
