import { defer } from 'react-router-dom';

import client from 'apollo/client';
import IS_LOGGED_IN from 'gql/auth';

async function isAuthenticatedLoader() {
  const isAuthenticatedPromise = client.query({
    query: IS_LOGGED_IN,
  });
  return defer({
    isAuthenticated: isAuthenticatedPromise,
  });
}
export default isAuthenticatedLoader;
