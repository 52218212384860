import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  summaryView: {
    flexDirection: 'columns',
    alignItems: 'flex-start',
    textAlign: 'center',
    padding: 10,
    paddingTop: 2,
    paddingBottom: 2,
  },
  rowView: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'center',
    marginLeft: 4,
    marginTop: 2,
  },
});

export default styles;
