import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import client from '../../apollo/client';

function PageTitle({ children, handleClick, hasUpdateButton, width }) {
  return (
    <Box
      sx={{
        mb: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width,
      }}
    >
      <Box id="pageTitle" sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{children}</Typography>
        {handleClick && (
          <Tooltip title="Ver tutorial aquí">
            <span>
              <IconButton onClick={handleClick} color="secondary">
                <HelpOutlineOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
      {hasUpdateButton && (
        <Button
          endIcon={<ReplayOutlinedIcon />}
          variant="outlined"
          color="secondary"
          onClick={async () => client.resetStore()}
        >
          Actualizar información
        </Button>
      )}
    </Box>
  );
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasUpdateButton: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
PageTitle.defaultProps = {
  handleClick: false,
  hasUpdateButton: false,
  width: '100%',
};
export default PageTitle;
