import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

function CustomDrawer({ drawerKey, open, onClose, title, width, children }) {
  return (
    <Drawer
      key={drawerKey}
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width,
          display: 'flex',
          padding: 2,
          gap: 3,
          overflowX: 'hidden',
          overflowY: 'auto',
          height: '-webkit-fill-available',
        },
      }}
    >
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {title}
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        {children}
      </>
    </Drawer>
  );
}
CustomDrawer.propTypes = {
  drawerKey: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  width: PropTypes.number,
};

CustomDrawer.defaultProps = {
  title: <div />,
  width: 400,
};

export default CustomDrawer;
