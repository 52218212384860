import { useState, useRef } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const NOT_FOUND = 'No encontrado';
function AddressComponentContainer({
  addressComponent,
  label,
  canEdit,
  keyForm,
  setValue,
}) {
  const [showEditButton, setShowEditButton] = useState(
    canEdit && addressComponent === NOT_FOUND,
  );
  const [showEditForm, setShowEditForm] = useState(false);
  const textFieldRef = useRef();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="caption">{label}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!showEditForm && (
          <Typography
            color={addressComponent === NOT_FOUND ? 'error' : 'inherit'}
          >
            {addressComponent}
          </Typography>
        )}
        <>
          {showEditButton && (
            <Tooltip title="Editar">
              <IconButton
                id="editComponentAddress"
                variant="body2"
                onClick={() => {
                  setShowEditButton(false);
                  setShowEditForm(true);
                }}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {showEditForm && <TextField size="small" inputRef={textFieldRef} />}
          {showEditForm && (
            <Tooltip title="Cancelar">
              <IconButton
                id="undoComponentAddress"
                variant="body2"
                onClick={() => {
                  setShowEditButton(true);
                  setShowEditForm(false);
                }}
              >
                <UndoOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {showEditForm && (
            <Tooltip title="Guardar">
              <IconButton
                id="saveComponentAddress"
                variant="body2"
                onClick={() => {
                  setShowEditButton(true);
                  setShowEditForm(false);
                  setValue(keyForm, textFieldRef.current.value);
                }}
              >
                <SaveOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      </Box>
    </Box>
  );
}
AddressComponentContainer.propTypes = {
  addressComponent: PropTypes.string,
  label: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  keyForm: PropTypes.string,
  setValue: PropTypes.func,
};
AddressComponentContainer.defaultProps = {
  addressComponent: NOT_FOUND,
  canEdit: false,
  keyForm: '',
  setValue: () => {},
};
export default AddressComponentContainer;
