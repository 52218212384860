import { useState, useEffect, useMemo, useCallback } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import LaunchIcon from '@mui/icons-material/Launch';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

import {
  productFilterVar,
  seenTourListProductsVar,
} from 'apollo/reactiveVariables';
import MenuButton from 'components/buttons/MenuButton';
import PageTitle from 'components/layout/PageTitle';
import SimpleMenu from 'components/menus/SimpleMenu';
import { showTourGuideListProductsLocalStorage } from 'components/onboarding/helpers';
import TourGuide from 'components/onboarding/TourGuide';
import SkeletonListData from 'components/skeletons/ListData';
import EditableCell from 'components/table/EditableCells';
import Tag from 'components/Tag';
import useSnackbarAlert from 'custom-hooks/useSnackbarAlert';
import { GET_PRODUCTS, UPDATE_PRODUCT } from 'gql/catalogs';
import GET_FILTERS from 'gql/filters';
import { GENERATE_PRODUCTS_REPORT } from 'gql/reports';
import STORAGE_OPTIONS from 'lib/storageOptions';
import ProductFilters from 'views/catalogs/inventories/list/components/ProductFilters';
import HeaderListData from 'views/commons/HeaderListData';
import ListServerData from 'views/commons/ListServerData';

import CreateProductButton from './components/CreateProductButton';
import ProductPlatforms from './components/ProductPlatforms';

const {
  PRODUCT_FORM_VALUES,
  SHORT_INVALID_VALUE,
  SHORT_REQUIRED_FIELD,
} = require('lib/form');

const CHARACTER_WIDTH_STORAGE_FIELD = '17ch';
const CHARACTER_WIDTH_MEASURES_FIELD = '14ch';
const CHARACTER_WIDTH_NAME_FIELD = '25ch';
// TODO: refactor measures
function ProductList() {
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const { SnackbarAlert, displaySnackbarAlert } = useSnackbarAlert();

  const {
    register,
    getValues,
    reset: formReset,
    formState,
    handleSubmit,
  } = useForm({ reValidateMode: 'onBlur' });
  const { errors } = formState;

  // Returns variables used in mutation, receives data from the form and data from the row
  const updateVariables = (formData, productRow) => {
    const variables = {
      input: {
        id: productRow.id,
        name: formData.name,
        storageType: formData.storageType.value,
      },
    };
    if (formData.measures.weight.value) {
      variables.input.measures = {
        height: {
          value: parseFloat(formData.measures.height.value),
          unit: 'cm',
        },
        length: {
          value: parseFloat(formData.measures.length.value),
          unit: 'cm',
        },
        width: {
          value: parseFloat(formData.measures.width.value),
          unit: 'cm',
        },
        weight: {
          value: parseFloat(formData.measures.weight.value),
          unit: 'kg',
        },
      };
    }
    return variables;
  };

  const [updateRow, { loading: updateRowLoading, reset }] = useMutation(
    UPDATE_PRODUCT,
    {
      onError: () => {
        displaySnackbarAlert({
          message: `Producto no ha sido actualizado: Ha ocurrido un error`,
          type: 'error',
        });
        setEditableRowIndex(-1);
        reset();
      },
      onCompleted: () => {
        displaySnackbarAlert({
          message: 'Producto actualizado correctamente',
          type: 'success',
        });
        setEditableRowIndex(-1);
        reset();
      },
    },
  );
  const editFormData = {
    formValues: PRODUCT_FORM_VALUES,
    updateVariables,
    formHooks: { handleSubmit, formReset, getValues, errors },
    mutationFunction: updateRow,
    loading: updateRowLoading,
    editableRowIndex,
    setEditableRowIndex,
  };
  const productFilter = useReactiveVar(productFilterVar);
  const seenTourListProducts = useReactiveVar(seenTourListProductsVar);

  const TOUR_STEPS = [
    {
      title: 'Productos',
      target: '#pageTitle',
      content: 'Puedes visualizar todos los productos registrados',
    },
    {
      title: 'Filtros',
      target: '#filterBox',
      content:
        'Puedes filtrar por vendedor y buscar por sku, producto o tipo de almacenaje',
    },
    {
      title: 'Ordenar',
      target: '#tableHead',
      content:
        'Puedes ordenar por Nombre, SKU o Tipo del Almacenaje del Producto',
    },
  ];
  const [run, setRun] = useState(!seenTourListProducts);
  const [filterOptions, setFilterOptions] = useState({
    sellers: [],
  });

  const [filters, setFilters] = useState(productFilter);
  useEffect(() => {
    productFilterVar(filters);
  }, [filters]);

  const { loading } = useQuery(GET_FILTERS, {
    onCompleted: ({
      getFilters: {
        catalogFilterOptions: { sellers: _sellers },
      },
    }) => {
      const sellers = _sellers.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      setFilterOptions({ sellers });
      setFilters((prev) => ({
        ...prev,
        sellerId: productFilter.sellerId || _sellers[0]?.id,
      }));
    },
  });

  const [openExportDataForm, setOpenExportDataForm] = useState(false);
  const handleClickOpenExportDataForm = () => {
    setOpenExportDataForm(true);
  };
  const handleCloseExportDataForm = () => {
    setOpenExportDataForm(false);
  };

  const report = {
    hasReport: true,
    reportProperties: {
      mutation: GENERATE_PRODUCTS_REPORT,
      type: 'products',
      dialogFunctions: {
        open: openExportDataForm,
        setOpen: setOpenExportDataForm,
        handleClickOpen: handleClickOpenExportDataForm,
        handleClose: handleCloseExportDataForm,
      },
      filterOptions,
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Nombre</HeaderListData>,
        accessor: 'name',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>{value}</Typography>
            </Box>
          );
          const renderCellEditing = () => (
            <TextField
              size="small"
              label="Nombre"
              error={Boolean(errors.name)}
              helperText={(errors.name && errors.name.message) || ' '}
              // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
              {...register('name', {
                required: SHORT_REQUIRED_FIELD, // JS only: <p>error message</p> TS only support string
              })}
              sx={{ m: 1, width: CHARACTER_WIDTH_NAME_FIELD }}
            />
          );

          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>SKU</HeaderListData>,
        accessor: 'sku',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (defaultValue) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>{defaultValue}</Typography>
            </Box>
          );
          const renderCellEditing = () => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>{value}</Typography>
              <Typography> &nbsp;</Typography>
            </Box>
          );
          return (
            <EditableCell
              initialValue={value}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>EAN</HeaderListData>,
        accessor: 'barcode',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {/* eslint-disable-next-line react/prop-types */}
            {value.map((ean) => (
              <div key={ean}>
                <Chip label={ean} size="small" />
              </div>
            ))}
          </Box>
        ),
      },
      {
        Header: <HeaderListData>Medidas</HeaderListData>,
        accessor: 'volume',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => {
            const { length, width, height } = value || {};
            const missingAllDimensions =
              !value ||
              Object.values({ length, width, height }).filter(
                (dimension) => dimension?.value === '?',
              ).length === 3;
            return (
              <Tooltip
                title={
                  !missingAllDimensions
                    ? `Largo x Ancho x Altura.`
                    : 'No existen dimensiones inscritas'
                }
              >
                <Typography>{value}</Typography>
              </Tooltip>
            );
          };
          const renderCellEditing = () => (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                label="Largo"
                type="number"
                size="small"
                error={Boolean(errors.measures?.length)}
                helperText={
                  errors.measures?.length &&
                  errors.measures.length.value.message
                }
                // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
                {...register('measures.length.value', {
                  validate: {
                    measureMissing: (v) =>
                      !!v ===
                        Object.values(getValues('measures')).some(
                          (el) => el.value,
                        ) || SHORT_REQUIRED_FIELD,
                    positive: (v) =>
                      parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
                  },
                })}
                sx={{ m: 1, width: CHARACTER_WIDTH_MEASURES_FIELD }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 'any' },
                }}
              />
              <TextField
                label="Ancho"
                type="number"
                size="small"
                error={Boolean(errors.measures?.width)}
                helperText={
                  errors.measures?.width && errors.measures.width.value.message
                }
                // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
                {...register('measures.width.value', {
                  validate: {
                    measureMissing: (v) =>
                      !!v ===
                        Object.values(getValues('measures')).some(
                          (el) => el.value,
                        ) || SHORT_REQUIRED_FIELD,
                    positive: (v) =>
                      parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
                  },
                })}
                sx={{ m: 1, width: CHARACTER_WIDTH_MEASURES_FIELD }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 'any' },
                }}
              />
              <TextField
                label="Alto"
                type="number"
                size="small"
                error={Boolean(errors.measures?.height)}
                helperText={
                  (errors.measures?.height &&
                    errors.measures.height.value.message) ||
                  ' '
                }
                // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
                {...register('measures.height.value', {
                  validate: {
                    measureMissing: (v) =>
                      !!v ===
                        Object.values(getValues('measures')).some(
                          (el) => el.value,
                        ) || SHORT_REQUIRED_FIELD,
                    positive: (v) =>
                      parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
                  },
                })}
                sx={{ m: 1, width: CHARACTER_WIDTH_MEASURES_FIELD }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 'any' },
                }}
              />
            </Box>
          );
          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>Peso</HeaderListData>,
        id: 'weight',
        accessor: 'measures',
        disableSortBy: true,
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => {
            const existsWeight =
              // eslint-disable-next-line react/prop-types
              value?.weight?.value && value?.weight?.value !== '?';
            return (
              <Tooltip title={existsWeight ? '' : 'No existe peso inscrito'}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>
                    {existsWeight ? `${value.weight.value}` : ''}
                  </Typography>
                  <Typography variant="caption">
                    {existsWeight ? `${value.weight.unit}` : '---'}
                  </Typography>
                </Box>
              </Tooltip>
            );
          };
          const renderCellEditing = () => (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <TextField
                label="Peso"
                type="number"
                size="small"
                error={Boolean(errors.measures?.weight)}
                helperText={
                  (errors.measures?.weight &&
                    errors.measures.weight.value.message) ||
                  ' '
                }
                // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
                {...register('measures.weight.value', {
                  validate: {
                    measureMissing: (v) =>
                      !!v ===
                        Object.values(getValues('measures')).some(
                          (el) => el.value,
                        ) || SHORT_REQUIRED_FIELD,
                    positive: (v) =>
                      parseFloat(v, 10) > 0 || SHORT_INVALID_VALUE,
                  },
                })}
                sx={{ width: CHARACTER_WIDTH_MEASURES_FIELD }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                  inputProps: { min: 0, step: 'any' },
                }}
              />
            </Box>
          );
          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>Tipo de almacenaje</HeaderListData>,
        accessor: 'storageType',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value: initialValue,
          // eslint-disable-next-line react/prop-types
          row: { index },
        }) => {
          const renderCell = (value) => (
            <Tooltip title="Los tipos de almacenamiento pueden ser seco, refrigerado y congelado.">
              <span>
                <Tag label={value.label} type={value.value} />
              </span>
            </Tooltip>
          );
          const renderCellEditing = () => (
            <TextField
              label="Tipo de almacenaje"
              select
              size="small"
              helperText=" "
              // eslint-disable-next-line react/jsx-props-no-spreading, react/prop-types
              {...register('storageType')}
              sx={{ width: CHARACTER_WIDTH_STORAGE_FIELD }}
              defaultValue={STORAGE_OPTIONS.find(
                // eslint-disable-next-line react/prop-types
                (el) => el.value === initialValue.value,
              )}
            >
              {STORAGE_OPTIONS.map((option) => (
                <MenuItem
                  key={`${option.value}`}
                  value={STORAGE_OPTIONS.find(
                    (el) => el.value === option.value,
                  )}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
          return (
            <EditableCell
              initialValue={initialValue}
              rowIndex={index}
              editableRowIndex={editableRowIndex}
              renderCell={renderCell}
              renderCellEditing={renderCellEditing}
            />
          );
        },
      },
      {
        Header: <HeaderListData>Acciones</HeaderListData>,
        id: 'actions',
        accessor: 'id',

        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
        }) => {
          const [anchorEl, setAnchorEl] = useState();
          const categories = useMemo(
            () => ({
              actions: { label: 'Acciones', icon: <ModeOutlinedIcon /> },
            }),
            [],
          );
          const options = useMemo(
            () => [
              {
                id: 'editInNewTab',
                label: 'Editar producto',
                href: `/catalogs/products/${value}/edit`,
                component: 'a',
                icon: <LaunchIcon />,
                category: 'actions',
              },
            ],
            [value],
          );
          const renderButton = useCallback(
            // eslint-disable-next-line react/jsx-props-no-spreading
            (props) => <MenuButton {...props} />,
            [],
          );
          return (
            <SimpleMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              options={options}
              renderButton={renderButton}
              categories={categories}
            />
          );
        },
      },
    ],
    [formState],
  );
  const renderSubComponent = (row) => {
    const {
      original: { platformsConfig },
    } = row;
    return <ProductPlatforms platforms={platformsConfig} />;
  };
  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({
      ...prev,
      globalFilter,
    }));
  };
  if (loading)
    return (
      <>
        <PageTitle>Productos y plataformas registradas</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <PageTitle
          handleClick={() => {
            setRun(true);
          }}
        >
          Productos y plataformas registradas
        </PageTitle>
        <CreateProductButton />
      </Box>
      <TourGuide
        steps={TOUR_STEPS}
        run={run}
        setRun={setRun}
        finishTour={showTourGuideListProductsLocalStorage}
      />
      <ListServerData
        rowEdition={{ canEdit: false, editFormProperties: editFormData }}
        report={report}
        query={GET_PRODUCTS}
        renderSubComponent={renderSubComponent}
        filters={{
          components: (
            <ProductFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
            />
          ),
          helperTextSearchBox: 'Buscar por producto, sku o tipo de almacenaje',
        }}
        queryVariables={{
          filters: {
            hasComponents: false,
            ...filters,
          },
        }}
        dataKey="getProducts"
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={productFilter.globalFilter}
        columns={columns}
      />
      <SnackbarAlert />
    </>
  );
}

export default ProductList;
