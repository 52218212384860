import { useState, useEffect } from 'react';

import { useMutation, useLazyQuery, gql } from '@apollo/client';

import { GENERATE_ORDER_LABELS, GET_ORDER_BY_ID } from 'gql/orders';

const RETRIES_LIMIT_NUMBER = 3;
const POLL_INTERVAL_MILISECONDS = 1000 * 3;

function useGenerateOrderLabel({ orderId }) {
  const [mutationState, setMutationState] = useState({
    loading: false,
    error: false,
  });
  const [numberFetchLabel, setNumberFetchLabel] = useState(0);
  const [showOrderLabels, setShowOrderLabels] = useState(false);
  const [
    getOrderById,
    { data: getOrderByIdData, startPolling, stopPolling, client },
  ] = useLazyQuery(GET_ORDER_BY_ID, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      const {
        getOrderById: {
          packages: { labelURL },
        },
      } = response;
      setMutationState((prev) => ({ ...prev, error: false }));
      if (labelURL) {
        setShowOrderLabels(true);
        stopPolling();
        client.writeFragment({
          id: `Order:${orderId}`,
          fragment: gql`
            fragment PackagesLabelURL on Order {
              packages {
                labelURL
              }
            }
          `,
          data: {
            packages: { labelURL },
          },
        });
        setMutationState((prev) => ({ ...prev, loading: false }));
      }

      setNumberFetchLabel((prev) => prev + 1);
    },
    onError: () => {
      stopPolling();
      setShowOrderLabels(false);
      setMutationState((prev) => ({ ...prev, error: true, loading: false }));
    },
  });
  const [
    generateOrderLabels,
    { data: generateOrderLabelsData, reset: resetMutation },
  ] = useMutation(GENERATE_ORDER_LABELS, {
    onCompleted: (response) => {
      const {
        generateOrderLabels: { success },
      } = response;
      if (success) {
        getOrderById({ variables: { id: orderId } });
        startPolling(POLL_INTERVAL_MILISECONDS);
      } else {
        setShowOrderLabels(false);
        setMutationState((prev) => ({ ...prev, error: true, loading: false }));
      }
    },
  });
  const onSubmit = (formData) => {
    const { quantity } = formData;
    generateOrderLabels({
      variables: { id: orderId, quantity: Number(quantity) },
    });
    setMutationState(() => ({ loading: true, error: false }));
  };
  const resetGenerateOrderLabelsOperation = () => {
    resetMutation();
    setMutationState({ loading: false, error: false });
    setNumberFetchLabel(0);
    setShowOrderLabels(false);
  };
  useEffect(() => {
    if (numberFetchLabel > RETRIES_LIMIT_NUMBER) {
      stopPolling();
      setShowOrderLabels(false);
      setMutationState({ error: true, loading: false });
    }
  }, [numberFetchLabel]);

  return {
    onSubmit,
    loading: mutationState.loading,
    orderLabels: showOrderLabels
      ? getOrderByIdData?.getOrderById?.packages?.labelURL
      : undefined,
    error: mutationState.error,
    resetGenerateOrderLabelsOperation,
    generateOrderLabelsData,
  };
}
export default useGenerateOrderLabel;
