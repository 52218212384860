import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import storeLoader from 'assets/images/store-loader.gif';
import Error from 'components/Error';
import DefaultPdf from 'components/pdf/DefaultPdf';
import TableBox from 'components/table/TableBox';
import { GET_CORRECTION } from 'gql/catalogs';
import dateParser from 'helpers/dateParser';

function CorrectionsPdf() {
  const [correctionData, setCorrectionData] = useState();
  const { id } = useParams();
  const [getCorrection, { loading, error }] = useLazyQuery(GET_CORRECTION);

  useEffect(() => {
    getCorrection({
      variables: { id },
      onCompleted: ({ correction }) => {
        setCorrectionData({
          ...correction,
        });
      },
    });
  }, []);

  const tableData = useMemo(() => {
    if (!correctionData) return null;
    return {
      summaryData: {
        Vendedor: correctionData.seller?.name,
        Tienda: correctionData.warehouse?.name,
        'Fecha ingreso': dateParser(correctionData.createdAt),
        'Ingresado por': correctionData.createdBy,
        Comentario: correctionData.comment,
      },
      title: { type: 'Corrección', name: correctionData.reference },
      bodyData: {
        columnsNames: ['Sku', 'Cantidad corregida'],
        columns: ['sku', 'quantityDelta'],
        data: correctionData.items,
      },
    };
  }, [correctionData]);

  if (error) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <Error message="No pudimos encontrar la información de esta corrección." />
        </TableBox>
      </Box>
    );
  }
  if (loading || !correctionData) {
    return (
      <Box
        style={{
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          display: 'flex',
        }}
      >
        <TableBox>
          <img src={storeLoader} alt="store-loader" width="110px" />
          <Typography>Cargando información</Typography>
        </TableBox>
      </Box>
    );
  }

  return (
    <DefaultPdf
      summaryData={tableData.summaryData}
      title={tableData.title}
      bodyData={tableData.bodyData}
    />
  );
}

export default CorrectionsPdf;
