import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Table from 'components/table/Table';
import HeaderListData from 'views/commons/HeaderListData';

function ProductPlatforms({ platforms }) {
  const columns = useMemo(
    () => [
      {
        Header: <HeaderListData>Nombre</HeaderListData>,
        accessor: 'platformName',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
    ],
    [],
  );
  if (platforms.length === 0) {
    return (
      <Typography sx={{ margin: 1 }}>
        No hay plataformas registradas para este producto
      </Typography>
    );
  }
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Plataformas registradas
      </Typography>
      <Table
        data={platforms}
        columns={columns}
        pagination={false}
        sxPaper={{ boxShadow: 'none' }}
        hasSearchBox={false}
      />
    </Box>
  );
}

ProductPlatforms.propTypes = {
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default ProductPlatforms;
