import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import storeLoader from 'assets/images/store-loader.gif';
import MainBox from 'components/containers/MainBox';
import Error from 'components/Error';
import { GET_PRODUCT } from 'gql/catalogs';

import ProductForm from '../components/ProductForm';

function EditProductForm() {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_PRODUCT, {
    variables: {
      id,
    },
  });

  const defaultValues = useMemo(() => {
    if (data?.product) {
      const {
        id: productId,
        seller: { id: sellerId },
        name: productName,
        sku,
        storageType: { value: storageType },
        deliveryStorageType,
        measures,
        barcode,
      } = data.product;
      return {
        id: productId,
        sellerId,
        productName,
        sku,
        storageType,
        deliveryStorageType,
        measures,
        barcode: barcode.map((ean) => ({ value: ean })),
      };
    }
    return {};
  }, [data]);

  if (error) {
    return (
      <MainBox>
        <Error />
      </MainBox>
    );
  }
  if (loading)
    return (
      <MainBox>
        <img src={storeLoader} alt="store-loader" width="110px" />
        <Typography>Cargando información</Typography>
      </MainBox>
    );
  return <ProductForm formType="edit" defaultValues={defaultValues} />;
}
export default EditProductForm;
