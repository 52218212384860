import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useFieldArray, useFormState } from 'react-hook-form';

import cache from 'apollo/cache';
import client from 'apollo/client';
import AutoCompleteSelect from 'components/selects/AutoCompleteSelect';
import useFirstRender from 'custom-hooks/useFirstRender';
import { GET_PRODUCTS } from 'gql/catalogs';
import { REQUIRED_FIELD } from 'lib/form';

import PackComponentsTable from './PackComponentsTable';

function PackComponentsForm({ control, register, watchSellerId }) {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'items',
    rules: { required: REQUIRED_FIELD },
  });
  const { errors } = useFormState({ control });

  const firstRender = useFirstRender();
  const [filters, setFilters] = useState({
    sellerId: '',
    globalFilter: '',
    hasComponents: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const addItem = () => {
    if (!fields.some((item) => item.value === selectedItem)) {
      const cacheObject = cache.identify({
        __typename: 'Product',
        id: selectedItem,
      });
      const product = client.readFragment({
        id: cacheObject,
        fragment: gql`
          fragment ProductObject on Product {
            id
            sku
            name
            value
            label
            storageType
            measures {
              width {
                value
                unit
              }
              height {
                value
                unit
              }
              length {
                value
                unit
              }
              weight {
                value
                unit
              }
            }
          }
        `,
      });
      append({
        ...product,
        quantity: 1,
      });
    }
  };

  useEffect(() => {
    if (!firstRender) {
      replace([]);
    }
    setFilters((prevFilters) => ({ ...prevFilters, sellerId: watchSellerId }));
  }, [watchSellerId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', marginY: 3 }}>
        <AutoCompleteSelect
          query={GET_PRODUCTS}
          label="Producto"
          filters={filters}
          setFilters={setFilters}
          setSelectedValue={setSelectedItem}
          size="small"
          validateFilters={(selectFilters) => !!selectFilters.sellerId}
          inputError={
            errors?.items ? { message: errors?.items?.root?.message } : false
          }
        />
        <Button
          size="medium"
          variant="contained"
          sx={{
            backgroundColor: '#01B0BB',
            borderRadius: 1,
            width: 250,
            marginLeft: 2,
            alignSelf: 'baseline',
          }}
          startIcon={<AddOutlinedIcon />}
          disabled={!selectedItem}
          onClick={addItem}
        >
          Agregar producto
        </Button>
      </Box>

      <Box>
        <Typography sx={{ marginBottom: 2 }}>
          Productos seleccionados
        </Typography>
        {fields?.length ? (
          <PackComponentsTable
            control={control}
            fields={fields}
            remove={remove}
            register={register}
          />
        ) : (
          <Typography variant="caption">
            Agrega productos a tu pack para poder visualizarlos acá
          </Typography>
        )}
      </Box>
    </Box>
  );
}
PackComponentsForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
  register: PropTypes.func.isRequired,
  watchSellerId: PropTypes.string.isRequired,
};
export default PackComponentsForm;
