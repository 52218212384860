import { useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { replenishmentFilterVar } from '../../../../apollo/reactiveVariables';
import PageTitle from '../../../../components/layout/PageTitle';
import SkeletonListData from '../../../../components/skeletons/ListData';
import useGetFilters from '../../../../custom-hooks/useGetFilters';
import { GET_TRANSFERS } from '../../../../gql/catalogs';
import ListServerData from '../../../commons/ListServerData';
import columns from './components/columns';
import ReplenishmentFilters from './components/TransferFilters';

function TransfersList() {
  const replenishmentFilter = useReactiveVar(replenishmentFilterVar);

  const { filters, setFilters, filterOptions, loading } = useGetFilters({
    filterVar: replenishmentFilterVar,
    filterOptionsKey: 'transferFilterOptions',
  });

  const handleGlobalFilter = (globalFilter) => {
    setFilters((prev) => ({
      ...prev,
      globalFilter,
    }));
  };

  const memoizedColumns = useMemo(() => columns(), []);
  if (loading)
    return (
      <>
        <PageTitle>Transferencias de inventario</PageTitle>
        <SkeletonListData />
      </>
    );
  return (
    <>
      <PageTitle>Transferencias de inventario</PageTitle>
      <ListServerData
        query={GET_TRANSFERS}
        filters={{
          components: (
            <ReplenishmentFilters
              filters={filters}
              setFilters={setFilters}
              sellersOptions={filterOptions.sellers}
              warehousesOptions={filterOptions.warehouses}
            />
          ),
          helperTextSearchBox: 'Buscar por referencia',
        }}
        dataKey="transfers"
        columns={memoizedColumns}
        queryVariables={{ sellerId: filters.sellerId }}
        handleGlobalFilter={handleGlobalFilter}
        initGlobalFilter={replenishmentFilter.globalFilter}
      />
    </>
  );
}
export default TransfersList;
