import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function SectionContainer({ title, children }) {
  return (
    <Box
      sx={{
        borderRadius: '6px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
        paddingY: '24px',
        paddingX: '24px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}

SectionContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SectionContainer;
