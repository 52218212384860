const keyMirror = require('keymirror');

const SHIPPING_METHODS = keyMirror({
  PICK_UP: null,
  EXTERNAL_PICK_UP: null,
  FLASH: null,
  EXPRESS: null,
  SAME_DAY: null,
  SCHEDULED: null,
  STANDARD: null,
  UNKNOWN: null,
  MANUAL: null,
  EXTERNAL_DELIVERY: null,
});

const SHIPPING_METHODS_LABEL_MAPPING = {
  [SHIPPING_METHODS.PICK_UP]: 'PICK UP',
  [SHIPPING_METHODS.EXTERNAL_PICK_UP]: 'PICK UP',
  [SHIPPING_METHODS.FLASH]: 'EXPRESS (APP)',
  [SHIPPING_METHODS.EXPRESS]: 'EXPRESS',
  [SHIPPING_METHODS.SAME_DAY]: 'SAME DAY',
  [SHIPPING_METHODS.SCHEDULED]: 'PROGRAMADO',
  [SHIPPING_METHODS.STANDARD]: 'ESTÁNDAR',
  [SHIPPING_METHODS.UNKNOWN]: 'DESCONOCIDO',
  [SHIPPING_METHODS.MANUAL]: 'COORDINADO',
  [SHIPPING_METHODS.EXTERNAL_DELIVERY]: 'EXTERNO',
};

export { SHIPPING_METHODS, SHIPPING_METHODS_LABEL_MAPPING };
