import { useEffect, useMemo, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useWatch, useFieldArray } from 'react-hook-form';

import cache from 'apollo/cache';
import client from 'apollo/client';
import FileInput from 'components/input-files/FileInput';
import FileTemplate from 'components/input-files/FileTemplate';
import AutocompleteSelect from 'components/selects/AutoCompleteSelect';
import Table from 'components/table/Table';
import usePrevious from 'custom-hooks/usePrevious';
import { GET_PRODUCTS, GET_PRODUCTS_AT_WAREHOUSES } from 'gql/catalogs';
import { formatCurrencyValue } from 'lib/currency';
import FILE_TYPES from 'lib/fileTypes';
import { REQUIRED_FIELD, POSITIVE_NUMBER } from 'lib/form';
import findDuplicates from 'lib/utils/findDuplicates';
import removeDuplicates from 'lib/utils/removeDuplicates';

import readCSVSkus from '../lib/readCSVSkus';
import ItemQuantityCell from './ItemQuantityCell';

const { REACT_APP_FILE_ORDER_SKUS_TEMPLATE_PATH } = process.env;
const GET_PRODUCTS_MULTIPLIER_LIMIT = 2;
const DEFAULT_ITEM_QUANTITY = 1;
const DEFAULT_ITEM_UNIT_PRICE = 1;
const REQUIRED = 'required';

function NewOrderItems({ errors, register, control, filters, setFilters }) {
  const [getProducts, { loading, error: getProductsError }] =
    useLazyQuery(GET_PRODUCTS);
  const [
    getProductsAtWarehouses,
    { loading: loadingInventory, error: getInventoryError },
  ] = useLazyQuery(GET_PRODUCTS_AT_WAREHOUSES);
  const [missingUploadSkus, setMissingUploadSkus] = useState([]);
  const [duplicatedSkus, setDuplicatedSkus] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'items',
    rules: { required: REQUIRED_FIELD },
  });
  const watchWarehouseId = useWatch({
    name: 'warehouseId',
    control,
  });
  const watchOrderCurrency = useWatch({
    name: 'orderCurrency',
    control,
  });
  const watchSellerId = useWatch({
    name: 'sellerId',
    control,
  });
  const prevFields = usePrevious(fields);

  useEffect(() => {
    replace([]);
  }, [watchSellerId, watchWarehouseId]);

  const appendDataToForm = (tableData) => {
    tableData.forEach((row) => {
      if (!fields.some(({ value }) => value === row.value)) {
        append(row);
      }
    });
  };

  const onCompleteReadCSV = async (tableData) => {
    setMissingUploadSkus([]);
    const skus = tableData.map(({ sku }) => sku);
    const repeatedSkus = findDuplicates(skus);
    setDuplicatedSkus(repeatedSkus);
    const cleanTableData = removeDuplicates(tableData, 'sku');
    const {
      data: {
        getProducts: { nodes },
      },
    } = await getProducts({
      variables: {
        limit: cleanTableData.length * GET_PRODUCTS_MULTIPLIER_LIMIT,
        filters: {
          sellerId: watchSellerId,
          skus: cleanTableData.map(({ sku }) => sku),
        },
      },
    });
    const items = cleanTableData
      .map(({ sku, quantity, unitPrice }) => {
        const product = nodes.find(({ sku: itemSku }) => itemSku === sku);
        if (!product) {
          setMissingUploadSkus((prev) => [...prev, sku]);
          return { sku };
        }
        const { id, name, value, label, storageType, measures } = product;
        return {
          id,
          sku,
          name,
          value,
          label,
          storageType: storageType.value,
          measures,
          quantity,
          unitPrice,
        };
      })
      .filter(({ id }) => id);
    const {
      data: {
        getProductsAtWarehouses: { nodes: productsAtWarehouses },
      },
    } = await getProductsAtWarehouses({
      variables: {
        limit: cleanTableData.length * GET_PRODUCTS_MULTIPLIER_LIMIT,
        filters: {
          sellerId: watchSellerId,
          warehouseId: watchWarehouseId,
          productId: items.map(({ id }) => id),
        },
      },
    });
    const itemsWithQuantityAvailable = items.map((item) => {
      const quantityAvailable =
        productsAtWarehouses.find(
          (inventory) => inventory.product.id === item.id,
        )?.quantityAvailable || 0;
      return { ...item, quantityAvailable };
    });

    appendDataToForm(itemsWithQuantityAvailable);
  };
  const addItem = () => {
    if (!fields.some(({ value }) => value === selectedItem)) {
      const cacheObject = cache.identify({
        __typename: 'Product',
        id: selectedItem,
      });
      const product = client.readFragment({
        id: cacheObject,
        fragment: gql`
          fragment ProductObject on Product {
            id
            sku
            name
            value
            label
            storageType
            measures {
              width {
                value
                unit
              }
              height {
                value
                unit
              }
              length {
                value
                unit
              }
              weight {
                value
                unit
              }
            }
          }
        `,
      });
      append({
        ...product,
        quantity: DEFAULT_ITEM_QUANTITY,
        unitPrice: DEFAULT_ITEM_UNIT_PRICE,
        storageType: product.storageType.value,
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: <Typography>Sku</Typography>,
        accessor: 'sku',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Nombre</Typography>,
        accessor: 'name',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => <Typography>{value}</Typography>,
      },
      {
        Header: <Typography>Precio unitario</Typography>,
        id: 'unitPrice',
        accessor: 'value',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <TextField
            sx={{ width: 250 }}
            key={`items[${fields.findIndex(
              (item) => item.value === value,
            )}].unitPrice`}
            size="small"
            type="number"
            error={Boolean(
              errors.items &&
                errors.items[fields.findIndex((item) => item.value === value)]
                  ?.unitPrice,
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register(
              `items[${fields.findIndex(
                (item) => item.value === value,
              )}].unitPrice`,
              {
                required: REQUIRED_FIELD,
                min: {
                  value: 1,
                  message: POSITIVE_NUMBER,
                },
              },
            )}
            helperText={
              errors.items &&
              errors.items[fields.findIndex((item) => item.value === value)]
                ?.unitPrice?.message
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {watchOrderCurrency}
                </InputAdornment>
              ),
            }}
          />
        ),
      },
      {
        Header: <Typography>Cantidad</Typography>,
        accessor: 'value',
        id: 'quantity',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { quantityAvailable },
          },
        }) => (
          <ItemQuantityCell
            value={value}
            productId={value}
            watchWarehouseId={watchWarehouseId}
            watchSellerId={watchSellerId}
            fields={fields}
            prevFields={prevFields}
            errors={errors}
            register={register}
            quantityAvailable={quantityAvailable}
          />
        ),
      },
      {
        Header: <Typography>Monto total</Typography>,
        id: 'totalValue',
        accessor: 'value',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => {
          const watchItems = useWatch({
            name: 'items',
            control,
          });
          const currentItems = watchItems ?? [];
          const currentItemIndex = currentItems.findIndex(
            (item) => item.value === value,
          );
          const currentItem = currentItems[currentItemIndex];
          if (!currentItem) return <div />;
          return (
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="caption">{watchOrderCurrency}</Typography>
              <Typography>
                {formatCurrencyValue({
                  currency: watchOrderCurrency,
                  value: currentItem.unitPrice * currentItem.quantity,
                })}
              </Typography>
            </Box>
          );
        },
      },
      {
        Header: '',
        id: 'delete',
        accessor: 'value',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          value,
        }) => (
          <Box
            key={`delete-item${value}-box`}
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
          >
            <IconButton
              onClick={() => {
                const currentItemIndex = fields.findIndex(
                  (item) => item.value === value,
                );
                remove(currentItemIndex);
              }}
            >
              <Tooltip title="Eliminar producto de la orden">
                <ClearIcon />
              </Tooltip>
            </IconButton>
          </Box>
        ),
      },
    ],
    [fields, control, watchOrderCurrency],
  );
  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Typography variant="subtitle1">3. Seleccionar productos</Typography>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
        <AutocompleteSelect
          query={GET_PRODUCTS}
          label="Producto"
          filters={filters}
          setFilters={setFilters}
          setSelectedValue={setSelectedItem}
          size="small"
          disabled={!watchWarehouseId}
          validateFilters={(_filters) => Boolean(_filters.sellerId)}
          inputError={
            errors?.items ? { message: errors?.items?.root?.message } : false
          }
        />
        <Button
          size="medium"
          variant="contained"
          sx={{
            backgroundColor: '#01B0BB',
            borderRadius: 1,
            width: 250,
          }}
          startIcon={<AddOutlinedIcon />}
          disabled={!selectedItem}
          onClick={addItem}
        >
          Agregar producto
        </Button>
      </Box>
      {watchWarehouseId && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography>
            También puedes agregar los SKUs con un archivo
          </Typography>
          <FileTemplate
            filePath={REACT_APP_FILE_ORDER_SKUS_TEMPLATE_PATH}
            title="CSV de ejemplo"
          />
          <FileInput
            fileTypes={[FILE_TYPES.CSV]}
            fileLabel="CSV"
            fileParser={(files) => readCSVSkus(files, onCompleteReadCSV)}
            error={Boolean(errors?.items?.root?.type === REQUIRED)}
          />
          {(loading || loadingInventory) && (
            <LinearProgress color="secondary" />
          )}
          {((!!getProductsError && !loading) ||
            (!!getInventoryError && !loadingInventory)) && (
            <Typography color="error">
              No pudimos cargar los SKUs en la orden
            </Typography>
          )}
          {!!missingUploadSkus.length && (
            <Typography color="error">
              Los siguientes SKUs no están registrados en el sistema:{' '}
              {missingUploadSkus.join(', ')}
            </Typography>
          )}
          {!!duplicatedSkus.length && (
            <Typography color="error">
              Los siguientes SKUs están repetidos en el archivo. Revisa que se
              hayan cargado correctamente: {duplicatedSkus.join(', ')}
            </Typography>
          )}
        </Box>
      )}
      {Boolean(fields?.length) && (
        <Box>
          <Typography sx={{ mb: 1 }}>Productos</Typography>
          <Table
            data={fields}
            columns={columns}
            pagination={{
              hasPagination: false,
              customPageSize: fields.length,
            }}
            hasSearchBox={false}
            paperBoxProps={{
              elevation: 0,
              sx: {
                borderRadius: '4px',
                border: '1px solid #EEEDED',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
NewOrderItems.propTypes = {
  errors: PropTypes.shape({
    orderCurrency: PropTypes.shape({ message: PropTypes.string }),
    items: PropTypes.oneOfType([
      PropTypes.shape({
        root: PropTypes.shape({
          message: PropTypes.string,
          type: PropTypes.string,
        }),
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          unitPrice: PropTypes.shape({ message: PropTypes.string }),
        }),
      ),
    ]),
  }).isRequired,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  filters: PropTypes.shape({
    sellerId: PropTypes.string,
    warehouseId: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};
export default NewOrderItems;
