import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import styles from './styles';

function SummaryView({ summaryTitle, summaryData }) {
  return (
    <>
      <View style={{ alignItems: 'left', paddingTop: 4 }}>
        <Text
          key="Title"
          style={{
            fontSize: 12,
            fontWeight: 400,
            margin: 2,
          }}
        >
          {summaryTitle}
        </Text>
      </View>
      <View style={styles.summaryView}>
        {Object.keys(summaryData).map((columnName) => (
          <Text
            key={columnName}
            style={{
              fontSize: 10,
              fontWeight: 100,
              margin: 2,
            }}
          >
            {`${columnName}: ${
              summaryData[columnName] ? summaryData[columnName] : '-'
            }`}
          </Text>
        ))}
      </View>
    </>
  );
}
SummaryView.propTypes = {
  summaryData: PropTypes.shape({}).isRequired,
  summaryTitle: PropTypes.string.isRequired,
};

export default SummaryView;
