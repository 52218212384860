import keyMirror from 'keymirror';

const TRANSFER_STATUSES = keyMirror({
  created: null,
  finished: null,
});

const TRANSFER_STATUS_LABEL_MAPPING = {
  [TRANSFER_STATUSES.created]: 'Creada',
  [TRANSFER_STATUSES.received]: 'Finalizada',
};

const TRANSFER_STATUS_COLOR_MAPPING = {
  created: 'info',
  received: 'success',
};

export {
  TRANSFER_STATUSES,
  TRANSFER_STATUS_LABEL_MAPPING,
  TRANSFER_STATUS_COLOR_MAPPING,
};
