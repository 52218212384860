import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Tag from 'components/Tag';
import STORAGE_OPTIONS from 'lib/storageOptions';
import HeaderListData from 'views/commons/HeaderListData';

import { translateMeasures, translateWeight } from '../lib/translateMeasures';

const columns = ({ removeProduct }) => [
  {
    Header: <HeaderListData fontSize="0.8rem">Sku</HeaderListData>,
    accessor: 'sku',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Nombre</HeaderListData>,
    accessor: 'name',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    Header: (
      <HeaderListData fontSize="0.8rem">Tipo de almacenaje</HeaderListData>
    ),
    accessor: 'storageType',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      if (!value) {
        return <Typography>-</Typography>;
      }
      const storageType = STORAGE_OPTIONS.find(
        (storageOption) => value === storageOption.value,
      );
      return (
        <Tooltip title="Los tipos de almacenamiento pueden ser seco, refrigerado y congelado.">
          <span>
            {/* eslint-disable-next-line react/prop-types */}
            <Tag label={storageType.label} type={storageType.value} />
          </span>
        </Tooltip>
      );
    },
  },
  {
    Header: (
      <HeaderListData fontSize="0.8rem">
        Tipo de almacenaje del delivery
      </HeaderListData>
    ),
    accessor: 'deliveryStorageType',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      if (!value) {
        return <Typography>-</Typography>;
      }
      const deliveryStorageType = STORAGE_OPTIONS.find(
        (storageOption) => value === storageOption.value,
      );
      return (
        <Tooltip title="Los tipos de almacenamiento pueden ser seco, refrigerado y congelado.">
          <span>
            {/* eslint-disable-next-line react/prop-types */}
            <Tag
              label={deliveryStorageType.label}
              type={deliveryStorageType.value}
            />
          </span>
        </Tooltip>
      );
    },
  },
  {
    Header: <HeaderListData fontSize="0.8rem">EAN</HeaderListData>,
    accessor: 'barcode',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {value &&
          // eslint-disable-next-line react/prop-types
          value.map((ean) => (
            <div key={ean}>
              <Chip label={ean} size="small" />
            </div>
          ))}
      </Box>
    ),
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Medidas</HeaderListData>,
    accessor: 'measures',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => {
      const { length, width, height } = value || {};
      const missingAllDimensions =
        !value ||
        Object.values({ length, width, height }).filter(
          (dimension) => dimension?.value === '?',
        ).length === 3;
      const parsedMeasures = translateMeasures(value);
      return (
        <Tooltip
          title={
            !missingAllDimensions
              ? `Largo x Ancho x Altura.`
              : 'No existen dimensiones inscritas'
          }
        >
          <Typography>{parsedMeasures}</Typography>
        </Tooltip>
      );
    },
  },
  {
    Header: <HeaderListData fontSize="0.8rem">Peso</HeaderListData>,
    id: 'weight',
    accessor: 'measures',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => {
      const existsWeight =
        // eslint-disable-next-line react/prop-types
        value?.weight?.value && value?.weight?.value !== '?';
      // eslint-disable-next-line react/prop-types
      const parsedWeight = translateWeight(value.weight);
      return (
        <Tooltip title={!existsWeight ? `Peso` : 'No existe peso inscrito'}>
          <Typography>{parsedWeight}</Typography>
        </Tooltip>
      );
    },
  },

  {
    Header: '',
    id: 'delete',
    accessor: 'sku',
    Cell: ({
      // eslint-disable-next-line react/prop-types
      value,
    }) => (
      <Box
        key={`delete-item${value}-box`}
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton
          onClick={() => {
            removeProduct(value);
          }}
        >
          <Tooltip title="Eliminar orden">
            <ClearIcon />
          </Tooltip>
        </IconButton>
      </Box>
    ),
  },
];

export default columns;
